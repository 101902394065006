import { gql } from "@apollo/client";

export const initGraphQL = gql`
    query initJobSiteGraphQL($id: MongoID!) {
        JobSiteFindById(_id: $id) {
            ClientJobSiteCode
            Code
            Name
            Type
            Description
            Client {
                _id
                VCard {
                    Addresses {
                        AddressString
                        _id
                    }
                    _id
                }
            }
            ClientAddressId
            _id
            createdAt
            updatedAt
        }
        ClientFindMany {
            _id
            VCard {
                ContactName
                Addresses {
                    AddressString
                    _id
                }
                _id
            }
        }
    }
`;