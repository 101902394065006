import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';
import moment from 'moment';
import { useFormContext } from '../../../providers/FormProvider/FormProvider';

const DatepickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  flex: 5;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  height: fit-content;

  .react-datepicker__input-container {
    height: 100%;
  }
`;


const FieldLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
`;

const DatepickerInput = styled.input`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;

  /* Display style settings */
  width: 100%;
  border: 1px solid ${({ theme }) => theme.borderColor.default};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall};
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  height: ${({ theme }) => theme.spacing.xxlarge};
  max-height: ${({ theme }) => theme.spacing.xxlarge};

  &:focus {
    border-color: ${({ theme }) => theme.borderColor.focus};
    outline-color: ${({ theme }) => theme.borderColor.outline};
  }
`;

const FieldExample = styled.span`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: ${({ theme }) => theme.fontStyles.italic};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.disabledText};
`;


const DateField = React.memo(({
  index = undefined,
  type = 'text',
  label = '',
  name = '',
  example = '',
  placeholder = '',
  ariaLabel = '',
  ariaDescribedby = '',
  autoComplete = '',
  required = false,
  ...props
}) => {
  const { state, dispatch } = useFormContext();
  const [value, setValue] = useState(new Date());

  const handleInputChange = useCallback((date) => {
    setValue(date);
    dispatch({ type: 'UPDATE_FORM_INPUT', payload: { key: name, value: {Date: date.toISOString()}, index } });
  }, [dispatch, name, index]);

  useEffect(() => {
    let inputValue;
    try {
      inputValue = moment(getFormValue(state?.formInputs, name, index)?.Date);
    } catch (error) {
    }
    setValue(inputValue);
  }, [state?.formInputs, name, index, setValue]);

  return (
    <DatepickerContainer>
      <FieldLabel readOnly={!state?.isEditing} htmlFor={`field-${label.toLowerCase()}-${index}`}>{label}</FieldLabel>
      <DatePicker
        id={`field-${label.toLowerCase()}-${index}`}
        readOnly={!state?.isEditing}
        name={name}
        selected={value}
        onChange={handleInputChange}
        placeholder={placeholder}
        autoComplete={index === 0 ? autoComplete : ''}
        required={required}
        aria-readonly={!state?.isEditing}
        aria-label={ariaLabel}
        aria-describedby={ariaDescribedby}
        dateFormat="yyyy/MM/dd"
        showYearDropdown
        dropdownMode="select"
        placeholderText='YYYY/MM/DD'
        customInput={<DatepickerInput isEditing={state?.isEditing} />}
        {...props}
      />
      <FieldExample>{example}</FieldExample>
    </DatepickerContainer>
  );
});

export default DateField;
