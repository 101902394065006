import { gql } from "@apollo/client";
import { PROJECT_FRAGMENT } from "../Fragments/Project";

export const ProjectCreateOne = gql`
    ${PROJECT_FRAGMENT}
    mutation Mutation($record: ProjectCreateOneWithRelationship!) {
        ProjectCreateOne(record: $record) {
            record {
                ...ProjectFields
            }
        }
    }
`;

export const ProjectUpdateOne = gql`
    mutation ProjectUpdateOne($record: ProjectUpdateOneWithRelationship!, $filter: FilterUpdateOneProjectInput) {
        ProjectUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const ProjectRemoveFindById = gql`
    mutation ProjectRemoveFindById($id: MongoID!) {
        ProjectRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
