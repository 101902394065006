/* CompanySelection.js */
import styled from 'styled-components';
import LogoBlack from '../../assets/images/logo-black.png';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { USER_ONE } from '../Querys/USER_ONE';
import { AppConfigContext } from '../../config';

const FullPageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(255,207,94);
  background: linear-gradient(180deg, rgba(255,207,94,1) 0%, rgba(51,104,222,1) 100%);
  color: ${({ theme }) => theme.colors.defaultText};
  gap: ${({ theme }) => theme.spacing.medium};
  padding: ${({ theme }) => theme.spacing.medium};
  min-height: 100vh;
  width: 100vw;
  box-sizing: border-box;
`;

const LogoImage = styled.img`
  width: 100px;
  height: auto;
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.large};
  font-weight: bold;
`;

const CompanyList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const CompanyListItem = styled.li`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.large};

  &:last-child {
    margin-bottom: 0;
  }
`;

const CompanyName = styled.div`
  flex: 6;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
`;

const SelectButton = styled.button`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  padding: ${({ theme }) => theme.spacing.xxsmall} ${({ theme }) => theme.spacing.medium};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.third};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondary};
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 3px rgba(0, 123, 255, 0.5);
  }
`;

export const USER_UPDATE_BY_ID = gql`
  mutation UserUpdateFindById($id: MongoID!, $record: UpdateFindByIdUserInput!) {
    UserUpdateFindById(_id: $id, record: $record) {
      recordId
    }
  }
`;

const CompanySelection = ({ UserData }) => {
  const [UserUpdateFindById] = useMutation(USER_UPDATE_BY_ID);
  const { setAppConfig } = useContext(AppConfigContext);

  const LoginToTenant = async (e) => {
    e.preventDefault();
    const tenantId = e.target.getAttribute('data-tenant-id');
    await UserUpdateFindById({
      variables: { id: UserData._id, record: { SelectedTenant: tenantId } },
      refetchQueries: [{ query: USER_ONE }],
    });
    setAppConfig((prevValue) => {
      return {
        ...prevValue,
        ...{
          SelectedTenant: tenantId
        }
      };
    });
  }

  return (
    <FullPageDiv>
      <LogoImage src={LogoBlack} alt="Logo" width={100} />
      <Title>Select Company</Title>
      <CompanyList>
        {UserData?.RelatedTenants?.map(({ Tenant }, index) => (
          <>
            <CompanyListItem key={index}>
              <CompanyName className="company-name">{Tenant?.Company?.Company}</CompanyName>
              <SelectButton
                data-tenant-id={Tenant._id}
                onClick={LoginToTenant}
              >
                Select
              </SelectButton>
            </CompanyListItem>
          </>
        ))}
      </CompanyList>
    </FullPageDiv>
  );
}

export default CompanySelection;