import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { filter, get, isEmpty, isEqual, isFunction, isObject } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';


const FieldSelect = styled.select`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: ${({ theme }) => theme.spacing.xxxxxsmall};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall};
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.defaultText};
  height: ${({ theme }) => theme.spacing.xxlarge};
  max-height: ${({ theme }) => theme.spacing.xxlarge};
  
  &:focus {
      border-color: ${({ theme }) => theme.borderColor.focus};
      outline-color: ${({ theme }) => theme.borderColor.outline};
  }

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 30px;
  width: 100%;
`;

const CustomSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const IconWrapper = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

const Select = React.memo(({
    state,
    dispatch,
    name = '',
    index = undefined,
    limit = 15,
    placeholder,
    optionsConfig,
    ...props
}) => {
    const [value, setValue] = useState('');
    const [_options, setOptions] = useState([]);

    const handleInputChange = useCallback((event) => {
        setValue(event.target.value);
        dispatch({ type: 'UPDATE_FORM_INPUT', payload: { key: name, value: event.target.value, index } });
    }, [dispatch, name, index]);

    useEffect(() => {
        if (isObject(optionsConfig)) {
            let newOptions = [];

            if (isFunction(optionsConfig.loadFromState)) {
                const rawOptions = optionsConfig.loadFromState(state);
                newOptions = filter(rawOptions, option => {
                    if (isObject(option)) {
                        return !isEmpty(option?.caption) && !isEmpty(option?.value);
                    }
                    return false;
                });
            } else {
                newOptions = get(optionsConfig, 'options', []);
            }

            if (!isEqual(_options, newOptions)) {
                setOptions(newOptions);
                setValue('');
            }
        }
    }, [_options, optionsConfig, state, index, name]);

    useEffect(() => {
        const inputValue = getFormValue(state?.formInputs, name, index);
        setValue(inputValue);
    }, [state?.formInputs, name, index]);


    return <React.Fragment>
        <CustomSelectWrapper>
            <FieldSelect
                {...props}
                readOnly={!state?.isEditing}
                name={name}
                type="text"
                value={value || ''}
                onChange={handleInputChange}
                placeholder={placeholder}
            >
                <option value="">{placeholder || 'Select'}</option>
                {_options?.map((option, optionIndex) => {
                    const truncatedOption = option?.caption?.slice(0, limit);
                    return (
                        <option
                            key={`field-${name}-option-${truncatedOption}-${optionIndex + 1}`}
                            label={truncatedOption}
                            value={option?.value || ''}>
                            {truncatedOption}
                        </option>
                    );
                })}
            </FieldSelect>
            <IconWrapper>
                <FontAwesomeIcon icon={faChevronDown} />
            </IconWrapper>
        </CustomSelectWrapper>
    </React.Fragment>;
});

export default Select;
