import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const BackButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xxxxsmall};
  color: ${({ theme }) => theme.colors.defaultText};
  font-size: ${({ theme }) => theme.fontSizes.small};

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const BackButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
`;

const BackButton = ({override}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <BackButtonWrapper onClick={override || goBack}>
      <BackButtonIcon icon={faArrowLeft} />
      Back
    </BackButtonWrapper>
  );
};

export default BackButton;
