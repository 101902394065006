import React, { useState } from 'react';
import styled from 'styled-components';

const TextBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGray};
`;

const Input = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const AttachmentInput = styled.input`
  display: none;
`;

const SendButton = styled.button`
  margin-left: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;
`;

const RemoveButton = styled.button`
  margin-left: 8px;
  padding: 4px;
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.red};
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
`;

const ChatTextInput = ({ value, onChange, onSendMessage }) => {
  const [messageContent, setMessageContent] = useState(value);
  const [attachments, setAttachments] = useState([]);

  const handleInputChange = (e) => {
    setMessageContent(e.target.value);
    onChange(e);
  };

  const handleSendMessage = () => {
    onSendMessage(messageContent, attachments);
    setMessageContent('');
    setAttachments([]);
  };

  const handleAttachmentChange = (e) => {
    const file = e.target.files[0];
    setAttachments([...attachments, file]);
  };

  const removeAttachment = (index) => {
    const updatedAttachments = attachments.filter((_, i) => i !== index);
    setAttachments(updatedAttachments);
  };

  return (
    <TextBox>
      <Input
        type="text"
        placeholder="Type your message..."
        value={messageContent}
        onChange={handleInputChange}
      />
      <AttachmentInput
        type="file"
        accept="image/*"
        onChange={handleAttachmentChange}
        multiple
      />
      <SendButton onClick={handleSendMessage}>Send</SendButton>
      {attachments.map((attachment, index) => (
        <Attachment key={index}>
          {attachment.name}
          <RemoveButton onClick={() => removeAttachment(index)}>
            &#10005;
          </RemoveButton>
        </Attachment>
      ))}
    </TextBox>
  );
};

// Rest of the code remains the same...

export default ChatTextInput;
