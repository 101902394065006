import { gql } from "@apollo/client";
import { ATTACHMENT_FRAGMENT } from "../Fragments/Attachment";

export const AttachmentCreateOne = gql`
    ${ATTACHMENT_FRAGMENT}
    mutation Mutation($record: AttachmentCreateOneWithRelationship!) {
        AttachmentCreateOne(record: $record) {
            record {
                ...AttachmentFields
            }
        }
    }
`;

export const AttachmentUpdateOne = gql`
    mutation AttachmentUpdateOne($record: AttachmentUpdateOneWithRelationship!, $filter: FilterUpdateOneAttachmentInput) {
        AttachmentUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const AttachmentRemoveFindById = gql`
    mutation AttachmentRemoveFindById($id: MongoID!) {
        AttachmentRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
