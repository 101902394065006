import { gql } from "@apollo/client";
import { NOTE_FRAGMENT } from "../Fragments/Note";

export const NoteCreateOne = gql`
    ${NOTE_FRAGMENT}
    mutation Mutation($record: NoteCreateOneWithRelationship!) {
        NoteCreateOne(record: $record) {
            record {
                ...NoteFields
            }
        }
    }
`;

export const NoteUpdateOne = gql`
    mutation NoteUpdateOne($record: NoteUpdateOneWithRelationship!, $filter: FilterUpdateOneNoteInput) {
        NoteUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const NoteRemoveFindById = gql`
    mutation NoteRemoveFindById($id: MongoID!) {
        NoteRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
