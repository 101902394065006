import styled from "styled-components";
import { jobsiteFormConfig } from "../../../../documentConfigs/JobSite/jobsite.form.js";
import FormBuilder from "../../../FormBuilder/FormBuilder.js";

const JobSiteDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;


const JobSiteDetails = ({ jobsite = {}, closeModal = () => { }, isNewDocument }) => {
    return <JobSiteDetailsWrapper>
        <FormBuilder
            documentId={jobsite?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            {...jobsiteFormConfig}
        />
    </JobSiteDetailsWrapper>;
};

export default JobSiteDetails