import { gql } from "@apollo/client";
import { JOBSITE_FRAGMENT_QUERY } from "../Fragments/JobSite";

export const JobSiteCreateOne = gql`
    ${JOBSITE_FRAGMENT_QUERY}
    mutation Mutation($record: JobSiteCreateOneWithRelationship) {
        JobSiteCreateOne(record: $record) {
            recordId
            record {
                ...JobSiteFields
            }
            error {
                message
            }
        }
    }
`;

export const JobSiteUpdateOne = gql`
    ${JOBSITE_FRAGMENT_QUERY}
    mutation JobSiteUpdateOne($record: JobSiteUpdateOneWithRelationship, $filter: FilterUpdateOneJobSiteInput, $sort: SortUpdateOneJobSiteInput, $skip: Int) {
        JobSiteUpdateOne(record: $record, filter: $filter, sort: $sort, skip: $skip) {
            recordId
            record {
                ...JobSiteFields
            }
            error {
                message
            }
        }
    }
`;

export const JobSiteRemoveFindById = gql`
    ${JOBSITE_FRAGMENT_QUERY}
    mutation JobSiteRemoveFindById($id: MongoID!) {
        JobSiteRemoveFindById(_id: $id) {
            recordId
            record {
                ...JobSiteFields
            }
            error {
                message
            }
        }
    }
`;
