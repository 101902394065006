import { every, get, isEmpty, isFunction, keys, union } from "lodash";

export async function handleFormValidation(state) {
    const requiredValues = state?.validation?.required;
    const validators = state?.validation?.validators;

    const formKeys = union(keys(requiredValues), keys(validators));

    const formErrors = {};

    for (const key of formKeys) {
        try {
            let _error = [];

            // Check Required
            if (get(requiredValues, key, false)) {
                if (isEmpty(get(state?.formInputs, key))) {
                    _error.push({
                        errorType: 'Required',
                    });
                }
            }

            // Check validator
            if (isFunction(validators?.[key])) {
                const validator = validators?.[key];
                const { valid, errorType, error } = await validator(get(state?.formInputs, `${key}`));
                if (!valid) {
                    _error.push({
                        errorType,
                        error,
                    });
                }
            }

            formErrors[key] = _error;
        } catch (error) {
            formErrors[key] = { errorType: 'Generic', error };
        }
    }

    const hasErrors = !every(formErrors, isEmpty);
    if (hasErrors) {
        throw formErrors;
    }
}