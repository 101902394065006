
import Text from "../../components/FormBuilder/fields/Text";
import TextArea from "../../components/FormBuilder/fields/TextArea";

export const fields = [
    {
        label: "Availability",
        fieldSet: [
            {
                label: 'All Day',
                name: 'CalendarEvent.IsAllDay',
                type: Text,
                example: '',
                placeholder: 'Enter All Day',
                ariaLabel: 'All Day',
                ariaDescribedby: 'Enter All Day',
                autoComplete: '',
            },
            {
                label: 'Start Time',
                name: 'CalendarEvent.StartTime',
                type: Text,
                example: '',
                placeholder: 'Enter Start Time',
                ariaLabel: 'Start Time',
                ariaDescribedby: 'Enter Start Time',
                autoComplete: '',
            },
            {
                label: 'End Time',
                name: 'CalendarEvent.EndTime',
                type: Text,
                example: '',
                placeholder: 'Enter End Time',
                ariaLabel: 'End Time',
                ariaDescribedby: 'Enter End Time',
                autoComplete: '',
            },
            {
                label: 'Frequency',
                name: 'CalendarEvent.Frequency',
                type: Text,
                example: '',
                placeholder: 'Enter Frequency',
                ariaLabel: 'Frequency',
                ariaDescribedby: 'Enter Frequency',
                autoComplete: '',
            },
            {
                label: 'Interval',
                name: 'CalendarEvent.Interval',
                type: Text,
                example: '',
                placeholder: 'Enter Interval',
                ariaLabel: 'Interval',
                ariaDescribedby: 'Enter Interval',
                autoComplete: '',
            },
            {
                label: 'Days Of Week',
                name: 'CalendarEvent.DaysOfWeek',
                type: Text,
                example: '',
                placeholder: 'Enter Days Of Week',
                ariaLabel: 'Days Of Week',
                ariaDescribedby: 'Enter Days Of Week',
                autoComplete: '',
            },
            {
                label: 'Description',
                name: 'CalendarEvent.Description',
                type: TextArea,
                example: '',
                placeholder: 'Enter Description',
                ariaLabel: 'Description',
                ariaDescribedby: 'Enter Description',
                autoComplete: '',
            },
        ]
    },
];