import { isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ErrorStyles = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  padding: ${({ theme }) => theme.spacing.xxxsmall};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: bold;
  background: white;
  margin: 0px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 5px solid red;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  p {
    padding: 0px;
    margin: 0px;
  }
`;

const DisplayErrors = ({ label, errors }) => {
  const { t } = useTranslation();

  if (isEmpty(errors)) {
    return <></>;
  }
  return (
    <ErrorStyles>
      {errors.map((err, index) => {
        const errorMessage = t(`FormBuilder.Errors.${err.errorType}`, { defaultValue: t('FormBuilder.Errors.Generic'), fieldName: label });

        return <p key={`error-${label}-${index}`}>{errorMessage}</p>;
      })}
    </ErrorStyles>
  );
};

export default DisplayErrors;
