import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useModal from '../../../../hooks/useModal';
import Project from '../../../Documents/Project';

const GridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const BodyWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.small};
`;

const BodyWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.defaultText};
  gap: ${({ theme }) => theme.spacing.xxxxxsmall};
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.defaultText};
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => theme.spacing.small};
  padding-top: 0px;
`;

const GridLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
`;

const Header = styled.h3`
  flex: 1;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
  box-sizing: border-box;
  margin: 0px;
  color: ${({ theme }) => theme.colors.defaultText};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.medium} ${({ theme }) => theme.borderRadius.medium} 0 0;
`;

// const Code = styled.p`
//   font-size: ${({ theme }) => theme.fontSizes.medium};
//   padding: 0px;
//   margin: 0px;
//   font-weight: bold;
// `;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  flex: 1;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3em;
  -webkit-box-orient: vertical;  
  height: 1.3em;
  color: ${({ theme }) => theme.colors.defaultText};
`;

const Details = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.defaultText};
  flex: 1;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3em;
  -webkit-box-orient: vertical;  
  height: 1.3em;
`;

const Priority = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  padding: 0px;
  margin: 0px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
`;

const FieldText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  /* color: ${({ theme }) => theme.colors.disabledText}; */
  padding: 0px;
  margin: 0px;
`;

const DeadLine = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  padding: 0px;
  margin: 0px;
`;

const IconWrapper = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
`;

const FieldLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.defaultText};
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
`;

const ProjectGridItem = ({ data, index }) => {
  const { openModal } = useModal();
  console.log({ data });
  const handleOpenModal = (props) => {
    openModal(<Project project={data} jobsite={data.JobSite} />);
  };

  return <GridItemContainer>
    <GridLink onClick={() => handleOpenModal()}>
      <Header>{data?.Status}</Header>
      <BodyWrapperRow>
        <BodyWrapperColumn>
          <Title>{data?.Name}</Title>
          <FieldText><FieldLabel>Description:</FieldLabel><br /><Details>{data?.Description}</Details></FieldText>
          <FieldText><FieldLabel>Manager:</FieldLabel><br /><Details>{data?.Manager}</Details></FieldText>
          <FieldText><FieldLabel>Target Start Date:</FieldLabel><br />{moment(data?.StartDate).format('MMM DD, YYYY')}</FieldText>
          <FieldText><FieldLabel>Target End Date:</FieldLabel><br />{moment(data?.EndDate).format('MMM DD, YYYY')}</FieldText>
        </BodyWrapperColumn>
        <IconWrapper >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconWrapper>
      </BodyWrapperRow>
      <FooterWrapper>
        <Priority>{data?.Priority}</Priority>
        <DeadLine>{Math.abs(moment(data?.EndDate).diff(moment(), 'days'))} {moment().isBefore(moment(data?.EndDate)) ? 'Days Remaining' : 'Days Overdue'}</DeadLine>
      </FooterWrapper>
    </GridLink>
  </GridItemContainer>
};

export default ProjectGridItem;
