import { gql } from "@apollo/client";
import { CLIENT_FRAGMENT } from "../../graphql/Fragments/Client";

export const initGraphQL = gql`
    ${CLIENT_FRAGMENT}
    query initClientGraphQL($id: MongoID!) {
        ClientFindById(_id: $id) {
            ...ClientFields
        }
    }
`;