// useForm.submit.js
import { get, isPlainObject, some, isArray, includes, has, isEqual, isEmpty, set } from "lodash";

export function removeTypenames(obj) {
    if (Array.isArray(obj)) {
        return obj.map(removeTypenames);
    } else if (typeof obj === "object" && obj !== null) {
        const newObj = {};
        for (const key in obj) {
            if (key !== "__typename") {
                newObj[key] = removeTypenames(obj[key]);
            }
        }
        return newObj;
    } else {
        return obj;
    }
}

const checkIfFile = (obj, fileTypes) => {
    if (isPlainObject(obj)) {
        if (includes(fileTypes, get(obj, 'raw.type'))) {
            return true;
        }
        return some(obj, checkIfFile);
    }

    if (isArray(obj)) {
        return some(obj, checkIfFile);
    }

    return false;
};

async function saveValues({
    state,
    dispatch,
    valuesToSave,
    documentMany,
    createOne,
    updateOne,
    initGraphQL,
}) {
    if (state?.formInputs?._id) {
        const { error, record } = await updateOne({
            variables: {
                record: removeTypenames(valuesToSave),
                filter: { _id: state?.formInputs?._id }
            },
            refetchQueries: [{ query: initGraphQL, variables: { id: state?.formInputs?._id } }, { query: documentMany }],
        });
        if (!isEmpty(error)) throw error;
        // Update form data with updated record data
        if (isEqual(record?._id, state?.formInputs?._id)) {
            console.log("SET INPUT")
            dispatch({
                type: 'SET_FORM_INPUTS', payload: {
                    formInputs: record,
                },
            });
        }
    } else {
        const { error, record } = await createOne({
            variables: { record: removeTypenames(valuesToSave) },
            refetchQueries: [{ query: documentMany }],
        });
        if (!isEmpty(error)) throw error;
        // Update form data with saved record data
        dispatch({
            type: 'SET_FORM_INPUTS', payload: {
                formInputs: record,
            },
        });
    }
}


// Note: could check if Sequential Uploads is possable
async function saveFiles({
    documentName,
    state,
    dispatch,
    filesToSave,
    sendRequest,
}) {
    const formData = new FormData();
    let index = 0;

    for (const key in filesToSave || {}) {
        const fileKey = `file_${index}`;
        const metadataKey = `metadata_${index}`;

        formData.append(fileKey, filesToSave[key]);
        formData.append(metadataKey, JSON.stringify({
            model: `${documentName}DocumentModel`,
            modelId: state?.formInputs?._id,
            field: key,
        }));
        index++;
    }

    const { data, error } = await sendRequest("/upload", "POST", formData);

    if (error) throw error;

    if (!isArray(data)) {
        throw new Error("FileSaveError: An error occured while saving the file");
    }

    for (const file in data || []) {
        dispatch({
            type: 'UPDATE_FORM_INPUT', payload: {
                key: file?.field,
                value: file?.value
            },
        });
    }
}

export async function handleFormSubmit({
    documentName,
    state,
    dispatch,
    documentMany,
    createOne,
    updateOne,
    initGraphQL,
    sendRequest,
    appConfig,
}) {
    const valuesToSave = {};
    const filesToSave = {};

    // Seperate files from regular values
    for (const key in state?.dirtyValues || {}) {
        const _dirtyValue = get(state, `formInputs.${key}`);
        if (checkIfFile(_dirtyValue, appConfig.fileUploadTypes)) {
            set(filesToSave, `${key}`, _dirtyValue);
            continue;
        }
        set(valuesToSave, `${key}`, _dirtyValue);
    };

    // Handle Refs
    for (const key in state?.refs || {}) {
        if (has(state, `refs.${key}._id`)) {
            set(valuesToSave, `refs.${key}._id`, get(state, `refs.${key}._id`));
        }
    }

    await saveValues({
        documentName,
        state,
        dispatch,
        valuesToSave,
        documentMany,
        createOne,
        updateOne,
        initGraphQL,
    });

    // await saveFiles({
    //     documentName,
    //     state,
    //     dispatch,
    //     filesToSave,
    //     sendRequest,
    // });
}
