import { gql } from "@apollo/client";

export const VCARD_FRAGMENT = gql`
    fragment VCardFields on VCard {
        _id
        Photo {
            signedUrl
        }
        Prefix
        ContactName
        FirstName
        PhoneticFirstName
        PronounciationFirstName
        MiddleName
        LastName
        PhoneticLastName
        PronounciationLastName
        MaidenName
        Suffix
        Nickname
        JobTitle
        Department
        Company
        IsCompany
        PhoneticCompanyName
        Phones {
            PhoneType
            PhoneNumber
            _id
        }
        Emails {
            EmailType
            EmailAddress
            _id
        }
        Addresses {
            AddressType
            Street
            City
            StateProvince
            PostalCode
            Country
            _id
        }
        Websites {
            WebsiteType
            WebsiteURL
            _id
        }
        Birthday {
            Date
            _id
        }
        Anniversary {
            Date
            _id
        }
        RelatedVcard {
            vcard
            _id
        }
        InstantMessagings {
            IMType
            IMAddress
            _id
        }
        SocialProfiles {
            SocialProfileType
            SocialProfileURL
            _id
        }
        Timezone {
            TimezoneName
            Offset
            _id
        }
        Notes
    }
`;
