import styled from "styled-components";
import TreeMenu from "../../Content/common/TreeMenu";

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

function Sidebar({data, handleSelection}) {
    return <Wrapper>
        <TreeMenu data={data} handleSelection={handleSelection}/>
    </Wrapper>;
};

export default Sidebar;