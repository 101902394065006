import React from 'react';
import styled from 'styled-components';
import Select from './Select';
import Text from './Text';
import FieldWrapper from './FieldWrapper';

const AddressWrapper = styled.fieldset`
  display: flex;
  flex-direction: column;
  border: none;
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

const AddressRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  align-items: stretch;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

const Address = React.memo(({ isEditing, name, index = undefined, ...props }) => {
  /* Context */
  return (
    <AddressWrapper>
      <AddressRow>
        <FieldWrapper
          FieldComponent={Select}
          index={index}
          name={`${name}.AddressType`}
          translationKey='FormBuilder.Fields.Address.AddressType'
          autoComplete="address-type"
          optionsConfig={{
            options: props?.typeOptions?.keyOptions
          }}
          {...props}
        />
        <FieldWrapper
          FieldComponent={Text}
          index={index}
          name={`${name}.Street`}
          translationKey='FormBuilder.Fields.Address.StreetAddress'
          autoComplete="street-address"
          {...props}
        />
      </AddressRow>
      <AddressRow>
        <FieldWrapper
          FieldComponent={Text}
          index={index}
          name={`${name}.City`}
          translationKey='FormBuilder.Fields.Address.City'
          autoComplete="address-level2"
          {...props}
        />
        <FieldWrapper
          FieldComponent={Text}
          index={index}
          name={`${name}.StateProvince`}
          translationKey='FormBuilder.Fields.Address.StateProvince'
          autoComplete="address-level1"
          {...props}
        />
        <FieldWrapper
          FieldComponent={Text}
          index={index}
          name={`${name}.PostalCode`}
          translationKey='FormBuilder.Fields.Address.PostalCode'
          autoComplete="postal-code"
          {...props}
        />
      </AddressRow>
      <AddressRow>
        <FieldWrapper
          FieldComponent={Text}
          index={index}
          name={`${name}.Country`}
          translationKey='FormBuilder.Fields.Address.Country'
          autoComplete="country"
          {...props}
        />
      </AddressRow>
    </AddressWrapper>
  );
});

export default Address;
