
import Text from "../../components/FormBuilder/fields/Text";
import Select from "../../components/FormBuilder/fields/Select";
import TextArea from "../../components/FormBuilder/fields/TextArea";

export const fields = [
    {
        label: "Details",
        fieldSet: [
            {
                label: 'Type',
                name: 'Type',
                type: Select,
                example: '',
                placeholder: 'Select Note Type',
                ariaLabel: 'Note Type',
                ariaDescribedby: 'Select Note Type',
                limit: 30,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'LandScaping', caption: 'LandScaping' },
                        { value: 'New Construction', caption: 'New Construction' },
                        { value: 'Retrofit', caption: 'Retrofit' }
                    ],
                }
            },
            {
                label: 'File Upload',
                name: 'Files',
                type: Text,
                example: '',
                placeholder: 'Enter File Upload',
                ariaLabel: 'File Upload',
                ariaDescribedby: 'Enter File Upload',
                autoComplete: '',
            },
            {
                label: 'Description',
                name: 'Description',
                type: TextArea,
                example: '',
                placeholder: 'Enter Description',
                ariaLabel: 'Description',
                ariaDescribedby: 'Enter Description',
                autoComplete: '',
            },
        ]
    },
];