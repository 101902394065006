import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../Content/Modal';
import Task from '../../Documents/Task';

const GridItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const BodyWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.small};
`;

const BodyWrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.defaultText};
  gap: ${({ theme }) => theme.spacing.xxxxxsmall};
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.defaultText};
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => theme.spacing.small};
  padding-top: 0px;
`;

const GridLink = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
`;

const Header = styled.h3`
  flex: 1;
  text-align: center;
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
  box-sizing: border-box;
  margin: 0px;
  color: ${({ theme }) => theme.colors.defaultText};
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: ${({ theme }) => theme.borderRadius.medium} ${({ theme }) => theme.borderRadius.medium} 0 0;
`;

const Code = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  padding: 0px;
  margin: 0px;
  font-weight: bold;
`;

const Title = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  flex: 1;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-height: 1.3em;
  -webkit-box-orient: vertical;  
  height: 1.3em;
  color: ${({ theme }) => theme.colors.defaultText};
`;

const Details = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.disabledText};
  padding: 0px;
  margin: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  line-height: 1.3em; 
  height: 3.9em;
`;

const Priority = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  padding: 0px;
  margin: 0px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
`;

const DeadLine = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  padding: 0px;
  margin: 0px;
`;

const IconWrapper = styled.span`
    display: flex;
    flex-direction: row;
    justify-content: center;
    cursor: pointer;
`;

const TaskGridItem = ({ data, showStatus = true, showChat = true, showLocation = true }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return <GridItemContainer>
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} hideCloseButton={true}>
      <Task JobSitesData={data} title={data.Title} code={data.Code} close={handleCloseModal} cancel={handleCloseModal} />
    </Modal>
    <GridLink onClick={handleOpenModal}>
      {showStatus && <Header>{data.Stage.name}</Header>}
      <BodyWrapperRow>
        <BodyWrapperColumn>
          <Code>{data.Code}</Code>
          <Title>{data.Title}</Title>
          <Details>{data.Details}</Details>
        </BodyWrapperColumn>
        <IconWrapper >
          <FontAwesomeIcon icon={faChevronRight} />
        </IconWrapper>
      </BodyWrapperRow>
    </GridLink>
    <FooterWrapper>
      <DeadLine>. . .</DeadLine>
      <Priority>P1</Priority>
      <DeadLine>5 Days Remaining</DeadLine>
      {showChat && <IconWrapper>
        <FontAwesomeIcon icon={faComment} />
      </IconWrapper>}
      {showLocation && <IconWrapper>
        <FontAwesomeIcon icon={faLocationDot} />
      </IconWrapper>}
    </FooterWrapper>
  </GridItemContainer>
};

export default TaskGridItem;
