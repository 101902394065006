import React, { useContext } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { LayoutContext } from '../../Utill/LayoutContext';

// Styling for the modal background
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #33333399;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease-out, visibility 0s linear ${({ isOpen }) => (isOpen ? '0s' : '0.2s')};
`;

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const ModalContent = styled.div`
  background-color: ${({ theme }) => theme.colors.contentArea};
  padding: 20px;
  border-radius: 4px;
  width: 80%;
  height: 85%;
  position: relative;
  margin-top: auto;
  margin-left: ${({ mainLeftMargin }) => mainLeftMargin};
  margin-right: 0px;
  box-sizing: border-box;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  padding: ${({ theme }) => theme.spacing.small};
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0px;
    margin-top: 10vh;
    width: 100vw;
    height: 100vh;
  }
`;


// Styling for the close button
const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
`;

const Modal = ({ isOpen, onClose, hideCloseButton = false, children }) => {
  const { LayoutValue } = useContext(LayoutContext);
  return (
    <>
      <ModalBackground isOpen={isOpen} />
      {isOpen && <GlobalStyle />}
      <ModalContent isOpen={isOpen} mainLeftMargin={LayoutValue.mainLeftMargin}>
        {!hideCloseButton && <CloseButton onClick={onClose}>&times;</CloseButton>}
        {children}
      </ModalContent>
    </>
  );
};

export default Modal;
