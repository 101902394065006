import styled from "styled-components";
import AttachmentPreview from "./preview.js";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.small};
`;

const JobSiteAttachments = ({ Attachments, Job }) => {
  return <Wrapper>
    {
      Attachments?.map((Attachment, index) => {
        return <AttachmentPreview
          key={`note-${index}`}
          Attachments={Attachment}
          Job={Job} />
      })
    }
  </Wrapper>;
};

export default JobSiteAttachments;