import styled from "styled-components";
import MenuItem from "./MenuItem";
import { Link, NavLink, Route } from "react-router-dom"; // Import Link and Route

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const Title = styled.h3`
  padding: 0;
  margin: 0;
  text-align: left;
`;

const LinkTo = styled(NavLink)`
  text-decoration: none;
`;

function VerticalMenuLink({ MenuSections }) {
    return (
        <Wrapper>
            {MenuSections?.map((section) => (
                <OptionWrapper key={section?.title}>
                    <Title>{section?.title}</Title>
                    {section?.options?.map((option) => (
                        <LinkTo
                            key={option?.title}
                            to={option?.linkTo}
                        >
                            <MenuItem
                                title={option?.title}
                                component={option?.component}
                            />
                        </LinkTo>
                    ))}
                </OptionWrapper>
            ))}
        </Wrapper>
    );
}

export default VerticalMenuLink;
