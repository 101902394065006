
import Text from "../../components/FormBuilder/fields/Text";
import Select from "../../components/FormBuilder/fields/Select";
import TextArea from "../../components/FormBuilder/fields/TextArea";
import SelectLookup from "../../components/FormBuilder/fields/SelectLookup";
import { get, map } from "lodash";

export const fields = [
    {
        label: "Name",
        fieldSet: [
            {
                name: 'Name',
                type: Text,
                autoComplete: '',
            },
        ]
    },
    {
        label: "StatusPriority",
        fieldSet: [
            {
                name: 'Status',
                type: Select,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'Pending', caption: 'Pending' },
                        { value: 'Active', caption: 'Active' },
                        { value: 'Complete', caption: 'Complete' },
                    ],
                },
            },
            {
                name: 'Priority',
                type: Select,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'Level 1', caption: 'Level 1' },
                        { value: 'Level 2', caption: 'Level 2' },
                        { value: 'Level 3', caption: 'Level 3' },
                    ],
                },
            },
        ]
    },
    {
        label: "PersonnelAssignment",
        fieldSet: [
            {
                name: 'Assignees',
                type: SelectLookup,
                autoComplete: '',
                multiple: true,
                multiplekey: '_id',
                optionsConfig: {
                    loadFromState: (state) => {
                        const profiles = get(state, 'localFormState.ProfileFindMany');
                        return map(profiles, (profile) => {
                            return {
                                caption: get(profile, 'VCard.ContactName'),
                                value: get(profile, '_id'),
                            };
                        });
                    }
                },
            },
        ]
    },
    {
        label: "TimeLine",
        fieldSet: [
            {
                name: 'LabourTimeEstimate',
                type: Text,
                autoComplete: '',
            },
        ]
    },
    {
        label: "Description",
        fieldSet: [
            {
                name: 'Description',
                type: TextArea,
                autoComplete: '',
            },
        ]
    },
];