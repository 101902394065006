import React, { createContext, useState } from 'react';

export const LayoutContext = createContext();

export const LayoutContextProvider = ({ children }) => {
  const [LayoutValue, setLayoutValue] = useState({});

  return (
    <LayoutContext.Provider value={{ LayoutValue, setLayoutValue }}>
      {children}
    </LayoutContext.Provider>
  );
};
