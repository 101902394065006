import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import JobSiteCard from './JobSiteCard';

const GridItemContainer = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  height: fit-content;
  justify-content: space-between;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
`;

const GridLink = styled(Link)`
  flex: 1;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.defaultText};
`;

const JobSiteCardLink = ({ data }) => (
  <GridItemContainer>
    <GridLink
      to={{
        pathname: `/jobsite/${data._id}`,
      }}
    >
      <JobSiteCard JobSite={data} />
    </GridLink>
  </GridItemContainer >
);

export default JobSiteCardLink;
