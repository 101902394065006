import React, { Component } from 'react';
import { ErrorContext } from '../../../providers/ErrorProvider/ErrorProvider';

class ErrorBoundary extends Component {
    static contextType = ErrorContext;

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        this.context.handleError(error);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong.</h1>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
