import React from 'react';


const InvoicePreview = ({ Job, Invoice }) => {
    return (
        <>
            
        </>
    );
};

export default InvoicePreview;
