/* Routes.js */
import Calendar from "./Pages/Calendar";
import Chat from "./Pages/Chat";
import AdminNav from "./Pages/Admin/AdminNav";
import Clients from "./Pages/Admin/Clients";
import JobSites from "./Pages/JobSites";
import JobSiteOverview from "./Pages/Jobsite";
import JobSiteAttachments from "./Pages/Jobsite/JobSiteAttachments";
import JobSiteClientAvailability from "./Pages/Jobsite/JobSiteClientAvailability";
import JobSiteDetails from "./Pages/Jobsite/JobSiteOverview/JobSiteDetails";
import JobSiteInvoices from "./Pages/Jobsite/JobSiteInvoices";
import JobSiteNotes from "./Pages/Jobsite/JobSiteNotes";
import JobSiteQuotes from "./Pages/Jobsite/JobSiteQuotes";
import MyTasks from "./Pages/MyTasks";
import Notifications from "./Pages/Notifications";
import Profile from "./Pages/Profile";
import PrivateRoute from "./PrivateRoute";
import DisplayError from "./Utill/ErrorMessage";
import Admin from "./Pages/Admin/Admin";
import UserProfiles from "./Pages/Admin/UserProfiles/UserProfiles";

const routes = [
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <DisplayError />,
    children: [
      {
        label: "My Tasks",
        path: "",
        element: <MyTasks />,
      },
      {
        label: "My Tasks",
        path: "mytasks",
        element: <MyTasks />,
      },
      {
        label: "Chat",
        path: "chat",
        element: <Chat />,
      },
      {
        label: "Job Sites",
        path: "jobsites",
        element: <JobSites />,
        loader: () => {
          return { header: true };
        },
      },
      {
        label: "Job Site",
        path: "jobsite/:id",
        element: <JobSiteOverview />,
        loader: ({ params }) => {
          return { id: params.id };
        },
      },
      {
        label: "Details",
        path: "jobsite/:code/details",
        element: <JobSiteDetails />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Notes",
        path: "jobsite/:code/notes",
        element: <JobSiteNotes />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Client Availability",
        path: "jobsite/:code/client-availability",
        element: <JobSiteClientAvailability />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Tasks",
        path: "jobsite/:code/tasks",
        element: <JobSiteNotes />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Quotes",
        path: "jobsite/:code/quotes",
        element: <JobSiteQuotes />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Invoices",
        path: "jobsite/:code/invoices",
        element: <JobSiteInvoices />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Attachments",
        path: "jobsite/:code/attachments",
        element: <JobSiteAttachments />,
        loader: ({ params }) => {
          return { code: params.code };
        },
      },
      {
        label: "Calendar",
        path: "calendar",
        element: <Calendar />,
      },
      {
        label: "Admin",
        path: "admin",
        element: <Admin />,
        loader: () => {
          return { header: true };
        },
        children: [
          {
            label: "Company",
            path: "",
            element: <AdminNav />,
          },
          {
            label: "UserProfile",
            path: "user-profiles",
            element: <UserProfiles />,
          },
          {
            label: "Clients",
            path: "clients",
            element: <Clients />,
          },
        ]
      },
      {
        label: "Notifications",
        path: "notifications",
        element: <Notifications />,
      },
      {
        label: "Profile",
        path: "profile",
        element: <Profile />,
      },
    ]
  },
  // other routes...
];

export { routes };