import { gql } from "@apollo/client";
import { CLIENT_AVAILABLITY_FRAGMENT } from "../Fragments/ClientAvailablity";

export const ClientAvailablityCreateOne = gql`
    ${CLIENT_AVAILABLITY_FRAGMENT}
    mutation Mutation($record: ClientAvailablityCreateOneWithRelationship!) {
        ClientAvailablityCreateOne(record: $record) {
            record {
                ...ClientAvailablityFields
            }
        }
    }
`;

export const ClientAvailablityUpdateOne = gql`
    mutation ClientAvailablityUpdateOne($record: ClientAvailablityUpdateOneWithRelationship!, $filter: FilterUpdateOneClientAvailablityInput) {
        ClientAvailablityUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const ClientAvailablityRemoveFindById = gql`
    mutation ClientAvailablityRemoveFindById($id: MongoID!) {
        ClientAvailablityRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
