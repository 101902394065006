import { gql } from "@apollo/client";
import { VCARD_FRAGMENT } from "./VCard";

export const PROFILE_FRAGMENT = gql`
    ${VCARD_FRAGMENT}
    fragment ProfileFields on Profile {
        VCard {
            ...VCardFields
        }
        _id
    }
`;

export const PROFILE_FRAGMENT_LOOKUP_FIELDS = gql`
    ${VCARD_FRAGMENT}
    fragment ProfileLookupFields on Profile {
        VCard {
            ContactName
            _id
        }
        _id
    }
`;
