/* Landing.js */
import styled from 'styled-components';
import LoginButton from '../Auth/LoginButton';
import LogoBlack from '../../assets/images/logo-black.png';

const FullPageDiv = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: rgb(255,207,94);
  background: linear-gradient(180deg, rgba(255,207,94,1) 0%, rgba(51,104,222,1) 100%);
`;

const Landing = props => {
  return (
    <FullPageDiv>
      <img src={LogoBlack} alt="Logo" width={100} />
      <p>Welcome to Jobsite Central</p>
      <LoginButton />
    </FullPageDiv>
  );
}

export default Landing;