import Text from "../../components/FormBuilder/fields/Text";
import Select from "../../components/FormBuilder/fields/Select";
import SelectLookup from "../../components/FormBuilder/fields/SelectLookup";
import TextArea from "../../components/FormBuilder/fields/TextArea";
import { get, map } from "lodash";

export const fields = [
    {
        label: 'Managers',
        fieldSet: [
            {
                name: 'Managers',
                type: SelectLookup,
                autoComplete: '',
                multiple: true,
                multiplekey: '_id',
                optionsConfig: {
                    loadFromState: (state) => {
                        const profiles = get(state, 'localFormState.ProfileFindMany');
                        return map(profiles, (profile) => {
                            return {
                                caption: get(profile, 'VCard.ContactName'),
                                value: get(profile, '_id'),
                            };
                        });
                    }
                },
            },
        ],
    },
    {
        label: 'Details',
        fieldSet: [
            {
                name: 'Status',
                type: Select,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'Pending', caption: 'Pending' },
                        { value: 'Active', caption: 'Active' },
                        { value: 'Complete', caption: 'Complete' },
                    ],
                },
            },
            {
                name: 'Name',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'Type',
                type: Select,
                limit: 30,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'LandScaping', caption: 'LandScaping' },
                        { value: 'New Construction', caption: 'New Construction' },
                        { value: 'Retrofit', caption: 'Retrofit' }
                    ],
                }
            },
            {
                name: 'Description',
                type: TextArea,
                autoComplete: '',
            },
            {
                name: 'Priority',
                type: Select,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'Level 1', caption: 'Level 1' },
                        { value: 'Level 2', caption: 'Level 2' },
                        { value: 'Level 3', caption: 'Level 3' },
                    ],
                },
            },
        ],
    },
    {
        label: 'Timeline',
        fieldSet: [
            {
                name: 'TargetStartDate',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'TargetEndDate',
                type: Text,
                autoComplete: '',
            },
        ],
    },
];