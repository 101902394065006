// SelectLookup.js
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { produce } from 'immer';
import { filter, find, get, isArray, isEmpty, isEqual, isFunction, isObject, isString, pullAt, set, some, trim } from 'lodash';
import React, { useCallback, useEffect, useReducer, useRef } from 'react';
import styled from 'styled-components';
import { getFormValue } from '../../../../hooks/useForm/useForm.utils';

const FieldInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  flex: 1;
  position: relative;
  box-sizing: content-box;
`;

const FieldInput = styled.input`
  /* Text settings */
  /* text-overflow: ellipsis;
  overflow: hidden; */

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: ${({ theme }) => theme.spacing.xxxxxsmall};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.defaultText};

  padding-right: 30px;
  flex: 1;
  background: transparent;
  border: none !important;
  outline: none !important;
  /* padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall}; */
`;

const OptionsDropdown = styled.ul`
  position: absolute;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: ${({ theme }) => theme.spacing.xxxxxsmall};
  background-color: ${({ theme }) => theme.colors.white};
  margin: 0px;
  padding: 0px;
  top: 100%;
  list-style-type: none;
  z-index: 1000;
`;

const OptionItem = styled.li`
   padding: 5px;
  cursor: pointer;
  background-color: ${props => props.highlighted ? '#e6e6e6' : 'transparent'}; // You can change this to any desired color
  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const MagnifyingIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
`;

const TextInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  background-color: ${({ theme }) => theme.colors.white}; 
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: ${({ theme }) => theme.spacing.xxxxxsmall};
  min-height: ${({ theme }) => theme.spacing.xxlarge};

  outline-color: ${({ theme, isFocused }) => isFocused ? `${theme.borderColor.outline}` : `${theme.colors.mediumGrey}`};
  outline-style: ${({ theme, isFocused }) => isFocused ? 'auto' : 'none'};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
`;

const SelectedValue = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondary}50;
  border-radius: ${({ theme }) => theme.spacing.xxxxsmall};
  padding: ${({ theme }) => theme.spacing.xxxxxsmall} ${({ theme }) => theme.spacing.xxxsmall};
  height: ${({ theme }) => theme.spacing.small};
  max-height: ${({ theme }) => theme.spacing.small};
`;

const ClearIcon = styled.span`
  cursor: pointer;
  margin-left: ${({ theme }) => theme.spacing.xxxxsmall};
`;

const initialState = {
    // The value in the text input field
    value: '',
    selectedValue: '',
    selectedValues: [],
    initalOptions: [],
    options: [],
    highlightedIndex: 0,
    isInputFocused: false,
    multiple: false,
    multiplekey: '',
};

function defalutOptionsfilter(state) {
    let options = [];
    if (state?.value && isString(state?.value) && trim(state?.value) !== '') {
        options = state?.initalOptions?.filter(option => option?.caption?.toLowerCase()?.includes(state?.value?.toLowerCase()));
        if (state.multiple) {
            const selectedValues = state?.selectedValues;
            const multiplekey = state?.multiplekey;

            options = options?.filter(option => {
                const optionValue = option?.value;
                const shouldInclude = !some(selectedValues, selectedValue =>
                    selectedValue?.[multiplekey] === optionValue
                );
                return shouldInclude;
            });
        }
    }
    return options;
};

function updateMainFormState(dispatch, state, name, index) {
    const payload = {
        key: name,
        value: state?.selectedValue,
        index
    };

    if (state.multiple) {
        payload.value = state.selectedValues;
    }

    dispatch({ type: 'UPDATE_FORM_INPUT', payload });
}

function keyboardControls(state, action) {
    switch (action.payload?.event?.key) {
        case 'Backspace':
            if (isEmpty(state?.value)) {
                if (state.multiple) {
                    return produce(state, draft => {
                        if (draft.multiple) {
                            draft.selectedValues.pop();
                        }
                        set(draft, `value`, '');
                        set(draft, `highlightedIndex`, -1);
                        draft.needsUpdate = true;
                    });
                } else {
                    return produce(state, (draft) => {
                        set(draft, 'value', '');
                        set(draft, 'selectedValue', '');
                        set(draft, 'highlightedIndex', -1);
                        draft.needsUpdate = true;
                    });
                }
            }
            return state;
        case 'ArrowDown':
            action.payload?.event.preventDefault();
            return produce(state, draft => {
                set(draft, `highlightedIndex`, Math.min(draft?.highlightedIndex + 1, draft?.options?.length - 1));
            });
        case 'ArrowUp':
            action.payload?.event.preventDefault();
            return produce(state, draft => {
                set(draft, `highlightedIndex`, Math.max(draft?.highlightedIndex - 1, 0));
            });
        case 'Enter':
            action.payload?.event.preventDefault();
            if (!isEmpty(state?.value)) {
                return produce(state, draft => {
                    const value = state?.options?.[draft?.highlightedIndex || 0]?.value || '';
                    if (draft.multiple) {
                        draft.selectedValues.push({ [state.multiplekey]: value });
                    } else {
                        set(draft, `selectedValue`, value);
                    }
                    set(draft, `value`, '');
                    draft.needsUpdate = true;
                });
            }
            return state;
        default:
            return state;
    }
};

function selectReducer(state, action) {
    let _state;
    switch (action.type) {
        // Action that handles the updating the text in the text field
        case 'UPDATE_VALUE':
            _state = produce(state, draft => {
                if (isEmpty(draft?.selectedValue) || draft.multiple) {
                    if (isEqual(draft?.value, '') && isEqual(draft?.highlightedIndex, -1)) {
                        set(draft, `highlightedIndex`, 0);
                    }
                    set(draft, `value`, action.payload);
                    set(draft, `options`, defalutOptionsfilter(draft));
                }
            });
            return _state;
        // Action that handles the initialization of selected values on load
        case 'SET_SELECTED':
            _state = produce(state, (draft) => {
                if (draft.multiple) {
                    const selectedValues = action.payload?.value;
                    draft.selectedValues = selectedValues || [];
                } else {
                    draft.selectedValue = action.payload?.value;
                }
                set(draft, 'highlightedIndex', action.payload?.highlightedIndex);
                set(draft, 'value', '');
            });
            return _state;
        // Action that handles when a user clicks with there mmouse on an option
        case 'UPDATE_SELECTED':
            return produce(state, (draft) => {
                if (draft.multiple) {
                    draft.selectedValues.push({ [state.multiplekey]: action.payload?.value });
                } else {
                    draft.selectedValue = action.payload?.value;
                }
                set(draft, 'highlightedIndex', action.payload?.highlightedIndex);
                set(draft, 'value', '');
                draft.needsUpdate = true;
            });
        // Action that handles setting the options on initilization
        case 'SET_OPTIONS':
            return produce(state, draft => {
                set(draft, `initalOptions`, action.payload);
                set(draft, `options`, action.payload);
            });
        // Action that handles clearing items when not in multi mode
        case 'CLEAR':
            return produce(state, (draft) => {
                set(draft, 'value', '');
                set(draft, 'selectedValue', '');
                set(draft, 'highlightedIndex', -1);
                set(draft, `isInputFocused`, false);
                draft.needsUpdate = true;
            });
        // Action that handles clearing items when in multi mode
        case 'CLEAR_SELECTED':
            return produce(state, (draft) => {
                const indexToRemove = action.payload?.index;
                pullAt(draft.selectedValues, indexToRemove);
                set(draft, `highlightedIndex`, -1);
                set(draft, `isInputFocused`, false);
                draft.needsUpdate = true;
            });
        case 'RESET_UPDATE_FLAG':
            return produce(state, (draft) => {
                draft.needsUpdate = false;
            });
        // Action that handles key presses
        case 'KEYDOWN':
            _state = keyboardControls(state, action);
            return _state;
        case 'FOCUS':
            return produce(state, draft => {
                set(draft, `isInputFocused`, true);
            });
        case 'BLUR':
            return produce(state, draft => {
                set(draft, `isInputFocused`, false);
            });
        default:
            return state;
    }
}

const SelectLookup = React.memo(({
    state: formState,
    dispatch: formDispatch,
    name = '',
    index = undefined,
    limit = 15,
    multiple = false,
    multiplekey = '',
    optionsConfig,
    ...props
}) => {
    const currentValue = getFormValue(formState?.formInputs, name, index);
    /* Reducer */
    const [state, dispatch] = useReducer(selectReducer, { ...initialState, multiple, multiplekey });
    /* Refs */
    const inputRef = useRef(null);
    const optionRefs = useRef([]);

    /* useEffect */
    useEffect(() => {
        dispatch({ type: 'SET_SELECTED', payload: { value: currentValue, highlightedIndex: -1 } });
    }, [currentValue]);

    useEffect(() => {
        if (isObject(optionsConfig)) {
            let newOptions = [];

            if (isFunction(optionsConfig.loadFromState)) {
                const rawOptions = optionsConfig.loadFromState(formState);
                newOptions = filter(rawOptions, option => {
                    if (isObject(option)) {
                        return !isEmpty(option?.caption) && !isEmpty(option?.value);
                    }
                    return false;
                });
            } else {
                newOptions = get(optionsConfig, 'options', []);
            }

            if (!isEqual(state.initalOptions, newOptions)) {
                dispatch({ type: 'SET_OPTIONS', payload: newOptions });
            }
        }
    }, [formState, optionsConfig, state.initalOptions]);

    useEffect(() => {
        if (state.needsUpdate) {
            // Perform the update
            updateMainFormState(formDispatch, state, name, index);
            // Reset the flag
            dispatch({ type: 'RESET_UPDATE_FLAG' });
        }
    }, [state.needsUpdate, state, formDispatch, name, index]);

    // Add Event Listeners to the input field
    useEffect(() => {
        const handleFocus = () => {
            if (!formState?.isEditing) return;
            dispatch({ type: 'FOCUS' })
        };
        const handleBlur = () => dispatch({ type: 'BLUR' });

        const inputElement = inputRef.current;
        inputElement.addEventListener('focus', handleFocus);
        inputElement.addEventListener('blur', handleBlur);

        return () => {
            inputElement.removeEventListener('focus', handleFocus);
            inputElement.removeEventListener('blur', handleBlur);
        }
    }, [formState, inputRef]);

    // Add Scroll To Event
    useEffect(() => {
        const highlightedOption = optionRefs.current[state?.highlightedIndex];
        if (highlightedOption) {
            highlightedOption.scrollIntoView({
                block: "nearest"
            });
        }
    }, [state?.highlightedIndex]);

    /* Handle Changes */
    const handleInputChange = (event) => {
        event.preventDefault();
        dispatch({ type: 'UPDATE_VALUE', payload: event?.target?.value });
    };

    const handleClear = () => {
        dispatch({ type: 'CLEAR' });
    };

    const handleClearSelected = (indexToRemove) => {
        dispatch({
            type: 'CLEAR_SELECTED',
            payload: {
                index: indexToRemove,
            },
        });
    };

    const handleKeyDown = (e) => {
        dispatch({ type: 'KEYDOWN', payload: { event: e } });
    };

    return <React.Fragment>
        <FieldInputWrapper>
            <TextInputWrapper
                tabIndex={-1}
                onFocusCapture={() => {
                    if (!formState?.isEditing) return;
                    dispatch({ type: 'FOCUS' })
                }}
                onBlur={() => dispatch({ type: 'BLUR' })}
                className={'error'}
                isFocused={state?.isInputFocused}>
                {state?.multiple && state?.selectedValues?.map((selectedValue, index) => (
                    <SelectedValue
                        tabIndex={-1}
                        onFocusCapture={() => {
                            if (!formState?.isEditing) return;
                            dispatch({ type: 'FOCUS' })
                        }}
                        onBlur={() => dispatch({ type: 'BLUR' })}
                        key={index}>
                        {get(find(state?.initalOptions, ['value', selectedValue?.[multiplekey]]), 'caption')}
                        {formState?.isEditing &&
                            <ClearIcon onClick={() => handleClearSelected(index)}>&nbsp;&nbsp;x</ClearIcon>
                        }
                    </SelectedValue>
                ))}
                {!state.multiple && !isEmpty(state?.selectedValue) &&
                    <SelectedValue>
                        {get(find(state?.initalOptions, ['value', state?.selectedValue]), 'caption')}
                        {formState?.isEditing &&
                            <ClearIcon onClick={handleClear}>&nbsp;&nbsp;x</ClearIcon>
                        }
                    </SelectedValue>
                }
                <FieldInput
                    {...props}
                    name={name}
                    type="text"
                    autoComplete={'off'}
                    value={state?.value}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    ref={inputRef}
                />
                <MagnifyingIcon><FontAwesomeIcon icon={faChevronDown} /></MagnifyingIcon>
            </TextInputWrapper>
            {
                (state?.value && !state?.selectedValue) && state?.options?.length > 0 && (
                    <OptionsDropdown>
                        {
                            state?.options?.map((option, optionIndex) => (
                                <OptionItem
                                    key={optionIndex}
                                    ref={(el) => optionRefs.current[optionIndex] = el}
                                    highlighted={optionIndex === state?.highlightedIndex}
                                    onClick={() => {
                                        dispatch({ type: 'UPDATE_SELECTED', payload: { value: option?.value, highlightedIndex: optionIndex } });
                                    }}>
                                    {option?.caption}
                                </OptionItem>
                            ))
                        }
                    </OptionsDropdown>
                )
            }
        </FieldInputWrapper>
    </React.Fragment>;
});

export default SelectLookup;
