// client.form.fields.js
import PhoneNumber from "../../components/FormBuilder/fields/PhoneNumber";
import Text from "../../components/FormBuilder/fields/Text";
import TextArea from "../../components/FormBuilder/fields/TextArea";
import Url from "../../components/FormBuilder/fields/Url";
import Address from "../../components/FormBuilder/fields/Address";
import Email from "../../components/FormBuilder/fields/Email";
import InstantMessage from "../../components/FormBuilder/fields/InstantMessage";
import SocialProfile from "../../components/FormBuilder/fields/SocialProfiles";
import TimeZone from "../../components/FormBuilder/fields/TimeZone";
import FieldMultipleList from "../../components/FormBuilder/fields/FieldMultipleList";
import DateField from "../../components/FormBuilder/fields/Date";
import Checkbox from "../../components/FormBuilder/fields/Checkbox";
import ColourPicker from "../../components/FormBuilder/fields/ColourPicker";

export const fields = [
    {
        label: 'NoLabel',
        fieldSet: [
            {
                name: 'Colour',
                type: ColourPicker,
            },
            {
                name: 'Code',
                type: Text,
                limit: 6,
            },
        ],
    },
    {
        label: 'NoLabel',
        fieldSet: [
            {
                name: 'VCard.Prefix',
                type: Text,
                autoComplete: 'honorific-prefix',
            },
            {
                name: 'VCard.FirstName',
                type: Text,
                autoComplete: 'given-name',
            },
            {
                name: 'VCard.PhoneticFirstName',
                type: Text,
                autoComplete: 'additional-name',
            },
            {
                name: 'VCard.PronounciationFirstName',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'VCard.MiddleName',
                type: Text,
                autoComplete: 'additional-name',
            },
            {
                name: 'VCard.PhoneticLastName',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'VCard.LastName',
                type: Text,
                autoComplete: 'family-name',
            },
            {
                name: 'VCard.PronounciationLastName',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'VCard.MaidenName',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'VCard.Suffix',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'VCard.Nickname',
                type: Text,
                autoComplete: 'nickname',
            },
            {
                name: 'VCard.JobTitle',
                type: Text,
                autoComplete: 'organization-title',
            },
            {
                name: 'VCard.Department',
                type: Text,
                autoComplete: 'organization-unit'
            },
            {
                name: 'VCard.Company',
                type: Text,
                autoComplete: 'organization',
            },
            {
                name: 'VCard.IsCompany',
                type: Checkbox,
                autoComplete: 'organization',
            },
            {
                name: 'VCard.PhoneticCompanyName',
                type: Text,
                autoComplete: '',
            }
        ]
    },
    {
        name: 'VCard.Phones',
        type: FieldMultipleList,
        typeOptions: {
            genericType: PhoneNumber,
            keyName: 'PhoneType',
            keyOptions: [
                { value: 'Home', caption: 'Home' },
                { value: 'Work', caption: 'Work' },
                { value: 'Cell', caption: 'Cell' },
                { value: 'Other', caption: 'Other' },
            ],
        },
        autoComplete: 'tel',
    },
    {
        name: 'VCard.Emails',
        type: FieldMultipleList,
        typeOptions: {
            genericType: Email,
            keyName: 'EmailType',
            keyOptions: [
                { value: 'Home', caption: 'Home' },
                { value: 'Work', caption: 'Work' },
                { value: 'Other', caption: 'Other' },
            ],
        },
        autoComplete: 'email',
    },
    {
        name: 'VCard.Addresses',
        type: FieldMultipleList,
        typeOptions: {
            genericType: Address,
            keyName: 'AddressType',
            keyOptions: [
                { value: 'Home', caption: 'Home' },
                { value: 'Work', caption: 'Work' },
                { value: 'Other', caption: 'Other' },
            ],
        }
    },
    {
        name: 'VCard.Websites',
        type: FieldMultipleList,
        typeOptions: {
            genericType: Url,
            keyName: 'WebsiteType',
            keyOptions: [
                { value: 'Personal', caption: 'Personal' },
                { value: 'Work', caption: 'Work' },
                { value: 'Other', caption: 'Other' },
            ],
        },
        autoComplete: 'url',
    },
    {
        name: 'VCard.Birthday',
        type: DateField,
    },
    {
        name: 'VCard.Anniversary',
        type: DateField,
    },
    {
        name: 'VCard.RelatedVcard',
        type: Text,
    },
    {
        name: 'VCard.InstantMessagings',
        type: FieldMultipleList,
        typeOptions: {
            genericType: InstantMessage,
            keyName: 'IMType',
            keyOptions: [
                { value: 'FaceBook', caption: 'FaceBook' },
                { value: 'Teams', caption: 'Teams' },
                { value: 'Google Hangouts', caption: 'Google Hangouts' },
                { value: 'Other', caption: 'Other' },
            ],
        }
    },
    {
        name: 'VCard.SocialProfiles',
        type: FieldMultipleList,
        typeOptions: {
            genericType: SocialProfile,
            keyName: 'SocialProfileType',
            keyOptions: [
                { value: 'FaceBook', caption: 'FaceBook' },
                { value: 'LinkedIn', caption: 'LinkedIn' },
                { value: 'Twitter', caption: 'Twitter' },
                { value: 'Other', caption: 'Other' },
            ],
        }
    },
    {
        name: 'VCard.Timezone',
        type: TimeZone,
    },
    {
        name: 'VCard.Notes',
        type: TextArea,
        autoComplete: '',
    },
];