/* Sidebar.js */
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faComment, faMapMarkerAlt, faCalendarAlt, faBuilding } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useState } from 'react';
import { forwardRef } from 'react';


const SideBarContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.small};

  /* position */
  display: flex;
  text-align: center;
  position: fixed;
  margin-top: ${({ mainTopMargin }) => 'calc(' + mainTopMargin + ' + 10px)'};
  margin-left: 10px;
  height: ${({ mainTopMargin }) => 'calc(100% - ' + mainTopMargin + ' - 20px)'};
  max-width: fit-content;
  box-sizing: border-box;
  z-index: 100;
  border-radius: 10px;

  /* Styles */
  background-color: ${({ theme }) => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-left: 0px;
    height: fit-content;
    position: fixed;
    bottom: 0;
    box-sizing: border-box; 
    width: 100%;
    max-width: 100%;
  }
`;

const SideBarNav = styled.nav`
  /* display */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* flex: 1; */
  /* flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: flex-start; */
  /* flex-wrap: nowrap;
  */

  /* position */
  /* position: fixed;
  top: 10vh;
  height: 100%;
  width: calc(100vw * 0.06);
  max-width: calc(100vw * 0.06);
  flex-basis: calc(100vw * 0.06); */

  /* Styles */
  /* padding-top: ${({ theme }) => theme.spacing.medium}; */
  gap: ${({ theme }) => theme.spacing.large};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    /* top: inherit;
    bottom: 0;
    position: fixed;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.small};
    height: 8vh;
    justify-content: space-evenly;
    min-width: fit-content; */
  }
`;

const IconLink = styled(Link)`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.sidebar.iconColor};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  border: none;
  position: relative;

  &.hide-outline {
    outline: none !important;
  }
  
  &::after {
    content: attr(aria-label) !important;
    position: absolute;
    bottom: -30px;
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%);
    padding: ${({ theme }) => theme.spacing.xxxxsmall};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    background-color: ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.white};
    font-size: ${({ theme }) => theme.fontSizes.xxxsmall};
    white-space: nowrap;
  }

  &:focus-visible::after {
    opacity: 1;
    visibility: visible;
    transition: none;
  }
  &:hover::after {
    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
      opacity: 1 !important;
      visibility: visible !important;
      transition: opacity 0s ease-in-out 1s;
    }
  }
  &::after, &.hide-outline::after {
      content: '';
      opacity: 0;
      visibility: hidden;
  }
  
  border: ${({ theme }) => theme.borderRadius.xsmall} solid transparent;
  &.active {
    border-bottom-color: ${({ theme }) => theme.colors.secondary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: 100%;
    justify-content: center;
    width: fit-content;
    padding: 0 ${({ theme }) => theme.spacing.small};
    outline-color: ${({ theme }) => theme.colors.secondary};
    &:hover::after,
    &:focus::after {
      bottom: 62px;
      top: auto;
    }
    &:hover {
      color: ${({ theme }) => theme.colors.defaultText};
    }
    &.active {
      color: ${({ theme }) => theme.colors.primary};
      border: none;
    }
  }
`;



const Icon = styled(FontAwesomeIcon)`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.large};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const Label = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xxsmall};
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing.small};
  // Prevent text overflow
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const Sidebar = forwardRef(({ sidebarRef, mainTopMargin }, ref) => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(null);
  const [isFirstFocus, setIsFirstFocus] = useState(true);

  const handleLinkClick = (event) => {
    setActiveLink(event.currentTarget);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Tab' &&
        isFirstFocus &&
        activeLink) {
        event.preventDefault();
        activeLink.focus();
        setIsFirstFocus(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [activeLink, isFirstFocus]);

  useEffect(() => {
    if (activeLink) {
      activeLink.focus();
    }
    if (location.pathname === '/') {
      setIsFirstFocus(false);
    }
  }, [activeLink, location]);

  return (
    <SideBarContainer ref={ref} mainTopMargin={mainTopMargin}>
      <SideBarNav>
        <IconLink
          to="/mytasks"
          className={`${location.pathname === '/mytasks' ? 'active' : ''} ${isFirstFocus ? 'hide-outline' : ''}`}
          onClick={handleLinkClick}
          ref={location.pathname === '/mytasks' ? setActiveLink : null}
          tabIndex="0"
          aria-label="My Tasks"
        >
          <Icon icon={faClipboard} />
          <Label>My Tasks</Label>
        </IconLink>
        <IconLink
          to="/chat"
          className={`${location.pathname === '/chat' ? 'active' : ''} ${isFirstFocus ? 'hide-outline' : ''}`}
          onClick={handleLinkClick}
          ref={location.pathname === '/chat' ? setActiveLink : null}
          tabIndex="0"
          aria-label="Chat"
        >
          <Icon icon={faComment} />
          <Label>Chat</Label>
        </IconLink>
        <IconLink
          to="/jobsites"
          className={`${/.*(jobsites|jobsite).*/.test(location.pathname) ? 'active' : ''} ${isFirstFocus ? 'hide-outline' : ''}`}
          onClick={handleLinkClick}
          ref={/.*(jobsites|jobsite).*/.test(location.pathname) ? setActiveLink : null}
          tabIndex="0"
          aria-label="Job Sites"
        >
          <Icon icon={faMapMarkerAlt} />
          <Label>Job Sites</Label>
        </IconLink>
        <IconLink
          to="/calendar"
          className={`${location.pathname === '/calendar' ? 'active' : ''} ${isFirstFocus ? 'hide-outline' : ''}`}
          onClick={handleLinkClick}
          ref={location.pathname === '/calendar' ? setActiveLink : null}
          tabIndex="0"
          aria-label="Calendar"
        >
          <Icon icon={faCalendarAlt} />
          <Label>Calendar</Label>
        </IconLink>
        <IconLink
          to="/admin"
          className={`${location.pathname === '/admin' ? 'active' : ''} ${isFirstFocus ? 'hide-outline' : ''}`}
          onClick={handleLinkClick}
          ref={location.pathname === '/admin' ? setActiveLink : null}
          tabIndex="0"
          aria-label="Admin"
        >
          <Icon icon={faBuilding} />
          <Label>Admin</Label>
        </IconLink>
      </SideBarNav>
    </SideBarContainer>
  );
});

export default Sidebar;
