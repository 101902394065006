import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledSelect = styled.select`
    border-radius: 10px;
    border: none;
    padding: 10px;
    font-size: 16px;
    outline: none;
    width: fit-content;
`;

const PillDropDown = ({ label, options, selected, onChange }) => {
    const [selectedOption, setSelectedOption] = useState(selected || '');

    useEffect(() => {
        setSelectedOption(selected);
    }, [selected]);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (<>
        <StyledSelect value={selectedOption} onChange={handleChange}>
            {options.map((option, i) => <option key={i} value={option.value}>{option.label}</option>)}
        </StyledSelect>
    </>
    );
}

export default PillDropDown;
