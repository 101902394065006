import { gql } from "@apollo/client";
import { JOBSITE_FRAGMENT_QUERY } from "../Fragments/JobSite";

export const JobSiteFindOne = gql`
    ${JOBSITE_FRAGMENT_QUERY}
    query Query($filter: FilterFindOneJobSiteInput, $skip: Int, $sort: SortFindOneJobSiteInput) {
        JobSiteFindOne(filter: $filter, skip: $skip, sort: $sort) {
            ...JobSiteFields
        }
    }
`;

export const JobSiteFindMany = gql`
    ${JOBSITE_FRAGMENT_QUERY}
    query JobSiteFindMany($filter: FilterFindManyJobSiteInput, $skip: Int, $limit: Int, $sort: SortFindManyJobSiteInput) {
        JobSiteFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            ...JobSiteFields
        }
    }
`;
