import styled from "styled-components";
import InvoicePreview from "./preview.js";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.small};
`;

const JobSiteInvoices = ({ Invoices, Job }) => {
  return <Wrapper>
    {
      Invoices?.map((Invoice, index) => {
        return <InvoicePreview
          key={`note-${index}`}
          Invoice={Invoice}
          Job={Job} />
      })
    }
  </Wrapper>;
};

export default JobSiteInvoices;