/* PrivateRoute */
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet } from "react-router-dom";
import Landing from "./Pages/Landing";
import AppLayout from "./Layout/AppLayout";
import CompanySelection from "./Pages/CompanySelection";
import { gql, useQuery } from "@apollo/client";
import { USER_ONE } from "./Querys/USER_ONE";

export const USER_BY_ID = gql`
    query UserFindById($id: MongoID!) {
        UserFindById(_id: $id) {
            Name
            SelectedTenant {
                Company {
                    _id
                    Company
                }
                _id
            }
        }
    }
`;

const PrivateRoute = props => {
    const { isAuthenticated, user } = useAuth0();
    const { data: { UserFindOne } = {} } = useQuery(USER_ONE, {
        skip: !user?.sub, // skip if AuthUser data is not available yet
        filter: { ExternalId: user?.sub },
    });

    if (!isAuthenticated) {
        return <Landing />;
    }
    if (!UserFindOne?.SelectedTenant?._id) {
        return <CompanySelection UserData={UserFindOne} />
    }
    return <AppLayout><Outlet /></AppLayout>;
};


export default PrivateRoute;