
import { useContext } from "react";
import styled from "styled-components";
import { CalendarContext } from "../CalendarContext";
import moment from "moment";

const Wrapper = styled.div`
    padding: ${({ theme }) => theme.spacing.small} 0;
`;
const HeaderText = styled.div`
    text-align: center;
    padding: 0 ${({ theme }) => theme.spacing.small};
    flex-grow: 1;
`;

const DayNumber = styled.div`
    text-align: right;
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const Event = styled.div`
    position: absolute;
    top: ${({ startTime }) => (100 / 1440) * startTime}%; // converting minutes to percentage
    left: 0;
    right: 0;
    height: ${({ duration }) => (100 / 1440) * duration}%; // converting minutes to percentage
    box-sizing: border-box;
    background-color: ${({ theme }) => `${theme.colors.primary}80`};
    color: ${({ theme }) => theme.colors.defaultText};
    border-left: 5px solid ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    padding: ${({ theme }) => theme.spacing.xxxxxsmall};
    overflow: hidden; // to prevent text overflowing the event box
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Day = styled.div`
    position: relative;
    border-right: ${({ theme, index }) => index % 7 !== 6 ? `1px solid ${theme.colors.disabledText}` : '0px'};
    border-bottom: ${({ theme, index }) => index < 42 ? `1px solid ${theme.colors.disabledText}` : '0px'};
    padding: ${({ theme }) => theme.spacing.small};
`;

const CalendarWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: minmax(60px, auto);
    padding: ${({ theme }) => theme.spacing.small} 0;
`;

function Month({ handleSelectEvent }) {
    const { calendarState, setCalendarState } = useContext(CalendarContext);

    const daysInMonth = calendarState?.today?.daysInMonth();
    const startDay = calendarState?.today?.clone().startOf('month').day();

    const previousMonth = calendarState?.today?.clone().subtract(1, 'month');
    const daysInPreviousMonth = previousMonth.daysInMonth();

    let monthArray = [];
    for (let i = 0; i < startDay; i++) {
        // add last month's days
        monthArray.push(daysInPreviousMonth - startDay + i + 1);
    }
    for (let i = 1; i <= daysInMonth; i++) {
        // add current month's days
        monthArray.push(i);
    }
    // calculate remaining days to fill the last week (total 42 days to fill 6 weeks)
    const remainingDays = 42 - monthArray.length;
    for (let i = 0; i < remainingDays; i++) {
        // add next month's days
        monthArray.push(i + 1);
    }

    return (
        <Wrapper>
            <CalendarWrapper>
                {Array.from({ length: 7 }).map((_, dayIndex) => {
                    const day = calendarState.today
                        .clone()
                        .startOf('week')
                        .add(dayIndex, 'day');

                    return <Day key={dayIndex} index={dayIndex}>
                        <HeaderText>{day.format('ddd')}</HeaderText>
                    </Day>;
                })}
                {monthArray.map((dayNumber, index) => (
                    <Day key={index + 7} index={index + 7}>
                        {dayNumber && <DayNumber>{dayNumber}</DayNumber>}
                        {
                            calendarState?.events?.map((event, index) => {
                                const eventDay = moment(event.startTime).date();
                                if (eventDay === dayNumber) {
                                    const duration = moment(event.endTime).diff(moment(event.startTime), 'minutes');
                                    return (
                                        <Event
                                            onClick={() => handleSelectEvent(event)}
                                            key={index}
                                            duration={duration}
                                        >
                                            {event.name}
                                        </Event>
                                    )
                                }
                            })
                        }
                    </Day>
                ))}
            </CalendarWrapper>
        </Wrapper>
    );
};

export default Month;
