import styled from "styled-components";
import PillTabMenu from "../../../Content/common/PillTabMenu";
import PillDropDown from "../../../Content/PillDropDown";
import { useState } from "react";
import PagedGrid from "../../../Content/common/PagedGrid";
import TaskGridItem from "../../MyTasks/TaskGridItem";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
`;


function AllTasks({ Job }) {
    const [selectedOption, setSelectedOption] = useState(-1);

    const options = Job?.Projects?.map((value, index) => { return { label: value?.Name, value: index } }) || [];
    return (
        <Wrapper>
            <PillDropDown
                label={"Project"}
                selected={selectedOption}
                options={[{ value: '-1', label: 'All Projects' }, ...options]}
                onChange={setSelectedOption}
            />
            <PagedGrid data={Job?.Projects?.[0].Tasks || []} GridItem={TaskGridItem} itemsPerPage={9} searchable={false} />
        </Wrapper>
    );
};

export default AllTasks;