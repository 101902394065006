/* content.js */
import styled from "styled-components";

const ContentContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: lightgreen;
  margin-top: ${({ showHeader, mainTopMargin }) => showHeader ? mainTopMargin : '0px'};
  margin-left: ${({ mainLeftMargin }) => mainLeftMargin};
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.contentArea};
  min-width: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: ${({ mainBottomMargin }) => mainBottomMargin};
    margin-left: 0;
  }
`;

const ContentWrapper = styled.div`
  flex: 1;
  width: 80%;
  max-width: 80%;
  min-height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 100%;
    max-width: 100%;
  }
`;

const Main = ({ mainTopMargin, mainBottomMargin, mainLeftMargin, showHeader, children }) => {
  return (
    <ContentContainer
      mainTopMargin={mainTopMargin}
      mainBottomMargin={mainBottomMargin}
      mainLeftMargin={mainLeftMargin}
      showHeader={showHeader}
      id='main-content'>
      <ContentWrapper>
        {children}
      </ContentWrapper>
    </ContentContainer>
  );
};

export default Main;
