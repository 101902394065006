import { gql } from "@apollo/client";
import { ALERT_FRAGMENT } from "../Fragments/Alert";

export const AlertCreateOne = gql`
    ${ALERT_FRAGMENT}
    mutation Mutation($record: AlertCreateOneWithRelationship!) {
        AlertCreateOne(record: $record) {
            record {
                ...AlertFields
            }
        }
    }
`;

export const AlertUpdateOne = gql`
    mutation AlertUpdateOne($record: AlertUpdateOneWithRelationship!, $filter: FilterUpdateOneAlertInput) {
        AlertUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const AlertRemoveFindById = gql`
    mutation AlertRemoveFindById($id: MongoID!) {
        AlertRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
