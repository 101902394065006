
import { useQuery } from "@apollo/client";
import BackButton from "../../../Content/BackButton";
import PlusButton from "../../../Content/PlusButton";
import PagedList from "../../../Content/common/PagedList";
import styled from "styled-components";
import UserProfilePreview from "./UserProfilePreview";
import * as querys from "../../../../graphql/querys";
import useModal from "../../../../hooks/useModal";
import Profile from "../../../Documents/Profile";


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function UserProfiles() {
    const { data: { ProfileFindMany } = {} } = useQuery(querys.ProfileFindMany);
    const { openModal } = useModal();

    const handleOpenModal = (profile) => {
        openModal(<Profile
            profile={profile}
        />)
    };

    return <Wrapper>
        <ButtonWrapper>
            <BackButton />
            <PlusButton onClick={() => handleOpenModal({})} />
        </ButtonWrapper>
        <PagedList data={ProfileFindMany} ListItem={UserProfilePreview} handleClick={handleOpenModal} itemsPerPage={10} searchable={true} />
    </Wrapper>;
};

export default UserProfiles;