import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const TabContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap;
`;

const Tab = styled.div`
    padding: 10px 10px;
    border-radius: 10px;
    background: ${({ active, theme }) => active ? theme.colors.secondary : theme.colors.white};
    color: ${({ active, theme }) => active ? theme.colors.white : theme.colors.black};
    display: ${({ active, selectedTab }) => active || selectedTab === '' ? 'block' : 'none'};
    cursor: pointer;
    transition: background 0.2s;
`;

const Content = styled.div`
    padding: 20px 0px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: ${({ theme }) => theme.fontSizes.large};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const Divider = styled.div`
    border-left: 1px solid ${({ theme }) => theme.colors.gray};
    height: 20px;
    margin: 0 10px;
`;

const PillTabMenu = ({ beforeTabs, defaultContent, tabs }) => {
    const [selectedTab, setSelectedTab] = useState({ value: '', showBefore: false });

    return (
        <TabContainer>
            <TabsContainer>
                {((selectedTab?.value !== '' && selectedTab?.showBefore) || (selectedTab?.value === '' && beforeTabs)) && <>{beforeTabs}<Divider /></>}
                {selectedTab?.value !== '' &&
                    <Icon
                        icon={faClose}
                        onClick={() => setSelectedTab({ value: '', showBefore: false })} />
                }
                {tabs?.map((tab, index) => (
                    <React.Fragment key={`${tab?.name}-${index}`}>
                        {(tab?.divider && selectedTab?.value === '') && <Divider />}
                        <Tab
                            key={tab?.name}
                            active={selectedTab?.value === tab?.name}
                            selectedTab={selectedTab?.value}
                            onClick={() => setSelectedTab({ value: tab?.name, showBefore: tab?.showBefore })}
                        >
                            {tab?.name}
                        </Tab>
                    </React.Fragment>
                ))}
            </TabsContainer>
            <Content>
                {selectedTab?.value === '' ? defaultContent : tabs?.find(tab => tab?.name === selectedTab?.value)?.content}
            </Content>
        </TabContainer>
    );
};

export default PillTabMenu;
