import { gql } from "@apollo/client";
import { CLIENT_FRAGMENT } from "../Fragments/Client";

export const ClientCreateOne = gql`
    ${CLIENT_FRAGMENT}
    mutation Mutation($record: ClientCreateOneWithRelationship!) {
        ClientCreateOne(record: $record) {
            recordId
            record {
                ...ClientFields
            }
            error {
                message
            }
        }
    }
`;

export const ClientUpdateOne = gql`
    ${CLIENT_FRAGMENT}
    mutation ClientUpdateOne($record: ClientUpdateOneWithRelationship!, $filter: FilterUpdateOneClientInput) {
        ClientUpdateOne(record: $record, filter: $filter) {
            recordId
            record {
                ...ClientFields
            }
            error {
                message
            }
        }
    }
`;

export const ClientRemoveFindById = gql`
    ${CLIENT_FRAGMENT}
    mutation ClientRemoveFindById($id: MongoID!) {
        ClientRemoveFindById(_id: $id) {
            recordId
            record {
                ...ClientFields
            }
            error {
                message
            }
        }
    }
`;
