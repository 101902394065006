import FieldMultipleListItem from './FieldMultipleListItem';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';
import { useFormContext } from '../../../providers/FormProvider/FormProvider';
import { useTranslation } from 'react-i18next';

const FieldMultipleListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.small};
`;

const FieldMultipleListItemSeparator = styled.hr`
  width: 100%;
  flex: 1;
  height: 1px;
  border: none;
  border-top: 1px solid ${({ theme }) => theme.borderColor.disabled};
`;

const FieldWrapper = styled.div`
`;

const FieldMultipleListAddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #f1f1f1;
  }
`;

const FieldMultipleListAddButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 20px;
  color: green;
`;

const FieldMultipleListAddButtonLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const Legend = styled.legend`
  font-size: ${({ theme }) => theme.fontSizes.small};
`;

const FieldMultipleList = (props) => {
  const { state, dispatch } = useFormContext();
  const { t } = useTranslation();
  const fieldName = props?.name;
  const fieldValue = getFormValue(state?.formInputs, fieldName) || [];
  const label = t(`${state?.documentName}.Field.${props?.name}.Label`);

  const handleAddField = (e) => {
    e.preventDefault();
    const _fieldValue = getFormValue(state?.formInputs, props?.name);
    if (!Array.isArray(_fieldValue) || _fieldValue?.length === 0) {
      dispatch({
        type: 'UPDATE_FORM_INPUT', payload: {
          key: fieldName,
          value: [{ [props?.typeOptions?.keyName]: props?.typeOptions?.keyOptions?.[0] }],
        },
      });

      return;
    }
    dispatch({
      type: 'UPDATE_FORM_INPUT', payload: {
        key: fieldName,
        value: [..._fieldValue, { [props?.typeOptions?.keyName]: props?.typeOptions?.keyOptions?.[0] }],
      },
    });
  };

  const handleRemoveField = (index) => {
    const _fieldValue = getFormValue(state?.formInputs, fieldName) || [];
    if (!_fieldValue) {
      return;
    }
    dispatch({
      type: 'UPDATE_FORM_INPUT', payload: {
        key: fieldName,
        value: _fieldValue.filter((_, i) => i !== index),
      },
    });
  };

  return (
    <FieldMultipleListWrapper>
      <Legend>{label}</Legend>
      {fieldValue && fieldValue?.map((field, index) => (
        <FieldWrapper key={`field--${index}`}>
          <FieldMultipleListItemSeparator />
          <FieldMultipleListItem state={state} dispatch={dispatch} {...props} name={`${fieldName}[${index}]`} index={index} handleRemoveField={handleRemoveField} />
          {(index === fieldValue.length - 1) && <FieldMultipleListItemSeparator />}
        </FieldWrapper>
      ))}
      {state?.isEditing &&
        <FieldMultipleListAddButton type="button" onClick={handleAddField}>
          <FieldMultipleListAddButtonIcon icon={faPlus} />
          <FieldMultipleListAddButtonLabel>Add {label}</FieldMultipleListAddButtonLabel>
        </FieldMultipleListAddButton>
      }
    </FieldMultipleListWrapper>
  );
};

export default FieldMultipleList;
