import { gql } from "@apollo/client";

export const TASK_FRAGMENT = gql`
    fragment TaskFields on Task {
        Project
        Assignees {
            _id
            VCard {
                ContactName
                _id
            }
        }
        Number
        Code
        Status
        Priority
        Name
        Description
        LabourTimeEstimate
        _id
        createdAt
        updatedAt
    }
`;

export const TASK_FORM_FRAGMENT = gql`
    fragment TaskFormFields on Task {
        Project
        Assignees {
            _id
            VCard {
                ContactName
                _id
            }
        }
        Number
        Code
        Status
        Priority
        Name
        Description
        LabourTimeEstimate
        _id
    }
`;
