// ColourPicker.js
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';

const GLOBAL_TEXT_BOX_LIMIT = 100;

const FieldColorInput = styled.input`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.borderColor.default};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  /* padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall}; */
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  height: ${({ theme }) => theme.spacing.xxlarge};
  max-height: ${({ theme }) => theme.spacing.xxlarge};

  &:focus {
    border-color: ${({ theme }) => theme.borderColor.focus};
    outline-color: ${({ theme }) => theme.borderColor.outline};
  }
`;

const FieldTextInput = styled.input`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.borderColor.default};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall};
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  height: ${({ theme }) => theme.spacing.xxlarge};
  max-height: ${({ theme }) => theme.spacing.xxlarge};

  &:focus {
    border-color: ${({ theme }) => theme.borderColor.focus};
    outline-color: ${({ theme }) => theme.borderColor.outline};
  }
  flex: 1;
`;


const FormField = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  flex: 1;
  height: fit-content;
`;

const ColourPicker = React.memo(({
  state,
  dispatch,
  name = '',
  index = undefined,
  type = 'color',
  formatInput = (value) => value,
  limit = GLOBAL_TEXT_BOX_LIMIT,
  onChange,
  ...props
}) => {
  const handleInputChange = useCallback((event) => {
    let newValue = event.target.value;
    if (limit) {
      newValue = newValue.slice(0, limit);
    }
    dispatch({ type: 'UPDATE_FORM_INPUT', payload: { key: name, value: newValue, index } });
  }, [dispatch, name, index, limit]);

  let value = getFormValue(state?.formInputs, name, index) || "";
  return <React.Fragment>
    <FormField>
      <FieldColorInput
        {...props}
        name={name}
        type={type}
        value={value}
        onChange={handleInputChange}
        maxLength={255}
      />
      <FieldTextInput
        {...props}
        name={name}
        type={'text'}
        value={value}
        onChange={handleInputChange}
        maxLength={255}
        readOnly={true}
      />
    </FormField>
  </React.Fragment>;
});

export default ColourPicker;
