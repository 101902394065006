import styled from "styled-components";
import FormBuilder from "../../FormBuilder";
import { clientAvailablityFormConfig } from "../../../documentConfigs/clientAvailablity/clientAvailablity.form";

const ClientAvailablityDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const ClientAvailablity = ({ clientAvailablity = {}, closeModal = () => { }, isNewDocument }) => {
    return <ClientAvailablityDetailsWrapper>
        <FormBuilder
            documentId={clientAvailablity?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            {...clientAvailablityFormConfig}
        />
    </ClientAvailablityDetailsWrapper>;
};

export default ClientAvailablity;