import React from 'react';
import styled from 'styled-components';
import TaskGridItem from '../Pages/MyTasks/TaskGridItem';
import { filter, find, map } from 'lodash';

const StageContainer = styled.div`
    margin-bottom: 20px;
`;

const StageName = styled.h2`
    text-align: center;
`;

const GridContainer = styled.div`
  min-height: 100%;
  aria-live: polite;
  display: grid;
  grid-template-columns: repeat(auto-fit, 301px);
  gap: ${({ theme }) => theme.spacing.xxsmall};
  justify-items: start;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(auto-fit, minmax(301px, 1fr));
  }
`;


const TaskList = ({ jobSite, tasks }) => {
    const stages = [...new Set(map(tasks, task => task.Stage.Stage))].sort();
    return (
        <div>
            {map(stages, stage => (
                <StageContainer key={stage}>
                    <StageName>{find(tasks, task => task.Stage.Stage === stage)?.Stage?.name}</StageName>
                    <GridContainer>
                        {filter(tasks, task => task.Stage.Stage === stage).map((data, index) => (
                            <TaskGridItem key={index} data={data} showStatus={false} showChat={false} showLocation={false} />
                        ))}
                    </GridContainer>
                </StageContainer>
            ))}
        </div>
    );
};

export default TaskList;
