/* AppLayout.js */
import styled from 'styled-components';
import { useContext } from 'react';
import { useLocation } from "react-router-dom";
import { AppConfigContext } from '../../config';
import { LayoutContext } from '../Utill/LayoutContext';
import Main from "./Main";
import Header from "./Header";
import SideBar from "./SideBar";
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { includes } from 'lodash';
import Modal from '../Content/common/Modal';
import useModal from '../../hooks/useModal';

const DISPLAY_ON_MOBILE_ROUTES = [
  '/',
  '/mytasks',
  '/chat',
  '/jobsites',
  '/calendar',
  '/admin',
];

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContainer = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  min-height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-end;
  }
`;

const AppLayout = (props) => {
  const { setLayoutValue } = useContext(LayoutContext);
  const { appConfig: { appName, logoBlackSrc } } = useContext(AppConfigContext);
  const { modals, closeModal, openModal } = useModal();
  const location = useLocation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const headerRef = useRef(null);
  const sidebarRef = useRef(null);
  const [mainTopMargin, setMainTopMargin] = useState('0px');
  const [mainBottomMargin, setMainBottomMargin] = useState('0px');
  const [mainLeftMargin, setMainLeftMargin] = useState('0px');

  const showHeader = windowSize.width > 768 ||
    (windowSize.width < 768 && includes(DISPLAY_ON_MOBILE_ROUTES, location.pathname));

  useEffect(() => {
    if (headerRef.current) {
      setMainTopMargin(`${headerRef.current.offsetHeight + 10}px`);
      setMainBottomMargin(`${sidebarRef.current.offsetHeight}px`);
      setMainLeftMargin(`${sidebarRef.current.offsetWidth}px`);
      setLayoutValue((prevValue) => {
        return {
          ...prevValue,
          ...{
            mainTopMargin: `${headerRef.current.offsetHeight + 10}px`,
            mainBottomMargin: `${sidebarRef.current.offsetHeight}px`,
            mainLeftMargin: `${sidebarRef.current.offsetWidth}px`,
            sidebarHeight: sidebarRef.current.offsetHeight,
          }
        };
      });
    }
    const handleResize = () => {
      if (headerRef.current) {
        setMainTopMargin(`${headerRef.current.offsetHeight + 10}px`);
        setMainBottomMargin(`${sidebarRef.current.offsetHeight}px`);
        setMainLeftMargin(`${sidebarRef.current.offsetWidth}px`);
        setLayoutValue((prevValue) => {
          return {
            ...prevValue,
            ...{
              mainTopMargin: `${headerRef.current.offsetHeight + 10}px`,
              mainBottomMargin: `${sidebarRef.current.offsetHeight}px`,
              mainLeftMargin: `${sidebarRef.current.offsetWidth}px`,
              sidebarHeight: sidebarRef.current.offsetHeight,
              headerHeight: headerRef.current.offsetHeight,
            }
          };
        });
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [headerRef, setLayoutValue]);

  useEffect(() => {
    // Function to update window size
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AppContainer>
      {showHeader &&
        <Header appName={appName} logoBlackSrc={logoBlackSrc} ref={headerRef} />
      }
      <MainContainer>
        <Main
          mainTopMargin={mainTopMargin}
          mainBottomMargin={mainBottomMargin}
          mainLeftMargin={mainLeftMargin}
          showHeader={showHeader}
          {...props} />
      </MainContainer>
      {showHeader &&
        <SideBar mainTopMargin={mainTopMargin} ref={sidebarRef} />
      }
      <Modal modals={modals} closeModal={closeModal} />
    </AppContainer>
  );
};

export default AppLayout;
