import { gql } from "@apollo/client";

export const NOTE_FRAGMENT = gql`
    fragment NoteFields on Note {
        Name
        Type
        Description
        _id
        createdAt
        updatedAt
    }
`;
