import { useContext } from "react";
import styled from "styled-components";
import { CalendarContext } from "../CalendarContext";

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: ${({ theme }) => theme.spacing.small};
    padding: ${({ theme }) => theme.spacing.small} 0;
    box-sizing: border-box;
`;

const Day = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    border-right: ${({ theme, index }) => index % 7 !== 6 ? `1px solid ${theme.colors.disabledText}` : '0px'};
    border-bottom: ${({ theme, index }) => index < 42 ? `1px solid ${theme.colors.disabledText}` : '0px'};
    /* padding: ${({ theme }) => theme.spacing.small}; */

    
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        font-size: 0.8rem;
        width: minmax(calc(0.8rem * 1.5), auto);
        height: minmax(calc(0.8rem * 1.5), auto);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        font-size: 1rem;
        width: minmax(calc(1rem * 1.5), auto);
        height: minmax(calc(1rem * 1.5), auto);
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        font-size: 1.2rem;
        width: minmax(calc(1.2rem * 1.5), auto);
        height: minmax(calc(1.2rem * 1.5), auto);
    }
`;

const CalendarWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 20px);
    grid-auto-rows: minmax(60px, auto);
    padding: ${({ theme }) => theme.spacing.small} 0;
    box-sizing: border-box;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
        grid-auto-rows: minmax(calc(0.8rem * 1.5), auto);
        grid-template-columns: repeat(7, minmax(calc(0.8rem * 1.5), auto));
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        grid-auto-rows: minmax(calc(1rem * 1.5), auto);
        grid-template-columns: repeat(7, minmax(calc(1rem * 1.5), auto));
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
        grid-auto-rows: minmax(calc(1.2rem * 1.5), auto);
        grid-template-columns: repeat(7, minmax(calc(1.2rem * 1.5), auto));
    }
`;

const MonthTitle = styled.div`
    text-align: center;
    font-weight: bold;
    margin: ${({ theme }) => theme.spacing.small} 0;
`;

const DayNumber = styled.div`
    text-align: right;
    font-size: inherit;
`;

function Year() {
    const { calendarState } = useContext(CalendarContext);

    const yearArray = Array.from({ length: 12 }, (_, i) => i);

    return (
        <Wrapper>
            {yearArray.map(month => {
                const monthStart = calendarState.today.clone().month(month).startOf('month');
                const daysInMonth = monthStart.daysInMonth();
                const startDay = monthStart.day();

                let monthArray = [];
                for (let i = 0; i < startDay; i++) {
                    monthArray.push(null);
                }
                for (let i = 1; i <= daysInMonth; i++) {
                    monthArray.push(i);
                }

                return (
                    <div key={month}>
                        <MonthTitle>{monthStart.format('MMMM')}</MonthTitle>
                        <CalendarWrapper>
                            {monthArray.map((dayNumber, index) => (
                                <Day key={index + 7} index={index + 7}>
                                    {dayNumber && <DayNumber>{dayNumber}</DayNumber>}
                                </Day>
                            ))}
                        </CalendarWrapper>
                    </div>
                );
            })}
        </Wrapper>
    );
};

export default Year;
