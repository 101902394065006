import { gql } from "@apollo/client";
import { VCARD_FRAGMENT } from "./VCard";

export const TENANTDETAILS_FRAGMENT = gql`
    ${VCARD_FRAGMENT}
    fragment TenantDetailsFields on TenantDetails {
      VCard {
        ...VCardFields
      }
      _id
      createdAt
      updatedAt
    }
`;
