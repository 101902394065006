import { gql } from "@apollo/client";
import { PROFILE_FRAGMENT } from "../Fragments/Profile";

export const ProfileCreateOne = gql`
    ${PROFILE_FRAGMENT}
    mutation Mutation($record: ProfileCreateOneWithRelationship!) {
        ProfileCreateOne(record: $record) {
            record {
                ...ProfileFields
            }
        }
    }
`;

export const ProfileUpdateOne = gql`
    mutation ProfileUpdateOne($record: ProfileUpdateOneWithRelationship!, $filter: FilterUpdateOneProfileInput) {
        ProfileUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const ProfileRemoveFindById = gql`
    mutation ProfileRemoveFindById($id: MongoID!) {
        ProfileRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
