import styled from "styled-components";
import { useQuery } from "@apollo/client";
import JobSiteDetails from "../Jobsite/JobSiteOverview/JobSiteDetails";
import * as querys from "../../../graphql/querys/querys.jobsite";
import useModal from "../../../hooks/useModal";
import PlusButton from "../../Content/PlusButton";
import PagedGrid from "../../Content/common/PagedGrid";
import JobSiteCardLink from "../../Content/JobSite/JobSiteCardLink";


const JobSiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    /* padding: 0; */
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

const JobSites = props => {
  const { data: { JobSiteFindMany } = {} } = useQuery(querys.JobSiteFindMany);
  const { openModal } = useModal();


  const handleOpenModal = (props) => {
    openModal(<JobSiteDetails {...props} />);
  };

  return <JobSiteWrapper>
    <ButtonWrapper>
      <PlusButton onClick={() => handleOpenModal({ isNewDocument: true })} />
    </ButtonWrapper>
    <PagedGrid
      data={JobSiteFindMany}
      GridItem={JobSiteCardLink}
      itemsPerPage={9} />
  </JobSiteWrapper>
};

export default JobSites;
