import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

const ListPagination = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    box-sizing: border-box;
    width: 100%;
    margin: 0;
    padding: ${({ theme }) => theme.spacing.xxsmall} 0;
`;

const ListPageItem = styled.li`
    margin: 0 0.08rem;
    font-weight: ${({ theme, currentPage, page }) => currentPage === page ? theme.fontStyles.bold : 'normal'};
`;

const PageLink = styled.button`
    background: none;
    border: none;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    font-size: ${({ theme }) => theme.fontSizes.small};
    color: ${({ theme, active, disabled }) => (disabled ? theme.colors.disabledText : active ? theme.colors.white : theme.colors.secondary)};
    background-color: ${({ theme, active, disabled }) => (disabled ? 'transparent' : active ? theme.colors.secondary : 'transparent')};
    padding: ${({ theme }) => theme.spacing.xxxxxsmall} ${({ theme }) => theme.spacing.xxxxxsmall};
    border-radius: ${({ theme }) => theme.borderRadius.small};
    width: ${({ theme }) => theme.spacing.xxlarge};
    text-align: center;
    &:hover {
        text-decoration: ${({ active, disabled }) => (disabled ? 'none' : active ? 'none' : 'underline')};
    }
    &:active {
        background-color: ${({ theme, active, disabled }) => (disabled ? 'transparent' : active ? 'transparent' : theme.colors.secondary)};
        color: ${({ theme, active, disabled }) => (disabled ? theme.colors.disabledText : active ? theme.colors.secondary : theme.colors.white)};
    }
`;

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    const MAX_VISIBLE_PAGES = 5;

    const placeholders = Array.from({ length: MAX_VISIBLE_PAGES }, (_, i) => {
        const offset = Math.floor(MAX_VISIBLE_PAGES / 2);
        const page = currentPage - offset + i;
        return page >= 1 && page <= totalPages ? page : null;
    });

    return (
        <nav aria-label="Page navigation">
            <ListPagination>
                <ListPageItem>
                    <PageLink
                        aria-label="Go to first page"
                        onClick={() => onPageChange(1)}
                        disabled={currentPage === 1}
                    >
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleDoubleLeft} />
                        </span>
                    </PageLink>
                </ListPageItem>
                <ListPageItem>
                    <PageLink
                        aria-label="Go to previous page"
                        onClick={() => onPageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                    >
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleLeft} />
                        </span>
                    </PageLink>
                </ListPageItem>
                {placeholders.map((page, index) => (
                    <ListPageItem key={index} currentPage={currentPage} page={page}>
                        {page ? (
                            <PageLink
                                aria-label={`Go to page ${page}`}
                                onClick={() => onPageChange(page)}
                                active={currentPage === page}
                            >
                                {page}
                            </PageLink>
                        ) : (
                            <PageLink style={{ visibility: 'hidden' }}>
                                &nbsp;
                            </PageLink>
                        )}
                    </ListPageItem>
                ))}
                <ListPageItem>
                    <PageLink
                        aria-label="Go to next page"
                        onClick={() => onPageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                    >
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                    </PageLink>
                </ListPageItem>
                <ListPageItem>
                    <PageLink
                        aria-label="Go to last page"
                        onClick={() => onPageChange(totalPages)}
                        disabled={currentPage === totalPages}
                    >
                        <span aria-hidden="true">
                            <FontAwesomeIcon icon={faAngleDoubleRight} />
                        </span>
                    </PageLink>
                </ListPageItem>
            </ListPagination>
        </nav>
    );
};

export default Pagination;
