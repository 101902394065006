import styled from "styled-components";
import PagedGrid from "../../../Content/common/PagedGrid";
import ProjectGridItem from "../Projects/ProjectGridItem";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
`;


function AllProjects({ jobSite }) {
    const Projects = jobSite?.Projects || [];
    return (
        <Wrapper>
            <PagedGrid data={Projects} GridItem={ProjectGridItem} itemsPerPage={9} searchable={false} />
        </Wrapper>
    );
};

export default AllProjects;