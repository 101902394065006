// FormProvider.js
import React, { createContext, useReducer, useContext } from 'react';
import formReducer from '../../reducer/formReducer/formReducer';

const FormContext = createContext();

export const useFormContext = () => {
    const context = useContext(FormContext);
    if (!context) {
        throw new Error('useFormContext must be used within a FormProvider');
    }
    return context;
};

const initialState = {
    formInputs: {},
    initialFormInputs: {},
    dirtyValues: {},
    errors: {},
    activeFields: {},
    initActiveFields: {},
    localFormState: {},
    isEditing: false,
    submitSuccess: false,
};

export const FormProvider = ({ children, initialValues = {} }) => {
    const [state, dispatch] = useReducer(formReducer, {
        ...initialState,
        ...initialValues,
    });

    return (
        <FormContext.Provider value={{ state, dispatch }}>
            {children}
        </FormContext.Provider>
    );
};