import styled from "styled-components";
import CompanyDetailsPreview from "./CompanyDetails/CompanyDetailsPreview";
import * as querys from "../../../graphql/querys";
import { useQuery } from "@apollo/client";
import VerticalMenuLink from "../../Content/common/VerticalMenuLink";

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-left: ${({ theme }) => theme.spacing.xxlarge};
  margin-right: ${({ theme }) => theme.spacing.xxlarge};
  padding: ${({ theme }) => theme.spacing.small};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0px;
  }
`;

const TENANT_DETAILS_ID = '111111111111111111111111';

const AdminNav = props => {
  const { data: { TenantDetailsFindOne } = {} } = useQuery(querys.TenantDetailsFindById, {
    variables: {
      id: TENANT_DETAILS_ID,
    },
  });
  const Company = TenantDetailsFindOne?.VCard;

  var Sections = [
    {
      title: "Company",
      options: [
        {
          title: "Company",
          linkTo: "companyDetails",
          // content: <CompanyDetails Company={Company} cancel={() => setSelectedOption('')} isEditing={true}/>,
          component: <CompanyDetailsPreview Company={Company} />
        }
      ]
    },
    {
      title: "User Profiles & Roles",
      options: [
        {
          title: "User Profiles",
          linkTo: "user-profiles",
          // content: <Clients cancel={() => setSelectedOption('')}/>,
        },
        {
          title: "User Roles",
          linkTo: "roles",
          // content: <Employees cancel={() => setSelectedOption('')}/>,
        }
      ]
    },
    {
      title: "Teams",
      options: [
        {
          title: "Teams",
          linkTo: "teams",
          // content: <Clients cancel={() => setSelectedOption('')}/>,
        },
      ]
    },
    {
      title: "Clients",
      options: [
        {
          title: "Clients",
          linkTo: "clients",
          // content: <Clients cancel={() => setSelectedOption('')}/>,
        },
      ]
    },
    {
      title: "Materials & Equipment",
      options: [
        {
          title: "Materials",
          linkTo: "materials",
          // content: <Materials cancel={() => setSelectedOption('')}/>,
        },
        {
          title: "Equipment",
          linkTo: "equipment",
          // content: <Equipment cancel={() => setSelectedOption('')}/>,
        }
      ]
    },
    {
      title: "Job Site Settings",
      options: [
        {
          title: "JobSite",
          linkTo: "jobsite-settings",
          // content: <JobSiteSettings cancel={() => setSelectedOption('')} />,
        },
        {
          title: "Project",
          linkTo: "project-settings",
          // content: <JobSiteSettings cancel={() => setSelectedOption('')} />,
        },
        {
          title: "Task",
          linkTo: "task-settings",
          // content: <JobSiteSettings cancel={() => setSelectedOption('')} />,
        },
      ]
    },
    {
      title: "App Setting",
      options: [
        {
          title: "About",
          linkTo: "about",
          // content: <SystemSettings cancel={() => setSelectedOption('')} />,
        },
        {
          title: "Export Data",
          linkTo: "exportdata",
          // content: <div><BackButton override={() => setSelectedOption('')}/></div>,
        },
        {
          title: "Support",
          linkTo: "support",
          // content: <SystemSettings cancel={() => setSelectedOption('')} />,
        },
      ]
    },
    // {
    //   title: "Reports & Export",
    //   options: [
    //     {
    //       title: "Charts & Reports",
    //       linkTo: "chartsandreports",
    //       // content: <div><BackButton override={() => setSelectedOption('')}/></div>,
    //     }
    //   ]
    // },
  ];

  return <AdminContainer>
    <VerticalMenuLink
      MenuSections={Sections}
    />
  </AdminContainer>;
};

export default AdminNav;