import styled from "styled-components";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import JobSitePreview from "./JobSitePreview";
import Projects from "../Projects";
import JobSiteDetails from "./JobSiteDetails";
import BackButton from "../../../Content/BackButton";
import PlusButton from "../../../Content/PlusButton";
import CommentButton from "../../../Content/CommentButton";
import useModal from "../../../../hooks/useModal";
import * as querys from "../../../../graphql/querys";
import { useQuery } from "@apollo/client";
import JobSiteAddSubDoc from "../JobSiteAddSubDoc";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

const Content = styled.div`
  flex: 1;
  /* max-width: 2250px; */
`;


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DetailsWrapper = styled.div`
    display: flex;
    height: fit-content;
    padding: ${({ theme }) => theme.spacing.small};
    gap: ${({ theme }) => theme.spacing.small};
    margin: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.defaultText};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    justify-content: space-between;
    align-items: center;
`;

const IconWrapper = styled.span`
  margin-left: ${({ theme }) => theme.spacing.xsmall};
`;

const JobSiteOverview = (props) => {
  const { openModal } = useModal();
  const { id } = useParams();

  const {
    data: { JobSiteFindOne = {} } = {},
  } = useQuery(querys?.JobSiteFindOne, {
    variables: { filter: { _id: id } },
  });

  const handleOpenModal = () => {
    openModal(<JobSiteDetails jobsite={JobSiteFindOne || {}} />)
  };

  const handleAddNew = () => {
    openModal(<JobSiteAddSubDoc jobsite={JobSiteFindOne || {}} />)
  };

  return <Wrapper>
    <Content>
      <ButtonWrapper>
        <BackButton />
        <ButtonWrapper>
          <Link to="/chat"><CommentButton /></Link>
          <PlusButton onClick={handleAddNew} />
        </ButtonWrapper>
      </ButtonWrapper>
      <DetailsWrapper onClick={handleOpenModal}>
        <JobSitePreview jobSite={JobSiteFindOne || {}} />
        <IconWrapper>
          <FontAwesomeIcon icon={faAngleRight} />
        </IconWrapper>
      </DetailsWrapper>
      <br />
      <Projects jobSite={JobSiteFindOne || {}} />
    </Content>
  </Wrapper>;
};

export default JobSiteOverview;
