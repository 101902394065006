import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  width: 25%;
  max-height: 40%;
  overflow: auto;
  
  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0px;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid gray;
  padding: 8px;
  margin-bottom: 16px;
  font-size: 16px;
  box-sizing: border-box;
`;

const ItemLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
  user-select: none;

  & > input[type="checkbox"] {
    margin-right: 8px;
  }
`;

const PickListModal = ({ items, activeFields, setActiveFields }) => {
  const [value, setValue] = useState({});
  const [searchValue, onSearchChange] = useState('');

  const filteredItems = items.filter(item => item?.label?.toLowerCase().includes(searchValue?.toLowerCase() || ''));

  const handleSetActiveFields = useCallback(
    (name) => {
      setActiveFields(name);
      setValue((prevFields) => ({
        ...prevFields,
        [name]: !prevFields[name],
      }));
    },
    [setActiveFields]
  );

  useEffect(() => {
    setValue(activeFields);
  }, [activeFields]);

  return (
    <div>
      <SearchInput
        type="text"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => {
          e.preventDefault();
          onSearchChange(e.target.value);
        }}
      />
      {filteredItems.map((item, index) => (
        <ItemLabel key={`searchlist-${index}`}>
          <input
            type="checkbox"
            id={`checkbox-${index}`} // Add an id for the input
            checked={value[item?.name]}
            onChange={() => handleSetActiveFields(item?.name)}
          />
          <label htmlFor={`checkbox-${index}`}>{item.label}</label> {/* Use htmlFor to associate the label with the input */}
        </ItemLabel>
      ))}
    </div>
  );
};

export default PickListModal;
