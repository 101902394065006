import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';

const FieldInput = styled.input`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.borderColor.default};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall};
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  height: ${({ theme }) => theme.spacing.medium};
  max-height: ${({ theme }) => theme.spacing.medium};
  width: ${({ theme }) => theme.spacing.medium};
  max-width: ${({ theme }) => theme.spacing.medium};

  &:focus {
    border-color: ${({ theme }) => theme.borderColor.focus};
    outline-color: ${({ theme }) => theme.borderColor.outline};
  }
`;

const Checkbox = React.memo(({
  state,
  dispatch,
  index = undefined,
  name,
  autoComplete = '',
  required = false,
  formatInput = (value) => value,
  ...props
}) => {
  const [value, setValue] = useState(false);
  const handleInputChange = useCallback((event) => {
    const newValue = event?.target?.checked ? true : false;
    setValue(newValue);
    dispatch({ type: 'UPDATE_FORM_INPUT', payload: { key: name, value: newValue, index } });
  }, [dispatch, name, index]);

  useEffect(() => {
    const inputValue = formatInput(getFormValue(state?.formInputs, name, index));
    setValue(inputValue);
  }, [state?.formInputs, name, index, formatInput]);

  return <React.Fragment>
    <FieldInput
      {...props}
      type="checkbox"
      isEditing={state?.isEditing}
      checked={value || false}
      onChange={handleInputChange}
      disabled={!state?.isEditing}
    />
  </React.Fragment>
});



export default Checkbox;
