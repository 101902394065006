import { useContext } from "react";
import styled from "styled-components";
import { CalendarContext } from "../CalendarContext";
import moment from "moment";

const HOURS_IN_DAY = 24;
const DAYS_IN_WEEK = 7;

const Wrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.disabledText};
    padding-top: ${({ theme }) => theme.spacing.small};
`;


const ColumnWrapper = styled.div`
    position: relative;
`;

const HeaderText = styled.div`
    background-color: white;
    text-align: center;
    height: 2em;
    line-height: 2em;   
    border-right: ${({ noBorder, theme }) => noBorder ? 'none' : `1px solid ${theme.colors.disabledText}`};
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.disabledText}`};
    box-sizing: border-box;
`;

const TimeValue = styled.div`
    height: 2em;
    line-height: 2em;
    text-align: center;
    border-right: ${({ noBorder, theme }) => noBorder ? 'none' : `1px solid ${theme.colors.disabledText}`};
`;

const EventWrapper = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
`;

const Event = styled.div`
  position: absolute;
  top: ${({ startTime }) => ((32 / 30) * startTime) - 32}px;
  height: ${({ duration }) => ((32 / 30) * duration)}px;
  width: 95%;
  background-color: ${({ theme }) => `${theme.colors.primary}80`};
  color: ${({ theme }) => theme.colors.defaultText};
  border-left: 5px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
  box-sizing: border-box;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
`;

const Row = styled.div`
    border-bottom: ${({ noBorder, theme }) => noBorder ? 'none' : `1px solid ${theme.colors.disabledText}`};
    height: 2em;
    box-sizing: border-box;
`;



function Week({ handleSelectEvent }) {
    const { calendarState, setCalendarState } = useContext(CalendarContext);

    return <Wrapper>
        <Column>
            <HeaderText>Time</HeaderText>
            {Array.from({ length: HOURS_IN_DAY }).map((_, index) => {
                let time = index + 1;
                if (!calendarState?.use24HourClock) {
                    time = (time % 12) || 12;
                }
                return (
                    <>
                        <Row key={index}>
                            <TimeValue>{time} {index > 11 ? 'pm' : 'am'}</TimeValue>
                        </Row>
                        <Row key={`${index}-placeholder`} noBorder={index === HOURS_IN_DAY - 1}>
                            <TimeValue></TimeValue>
                        </Row>
                    </>
                );
            })}
        </Column>
        {Array.from({ length: DAYS_IN_WEEK }).map((_, dayIndex) => {
            const day = calendarState.today
                .clone()
                .startOf('week')
                .add(dayIndex, 'day');
            return <ColumnWrapper>
                <Column>
                    <HeaderText noBorder={dayIndex + 1 === DAYS_IN_WEEK}>{day.format('ddd')}</HeaderText>
                    {Array.from({ length: HOURS_IN_DAY * 2 }).map((_, index) => (
                        <Row key={index} noBorder={index === HOURS_IN_DAY * 2 - 1} >
                            <TimeValue noBorder={dayIndex + 1 === DAYS_IN_WEEK}></TimeValue>
                        </Row>
                    ))}
                </Column>
                {
                    calendarState?.events?.map((event, index) => {
                        let startTime = moment(event.startTime);
                        let endTime = moment(event.endTime);
                        let startTimeInMinutes;
                        let duration;

                        const startOfDay = moment(day).startOf('day');
                        const endOfDay = moment(day).endOf('day');

                        // Skip events that do not intersect the current day
                        if (startTime.isAfter(endOfDay) || endTime.isBefore(startOfDay)) {
                            return null;
                        }
                        // console.log(`Event Start at ${startTime.format('MMM DD, YYYY, hh:mm')}`)

                        if (startTime.isSameOrBefore(startOfDay)) {
                            // If event starts before or at the start of the current day, set start time at the beginning of the day
                            startTimeInMinutes = 0;
                        } else {
                            // Else, calculate how far into the day the event starts
                            startTimeInMinutes = startTime.diff(startOfDay, 'minutes');
                        }

                        if (endTime.isSameOrAfter(endOfDay)) {
                            // If event ends after or at the end of the current day, set duration till the end of the day
                            duration = endOfDay.diff(startTime, 'minutes');
                        } else {
                            // Else, calculate event duration normally
                            duration = endTime.diff(startTime, 'minutes');
                        }
                        return <Event
                            onClick={() => handleSelectEvent(event)}
                            key={index}
                            startTime={startTimeInMinutes}
                            duration={duration}
                        >{event.name}</Event>
                    })
                }
            </ColumnWrapper>;
        })}
    </Wrapper>;
};

export default Week;