import { fields } from './clientAvailablity.form.fields';
import { validation } from './clientAvailablity.form.validation';
import { displayRules } from './clientAvailablity.form.displayRules';
import { initGraphQL } from './clientAvailablity.form.init.graphql';

const initActiveFields = {
    'CalendarEvent.IsAllDay': true,
    'CalendarEvent.StartTime': true,
    'CalendarEvent.EndTime': true,
    'CalendarEvent.DaysOfWeek': true,
    'CalendarEvent.Frequency': true,
    'CalendarEvent.Interval': true,
    'CalendarEvent.Description': true,
};

const blackListFields = [
];

export const clientAvailablityFormConfig = {
    label: 'Client Availablity',
    documentName: 'ClientAvailablity',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};