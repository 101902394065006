// FormBuilder.Builder.js
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import useForm from '../../hooks/useForm';
import useModal from '../../hooks/useModal';
import { ErrorContext } from '../../providers/ErrorProvider/ErrorProvider';
import { useFormContext } from '../../providers/FormProvider/FormProvider';
import FieldMaker from './FormBuilder.FieldMaker';
import { FieldAddButton, FieldAddButtonIcon, FieldAddButtonLabel, FormButton, FormButtonsEditing, FormButtonsReadOnly, FormHeader, FormWrapper } from "./StyledComponents";
import PickListModal from './fields/PickListModal';
import { useEffect } from 'react';

function getAllFieldNames(formFields, blackListFields = []) {
  const fieldNames = [];

  formFields.forEach((field) => {
    const fieldName = field?.name;
    if (fieldName) {
      fieldNames.push({ label: field.label, name: fieldName });
    } else if (field.fieldSet) {
      field.fieldSet.forEach((subField) => {
        const subFieldName = subField?.name;
        fieldNames.push({ label: subField.label, name: subFieldName });
      });
    }
  });
  return fieldNames.filter(fieldName => !blackListFields.includes(fieldName.name));
}

const Builder = ({
  documentName,
  documentId,
  fields,
  validation,
  isNewDocument,
  allowFieldSelector,
  blackListFields,
  initGraphQL,
  onFormClose
}) => {
  /* Use Form Hook */
  const { state, dispatch, loading, submitForm } = useForm({
    documentName,
    documentId,
    validation,
    initGraphQL
  });
  const { t } = useTranslation();
  const { clearError } = useContext(ErrorContext)

  /* Other Hooks */
  const { openModal } = useModal();

  /* Handle Functions */
  const handleOpenModal = () => {
    openModal(<PickListModal
      items={getAllFieldNames(fields, blackListFields)}
      activeFields={state?.activeFields}
      setActiveFields={(fieldName) => {
        dispatch({ type: 'TOGGLE_ACTIVE_FIELD', payload: fieldName });
      }}
    />,
      {
        miniModal: true,
        clickOut: true,
      }
    )
  };

  const handleEdit = (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_EDITING', payload: true });
    clearError();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch({ type: 'CANCEL_EDITING' });
    if (isNewDocument) {
      onFormClose();
    }
    clearError();
  };

  const handleClose = (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_EDITING', payload: false });
    onFormClose();
    clearError();
  };

  const handleDone = async (e) => {
    e.preventDefault();
    dispatch({ type: 'SET_EDITING', payload: false });
    await submitForm();
  };

  useEffect(() => {
    if (isNewDocument && state.submitSuccess) {
      onFormClose();
    }
  }, [isNewDocument, onFormClose, state.submitSuccess]);

  const label = t(`${state?.documentName}.Caption`);

  return (
    <FormWrapper onSubmit={handleDone}>
      <FormHeader>
        {(isNewDocument || state.isEditing) ? (
          <FormButtonsEditing>
            <FormButton action="cancel" type="button" onClick={handleCancel}>
              Cancel
            </FormButton>
            <h1>{isNewDocument ? 'Create New' : 'Edit'} {label}</h1>
            <FormButton action="submit" type="submit">
              Done
            </FormButton>
          </FormButtonsEditing>
        ) : (
          <FormButtonsReadOnly>
            <FormButton action="cancel" type="button" onClick={handleClose}>
              Close
            </FormButton>
            <h1>{label}</h1>
            <FormButton type="button" onClick={handleEdit}>
              Edit
            </FormButton>
          </FormButtonsReadOnly>
        )}
      </FormHeader>
      {!loading ?
        <FieldMaker
          state={state}
          dispatch={dispatch}
          fields={fields}
        /> : <p>Loading...</p>}
      {allowFieldSelector && state.isEditing && (
        <FieldAddButton type="button" onClick={() => handleOpenModal()}>
          <FieldAddButtonIcon icon={faPlus} />
          <FieldAddButtonLabel>Add Fields</FieldAddButtonLabel>
        </FieldAddButton>
      )}
    </FormWrapper>
  );
};

export default Builder;
