import React from 'react';

const ClientAvailabilityPreview = ({ Availability, Job }) => {

    // const ProjectName = Availability?.Project?.Name;
    // const AllDay = Availability?.AllDay;
    // const _StartDate = moment(Availability?.StartTime).toDate();
    // const StartDate = moment(_StartDate).format("ddd, MMM DD YYYY, hh:mmA");
    // const _EndDate = moment(Availability?.EndTime).toDate();
    // const EndDate = moment(_EndDate).format("ddd, MMM DD YYYY, hh:mmA");
    // const RepeatFrequency = Availability?.Frequency;
    // const Interval = Availability?.Interval;
    // const DayOfTheWeek = Availability?.DaysOfWeek.join(', ');

    return (
        <>
        </>
    );
};

export default ClientAvailabilityPreview;
