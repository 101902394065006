// SearchBar.js
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';


const SearchBarWrapper = styled.div`
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
`;

const SearchButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid #ccc;
    border-right: none;
    border-radius: 4px 0px 0px 4px;
`;

const SearchLabel = styled.label`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
`;

const SearchInput = styled.input`
    flex: 1;
    padding: 6px;
    border: 1px solid #ccc;
    border-left: none;
    border-radius: 0px 4px 4px 0px;
    background-color: ${({ theme }) => theme.colors.white};
`;

const Icon = styled(FontAwesomeIcon)`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSizes.large};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const SearchBar = ({ searchValue, onSearchChange }) => (
  <SearchBarWrapper>
    <SearchButton aria-label="Filter"><Icon icon={faFilter}/></SearchButton>
    <SearchLabel htmlFor="search-input">Search</SearchLabel>
    <SearchInput
      type="text"
      id="search-input"
      placeholder="Search"
      value={searchValue}
      onChange={onSearchChange}
    />
  </SearchBarWrapper>
);

export default SearchBar;
