import styled from 'styled-components';
import Text from './Text';
import Select from './Select';

const EmailRow = styled.fieldset`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  align-items: stretch;
  margin: 0;
  padding: 0;
  border: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

const Email = (props) => {
  return (<EmailRow>
    <Select
      {...props}
      name={`${props?.name}-EmailType`}
      example="Example: work, home"
      placeholder="Select Email type"
      label={`Email Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`Email Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaDescribedby="Select Email type"
      autoComplete="email-type"
      options={props?.typeOptions?.keyOptions}
    />
    <Text
      {...props}
      type="email"
      label={`${props?.label} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`${props?.ariaLabel} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      name={`${props?.name}-EmailAddress`}
      maxLength="255"
      title="Please enter a valid email address"
    />
  </EmailRow>);
};

export default Email;
