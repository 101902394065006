import styled from "styled-components";
import Note from "./Note.js";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.small};
`;

const JobSiteNotes = ({ Notes, Job }) => {
  return <Wrapper>
    {
      Notes?.map((note, index) => {
        return <Note
          key={`note-${index}`}
          Note={note}
          Job={Job} />
      })
    }
  </Wrapper>;
};

export default JobSiteNotes;