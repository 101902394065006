import React from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import useModal from "../../../../hooks/useModal/useModal";

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const OptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
    cursor: pointer;
`;

const Title = styled.h3`
    padding: 0;
    margin: 0;
    text-align: left;
    font-weight: bold;
    font-size: ${({ theme }) => theme.fontSizes.large};
`;


function VerticalMenuDynamic({ MenuSections }) {
    const { openModal } = useModal();
    const handleOpenModal = (content) => {
        openModal(content)
    };
    return <Wrapper>
        {MenuSections?.map(section => (
            <React.Fragment key={section?.title}>
                <Title>{section?.title}</Title>
                <OptionWrapper key={section?.title}>
                    {
                        section?.options?.map(option => (
                            <MenuItem key={option?.title} {...option} onClick={() => handleOpenModal(option?.content)} component={option?.component} />
                        ))
                    }
                </OptionWrapper>
            </React.Fragment>
        ))}
    </Wrapper>;
};

export default VerticalMenuDynamic;