import { gql } from "@apollo/client";

export const CLIENT_AVAILABLITY_FRAGMENT = gql`
    fragment ClientAvailablityFields on ClientAvailablity {
        CalendarEvent {
            IsAllDay
            StartTime
            EndTime
            DaysOfWeek
            Frequency
            Interval
            Description
            _id
        }
        _id
    }
`;
