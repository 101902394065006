import React, { useState } from 'react';
import PillDropDown from '../../../Content/PillDropDown';
import styled from 'styled-components';
import TaskList from '../../../Content/TaskLists';
import JobSiteNotes from '../JobSiteNotes';
import JobSiteClientAvailability from '../JobSiteClientAvailability';
import JobSiteInvoices from '../JobSiteInvoices';
import JobSiteQuotes from '../JobSiteQuotes';
import JobSiteAttachments from '../JobSiteAttachments';
import PillTabMenu from '../../../Content/common/PillTabMenu';
import AllProjects from '../AllProjects';
import AllTasks from '../AllTasks';
import { find, isEmpty, map } from 'lodash';

const Content = styled.div`
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-wrap: wrap;
`;

const Projects = ({ jobSite }) => {
    const [selectedOption, setSelectedOption] = useState(0);

    const projects = jobSite?.Projects || [];
    const options = map(projects, (value, index) => { return { label: value.Name, value: index } });
    const selectedProject = find(projects, ['_id', selectedOption]);

    if (isEmpty(options)) {
        options.push({ label: 'No Projects', value: null })
    }

    return (
        <Container>
            <Content>
                <PillTabMenu
                    defaultContent={<TaskList jobSite={jobSite} tasks={selectedProject?.Tasks} />}
                    beforeTabs={<PillDropDown
                        label={"Project"}
                        selected={selectedOption}
                        options={options}
                        onChange={setSelectedOption}
                    />}
                    tabs={[
                        { name: 'Notes', showBefore: true, content: <JobSiteNotes Notes={jobSite?.Notes} jobSite={jobSite} /> },
                        { name: 'Client Availability', showBefore: true, content: <JobSiteClientAvailability Availability={jobSite?.Availability} jobSite={jobSite} /> },
                        { name: 'Invoices', showBefore: true, content: <JobSiteInvoices Invoices={jobSite?.Invoices} jobSite={jobSite} /> },
                        { name: 'Quotes', showBefore: true, content: <JobSiteQuotes Quotes={jobSite?.Quotes} jobSite={jobSite} /> },
                        { name: 'Attachments', showBefore: true, content: <JobSiteAttachments Attachments={jobSite?.Attachments} jobSite={jobSite} /> },
                        { name: 'All Projects', showBefore: false, divider: true, content: <AllProjects jobSite={jobSite} /> },
                        { name: 'All Tasks', showBefore: false, content: <AllTasks jobSite={jobSite} /> }
                    ]}
                />
            </Content>
        </Container >
    );
}

export default Projects;
