import PhoneNumber from "../../components/FormBuilder/fields/PhoneNumber";
import Text from "../../components/FormBuilder/fields/Text";
import TextArea from "../../components/FormBuilder/fields/TextArea";
import Url from "../../components/FormBuilder/fields/Url";
import Address from "../../components/FormBuilder/fields/Address";
import Email from "../../components/FormBuilder/fields/Email";
import ProfilePic from "../../components/FormBuilder/fields/ProfilePic";
import InstantMessage from "../../components/FormBuilder/fields/InstantMessage";
import SocialProfile from "../../components/FormBuilder/fields/SocialProfiles";
import TimeZone from "../../components/FormBuilder/fields/TimeZone";
import FieldMultipleList from "../../components/FormBuilder/fields/FieldMultipleList";
import DateField from "../../components/FormBuilder/fields/Date";

export const fields = [
    {
        // label: 'Photo',
        fieldSet: [
            {
                label: 'Photo',
                name: 'VCard.Photo',
                type: ProfilePic,
                ariaLabel: 'Profile Picture',
                ariaDescribedby: 'Click to change profile picture',
                alt: 'Profile Picture',
                role: 'img',
            },
        ],
    },
    {
        fieldSet: [
            {
                label: 'Colour',
                name: 'Colour',
                type: Text,
                example: 'Example: FFF',
                placeholder: 'Enter Colour',
                ariaLabel: 'Colour',
                ariaDescribedby: 'Enter Colour',
            },
            {
                label: 'Code',
                name: 'Code',
                type: Text,
                example: 'Example: 123456',
                placeholder: 'Enter Code',
                ariaLabel: 'Code',
                ariaDescribedby: 'Enter Code',
                limit: 6,
            },
        ],
    },
    {
        fieldSet: [
            {
                label: 'PreFix',
                name: 'VCard.Prefix',
                type: Text,
                example: 'Example: Mr. Mrs.',
                placeholder: 'Enter Prefix',
                ariaLabel: 'Prefix',
                ariaDescribedby: 'Enter Prefix',
                autoComplete: 'honorific-prefix',
            },
            {
                label: 'First Name',
                name: 'VCard.FirstName',
                type: Text,
                example: 'Example: John',
                placeholder: 'Enter First Name',
                ariaLabel: 'First Name',
                ariaDescribedby: 'Enter First Name',
                autoComplete: 'given-name',
            },
            {
                label: 'Phonetic first name',
                name: 'VCard.PhoneticFirstName',
                type: Text,
                example: 'Example: Furigana',
                placeholder: 'Enter Phonetic First Name',
                ariaLabel: 'Phonetic First Name',
                ariaDescribedby: 'Enter Phonetic First Name',
                autoComplete: 'additional-name',
            },
            {
                label: 'Pronounciation first name',
                name: 'VCard.PronounciationFirstName',
                type: Text,
                example: 'Example: John',
                placeholder: 'Enter the phonetic pronunciation of your first name',
                ariaLabel: 'Pronunciation first name',
                ariaDescribedby: 'Enter the phonetic pronunciation of your first name',
                autoComplete: '',
            },
            {
                label: 'Middle Name',
                name: 'VCard.MiddleName',
                type: Text,
                example: 'Example: Lee',
                placeholder: 'Enter Middle Name',
                ariaLabel: 'Middle Name',
                ariaDescribedby: 'Enter Middle Name',
                autoComplete: 'additional-name',
            },
            {
                label: 'Phonetic Last Name',
                name: 'VCard.PhoneticLastName',
                type: Text,
                example: 'Example: Smith',
                placeholder: 'Enter Phonetic Last Name',
                ariaLabel: 'Phonetic Last Name',
                ariaDescribedby: 'Enter Phonetic Last Name',
                autoComplete: '',
            },
            {
                label: 'Last Name',
                name: 'VCard.LastName',
                type: Text,
                example: 'Smith',
                placeholder: 'Enter Last Name',
                ariaLabel: 'Last Name',
                ariaDescribedby: 'Enter Last Name',
                autoComplete: 'family-name',
            },
            {
                label: 'Pronounciation Last Name',
                name: 'VCard.PronounciationLastName',
                type: Text,
                example: 'Example: Smith',
                placeholder: 'Enter Pronunciation Last Name',
                ariaLabel: 'Pronunciation Last Name',
                ariaDescribedby: 'Enter Pronunciation Last Name',
                autoComplete: '',
            },
            {
                label: 'Maiden Name',
                name: 'VCard.MaidenName',
                type: Text,
                example: 'Example: Doe',
                placeholder: 'Enter Maiden Name',
                ariaLabel: 'Maiden Name',
                ariaDescribedby: 'Enter Maiden Name',
                autoComplete: '',
            },
            {
                label: 'Suffix',
                name: 'VCard.Suffix',
                type: Text,
                example: 'Example: Jr. Sr. III',
                placeholder: 'Enter Suffix',
                ariaLabel: 'Suffix',
                ariaDescribedby: 'Enter Suffix',
                autoComplete: '',
            },
            {
                label: 'Nickname',
                name: 'VCard.Nickname',
                type: Text,
                example: 'Example: Johnny',
                placeholder: 'Enter Nickname',
                ariaLabel: 'Nickname',
                ariaDescribedby: 'Enter Nickname',
                autoComplete: 'nickname',
            },
            {
                label: 'Job Title',
                name: 'VCard.JobTitle',
                type: Text,
                example: 'Example: Software Engineer',
                placeholder: 'Enter Job Title',
                ariaLabel: 'Job Title',
                ariaDescribedby: 'Enter Job Title',
                autoComplete: 'organization-title',
            },
            {
                label: 'Department',
                name: 'VCard.Department',
                type: Text,
                example: 'Example: Sales',
                placeholder: 'Enter Department',
                ariaLabel: 'Department',
                ariaDescribedby: 'Enter Department',
                autoComplete: 'organization-unit'
            },
            {
                label: 'Company',
                name: 'VCard.Company',
                type: Text,
                example: 'Example: JobSite Central',
                placeholder: 'Enter Company Name',
                ariaLabel: 'Company Name',
                ariaDescribedby: 'Enter Company Name',
                autoComplete: 'organization',
            },
            {
                label: 'Phonetic Company Name',
                name: 'VCard.PhoneticCompanyName',
                type: Text,
                example: 'Example: Job Site Central',
                placeholder: 'Enter Phonetic Company Name',
                ariaLabel: 'Phonetic Company Name',
                ariaDescribedby: 'Enter Phonetic Company Name',
                autoComplete: '',
            }
        ]
    },
    {
        label: 'Phone Number',
        name: 'VCard.Phones',
        type: FieldMultipleList,
        typeOptions: {
            genericType: PhoneNumber,
            keyName: 'PhoneType',
            keyOptions: [
                { value: 'Home', caption: 'Home' },
                { value: 'Work', caption: 'Work' },
                { value: 'Cell', caption: 'Cell' },
                { value: 'Other', caption: 'Other' },
            ],
        },
        example: 'Example: +1 (XXX) XXX - XXXX',
        placeholder: 'Enter Phone Number',
        ariaLabel: 'Phone Number',
        ariaDescribedby: 'Enter Phone Number',
        autoComplete: 'tel',
    },
    {
        label: 'Email Address',
        name: 'VCard.Emails',
        type: FieldMultipleList,
        typeOptions: {
            genericType: Email,
            keyName: 'EmailType',
            keyOptions: [
                { value: 'Home', caption: 'Home' },
                { value: 'Work', caption: 'Work' },
                { value: 'Other', caption: 'Other' },
            ],
        },
        example: 'Example: example@domain.com',
        placeholder: 'Enter Email Address',
        ariaLabel: 'Email Address',
        ariaDescribedby: 'Enter Email Address',
        autoComplete: 'email',
    },
    {
        label: 'Address',
        name: 'VCard.Addresses',
        type: FieldMultipleList,
        typeOptions: {
            genericType: Address,
            keyName: 'AddressType',
            keyOptions: [
                { value: 'Home', caption: 'Home' },
                { value: 'Work', caption: 'Work' },
                { value: 'Other', caption: 'Other' },
            ],
        }
    },
    {
        label: 'Websites',
        name: 'VCard.Websites',
        type: FieldMultipleList,
        typeOptions: {
            genericType: Url,
            keyName: 'WebsiteType',
            keyOptions: [
                { value: 'Personal', caption: 'Personal' },
                { value: 'Work', caption: 'Work' },
                { value: 'Other', caption: 'Other' },
            ],
        },
        example: 'Example: www.domain.com',
        placeholder: 'Enter Website',
        ariaLabel: 'Website',
        ariaDescribedby: 'Enter Website',
        autoComplete: 'url',
    },
    {
        label: 'Birthday',
        name: 'VCard.Birthday',
        type: DateField,
    },
    {
        label: 'Anniversary',
        name: 'VCard.Anniversary',
        type: DateField,
    },
    {
        label: 'Related Contact',
        name: 'VCard.RelatedVcard',
        type: Text,
    },
    {
        label: 'Instant Message',
        name: 'VCard.InstantMessagings',
        type: FieldMultipleList,
        typeOptions: {
            genericType: InstantMessage,
            keyName: 'IMType',
            keyOptions: [
                { value: 'FaceBook', caption: 'FaceBook' },
                { value: 'Teams', caption: 'Teams' },
                { value: 'Google Hangouts', caption: 'Google Hangouts' },
                { value: 'Other', caption: 'Other' },
            ],
        }
    },
    {
        label: 'Social Profiles',
        name: 'VCard.SocialProfiles',
        type: FieldMultipleList,
        typeOptions: {
            genericType: SocialProfile,
            keyName: 'SocialProfileType',
            keyOptions: [
                { value: 'FaceBook', caption: 'FaceBook' },
                { value: 'LinkedIn', caption: 'LinkedIn' },
                { value: 'Twitter', caption: 'Twitter' },
                { value: 'Other', caption: 'Other' },
            ],
        }
    },
    {
        label: 'TimeZone',
        name: 'VCard.Timezone',
        type: TimeZone,
    },
    {
        label: 'Notes',
        name: 'VCard.Notes',
        type: TextArea,
        example: 'Example: notes about...',
        placeholder: 'Enter Notes',
        ariaLabel: 'Notes',
        ariaDescribedby: 'Enter Notes',
        autoComplete: '',
    },
];