
import styled from 'styled-components';
import { CalendarContextProvider } from './CalendarContext';
import CalendarBody from './layout/CalendarBody';

const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.small} 0;
  box-sizing: border-box;
`;

function Calendar() {
  return <Wrapper>
    <CalendarContextProvider>
      <CalendarBody />
    </CalendarContextProvider>
  </Wrapper>;
};

export default Calendar;