import { useContext } from "react";
import { CalendarContext } from "../CalendarContext";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const DateWrapper = styled.div`
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme, activeView }) => activeView ? theme.colors.primary : `${theme.colors.primary}80`};
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
`;

const ChevronLeftButton = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme, activeView }) => activeView ? theme.colors.primary : `${theme.colors.primary}80`};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  &:active {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

const ChevronRightButton = styled.div`
  padding: 5px 10px;
  background-color: ${({ theme, activeView }) => activeView ? theme.colors.primary : `${theme.colors.primary}80`};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0px 5px 5px 0px;
  cursor: pointer;
  &:active {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`;

function CalendarHeader() {
  const { calendarState, setCalendarState } = useContext(CalendarContext);

  const handleCalendarState = (state) => {
    setCalendarState((prevValue) => {
      return {
        ...prevValue,
        ...state
      };
    });
  }
  return (
    <Wrapper>
      <DateWrapper>{calendarState?.today?.format('MMM DD, YYYY')}</DateWrapper>
      <ButtonWrapper>
        <ChevronLeftButton onClick={() => handleCalendarState({ today: calendarState?.today?.subtract(1, `${calendarState?.activeView}s`) })}><FontAwesomeIcon icon={faChevronLeft} /></ChevronLeftButton>
        <Button onClick={() => handleCalendarState({ activeView: 'day' })} activeView={calendarState?.activeView === 'day'}>Day</Button>
        <Button onClick={() => handleCalendarState({ activeView: 'week' })} activeView={calendarState?.activeView === 'week'}>Week</Button>
        <Button onClick={() => handleCalendarState({ activeView: 'month' })} activeView={calendarState?.activeView === 'month'}>Month</Button>
        <Button onClick={() => handleCalendarState({ activeView: 'year' })} activeView={calendarState?.activeView === 'year'}>Year</Button>
        <ChevronRightButton onClick={() => handleCalendarState({ today: calendarState?.today?.add(1, `${calendarState?.activeView}s`) })}><FontAwesomeIcon icon={faChevronRight} /></ChevronRightButton>
      </ButtonWrapper>
    </Wrapper>
  );
}

export default CalendarHeader;