import { fields } from './note.form.fields';
import { validation } from './note.form.validation';
import { displayRules } from './note.form.displayRules';
import { initGraphQL } from './note.form.init.graphql';

const initActiveFields = {
    'Name': true,
    'Type': true,
    'Description': true,
};

const blackListFields = [
];

export const noteFormConfig = {
    documentName: 'Note',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};