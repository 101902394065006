import { get, isEmpty } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import DisplayErrors from "../FormBuilder.DisplayErrors";

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  flex: 1;
  height: fit-content;

  input,
  textarea,
  select,
  .error {
    ${({ hasErrors }) => hasErrors && css`
      border-color: red !important;
    `}
  };
`;

const FieldLabel = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};

  ${({ theme, required }) => required && css`
      font-weight: ${theme.fontStyles.bold};
  `}
  ${({ hasErrors }) => hasErrors && css`
      color: red !important;
  `}
`;

const FieldExample = styled.span`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: ${({ theme }) => theme.fontStyles.italic};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.disabledText};
`;

const FieldWrapper = ({
  state,
  FieldComponent,
  index,
  autoComplete,
  name,
  translationKey,
  ...props
}) => {
  // Load Translations
  const { t } = useTranslation();
  const _translationKey = translationKey ? translationKey : `${state?.documentName}.Field.${name}`;
  const label = t(`${_translationKey}.Label`);
  const placeholder = t(`${_translationKey}.Placeholder`);
  const example = t(`${_translationKey}.Example`);
  const ariaLabel = t(`${_translationKey}.AriaLabel`);
  const ariaDescribedby = t(`${_translationKey}.AriaDescribedby`);

  const inputId = `field-${name}-${props?.index || 0}`;
  const errors = get(state, `errors.${name}`);
  const hasErrors = !isEmpty(errors);
  const required = get(state, `validation.required.${name}`);

  return (
    <FormField hasErrors={hasErrors}>
      <FieldLabel
        hasErrors={hasErrors}
        readOnly={!state?.isEditing}
        htmlFor={inputId}
        required={required}>
        {(hasErrors || required) && <>*&nbsp;</>}{label}
      </FieldLabel>
      <FieldComponent
        state={state}
        name={name}
        index={index}
        id={inputId}
        label={label}
        aria-label={ariaLabel}
        aria-invalid={hasErrors}
        aria-describedby={ariaDescribedby}
        required={false}
        readOnly={!state?.isEditing}
        aria-readonly={!state?.isEditing}
        placeholder={placeholder}
        autoComplete={index === 0 ? autoComplete : ''}
        {...props} />
      <FieldExample>{example}</FieldExample>
      <DisplayErrors
        label={label}
        errors={errors} />
    </FormField>
  );
};

export default FieldWrapper;
