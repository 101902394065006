import styled from "styled-components";
// import DateTabMenu from "../../Content/common/DateTabMenu";
// import TaskGridItem from "./TaskGridItem";
// import { jobs } from "../JobSites/JobsTestData";
// import { useState } from "react";
// import moment from "moment";

const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  min-height: 100%;
`;


function MyTasks() {
  // let tasks = jobs[0].Projects[0].Tasks;
  // const [activeDate, setActiveDate] = useState(moment());
  // const handleSetActiveDate = (day) => {
  //   setActiveDate(day);
  // };
  return <TaskContainer>
    {/* <DateTabMenu
      activeDate={activeDate}
      handleSetActiveDate={handleSetActiveDate}
      data={tasks}
      gridItem={TaskGridItem}
      OptionalTabs={[
        { name: 'Alerts', content: <div /> },
        { name: 'Materials', content: <div /> },
        { name: 'Equipment', content: <div /> }
      ]} /> */}
  </TaskContainer>;
};

export default MyTasks;