import { fields } from './alert.form.fields';
import { validation } from './alert.form.validation';
import { displayRules } from './alert.form.displayRules';
import { initGraphQL } from './alert.form.init.graphql';

const initActiveFields = {
    'Name': true,
    'Type': true,
    'Description': true,
};

const blackListFields = [
];

export const alertFormConfig = {
    documentName: 'Alert',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};