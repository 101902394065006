import React from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { setContext } from '@apollo/client/link/context';
import API_ROOT from './apollo.config';

export const MyApolloProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  // Define the ApolloClient with an authLink that adds the token to the request headers
  const authLink = setContext(async () => {
    const token = await getAccessTokenSilently();
    return {
      headers: {
        Authorization: `bearer ${token}`,
        jwt: true,
      }
    };
  });

  const httpLink = createHttpLink({ uri: API_ROOT });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};
