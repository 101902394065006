import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import React, { useCallback } from 'react';

const FieldMultipleListItemWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

const FieldMultipleListItemRemoveButton = styled.button`
  height: 100%;
  width: 100%;
  color: white;
  background-color: ${({ theme }) => theme.colors.red};
  border: none;
  font-size: 20px;
  border-radius: 1rem 0 0 1rem;
  padding: 0;
`;

const Column1 = styled.div`
  flex: 1;
  display: ${({ isEditing }) => (isEditing ? 'flex' : 'none')};
  align-items: ${({ isEditing }) => (isEditing ? 'flex-start' : 'center')};
`;

const Column2 = styled.div`
  flex: 8;
  border: ${({ isEditing }) => (isEditing ? 'solid black' : '')};
  border-width: ${({ isEditing }) => (isEditing ? '1px 0px 1px 0px' : '')};
  padding: ${({ isEditing }) => (isEditing ? '10px' : '')};
`;

const FieldMultipleListItem = (props) => {
  const FieldComponent = props?.typeOptions?.genericType;
  const removeField = useCallback((e) => {
    e.preventDefault();
    props?.handleRemoveField(props?.index);
  }, [props]);

  return (
    <FieldMultipleListItemWrapper>
      <Column1 isEditing={props?.state?.isEditing}>
        {props?.state?.isEditing &&
          <FieldMultipleListItemRemoveButton onClick={removeField}>
            <FontAwesomeIcon icon={faTimes} />
          </FieldMultipleListItemRemoveButton>
        }</Column1>
      <Column2 isEditing={props?.state?.isEditing}>
        <FieldComponent {...props} />
      </Column2>
    </FieldMultipleListItemWrapper>
  );
};

export default FieldMultipleListItem;
