import { gql } from "@apollo/client";
import { CLIENT_FRAGMENT } from "./Client";
import { PROJECT_FRAGMENT } from "./Project";

export const JOBSITE_FRAGMENT_QUERY = gql`
    ${CLIENT_FRAGMENT}
    ${PROJECT_FRAGMENT}
    fragment JobSiteFields on JobSite {
        Code
        ClientJobSiteCode
        Name
        Description
        Type
        Client {
            ...ClientFields
        }
        Projects {
            ...ProjectFields
        }
        ClientAddressId
        _id
        createdAt
        updatedAt
    }
`;

// export const JOBSITE_FRAGMENT_MUTATION = gql`
//     ${TENANT_FRAGMENT}
//     fragment JobSiteFields on JobSite {
//         Tenant {
//             ...TenantFields
//         }
//         Code
//         Name
//         Description
//         Client
//         ClientAddressId
//         _id
//         createdAt
//         updatedAt
//     }
// `;