import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';

const StyledTextArea = styled.textarea`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: scroll;

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.borderColor.default};
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall};
  box-sizing: border-box;
  /* ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''}; */
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};

  &:focus {
    border-color: ${({ theme }) => theme.borderColor.focus};
    outline-color: ${({ theme }) => theme.borderColor.outline};
  }
  word-wrap: break-word;
  resize: vertical;
  textarea {
    border-radius: ${({ theme }) => theme.borderRadius.small};
  }
`;

const TextArea = React.memo(({
  state,
  dispatch,
  name = '',
  index = undefined,
  type = 'text',
  formatInput = (value) => value,
  ...props
}) => {
  const [value, setValue] = useState('');
  const handleInputChange = useCallback((event) => {
    const newValue = event.target.value;
    setValue(newValue);
    dispatch({ type: 'UPDATE_FORM_INPUT', payload: { key: name, value: newValue, index } })
  }, [dispatch, name, index]);

  useEffect(() => {
    const inputValue = formatInput(getFormValue(state?.formInputs, name, index));
    setValue(inputValue);
  }, [state?.formInputs, name, index, formatInput]);

  const inputId = `field-${name}-${index}`;

  return <React.Fragment>
    <StyledTextArea
      id={inputId}
      readOnly={!state?.isEditing}
      name={name}
      type={type}
      value={value || ''}
      onChange={handleInputChange}
      rows="6"
      {...props} />
  </React.Fragment>;
});

export default TextArea;
