import { fields } from './project.form.fields.js';
import { validation } from './project.form.validation';
import { displayRules } from './project.form.displayRules';
import { initGraphQL } from './project.form.init.graphql.js';

const initActiveFields = {
    'Status': false,
    'Managers': true,
    'Priority': true,
    'Name': true,
    'Type': true,
    'Description': true,
    'TargetStartDate': true,
    'TargetEndDate': true,
};

const blackListFields = [
];

export const projectFormConfig = {
    documentName: 'Project',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};