import styled from "styled-components";
import { useState } from "react";
import Grid from "../Grid";
import Pagination from "../Pagination";
import SearchBar from "../SearchBar";
import PagedSizeSelector from "../PagedSizeSelector";
import { isArray } from "lodash";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: 100%;
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const Row = styled.div`
  flex: 1;
`;

const RowFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ListRow = styled.div`
  flex: 10;
`;

const PaginationRow = styled.div`
  display: flex;
  min-width: 100%;
  align-self: center;
  justify-content: center;
`;

function PagedGrid({ data, GridItem, searchable = true }) {
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    let filteredData;
    let pagedItems = [];
    let totalPages = 0;
    if (searchable) {
        filteredData = data?.filter(item =>
            item?.Code?.toLowerCase().includes(searchValue.toLowerCase())
        );
    } else {
        filteredData = data;
    }

    if (isArray(filteredData) && filteredData.length > 0) {
        totalPages = Math.ceil(filteredData.length / pageSize);

        pagedItems = filteredData.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        );
    }

    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const handlePageSizeChange = (value) => {
        setPageSize(value);
    };

    return <Wrapper>
        {searchable &&
            <>
                <Row>
                    <SearchBar
                        searchValue={searchValue}
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                    />
                </Row>
                <RowFlex>
                    <h2>Recent:</h2>
                    <PagedSizeSelector onChange={handlePageSizeChange} />
                </RowFlex>
            </>
        }
        <ListRow>
            <Grid items={pagedItems} GridItem={GridItem} />
        </ListRow>
        <Row>
            <PaginationRow>
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            </PaginationRow>
        </Row>
    </Wrapper>;
};

export default PagedGrid;