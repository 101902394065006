/* theme.js */
const theme = {
    colors: {
        // Palate
        primary: '#FFC845',
        secondary: '#0C40B3',
        third: '#457EFF',
        defaultText: '#2A2A2A',
        disabledText: '#9B9B9B',
        green: '#4CAF50',
        red: '#FF5747',
        white: '#FFF',
        contentArea: '#E0E0E0',
        // Other
        text: '#333',
        icon: '#000',
        appName: '#000',
        background: '#FFF',
        border: '#DDD',
        success: '#28A745',
        warning: '#FFC107',
        error: '#DC3545',
        mediumGrey: '#333333',
    },
    fontSizes: {
        xxlarge: '3rem', /* 48px */
        xlarge: '2.25rem', /* 36px */
        large: '1.875rem', /* 30px */
        medium: '1.5rem', /* 24px */
        small: '1.125rem', /* 18px */
        xsmall: '0.875rem', /* 14px */
        xxsmall: '0.75rem', /* 12px */
        xxxsmall: '0.625rem', /* 10px */
    },
    fontStyles: {
        italic: 'italic',
        bold: 'bold',
    },
    spacing: {
        xxxxlarge: '4rem',
        xxxlarge: '3rem', /* 48px */
        xxlarge: '2.25rem', /* 36px */
        xlarge: '1.875rem', /* 30px */
        large: '1.5rem', /* 24px */
        medium: '1.125rem', /* 18px */
        small: '0.875rem', /* 14px */
        xsmall: '0.75rem', /* 12px */
        xxsmall: '0.625rem', /* 10px */
        xxxsmall: '0.5rem', /* 8px */
        xxxxsmall: '0.375rem', /* 6px */
        xxxxxsmall: '0.25rem', /* 4px */
    },
    responsiveFontSizes: {
        xlarge: 'max(3.125rem, 5vw);',
        large: 'max(2.5rem, 3vw);',
        medium: 'max(2.5rem, 2vw);',
        small: 'max(1.875rem, 1.5vw);',
        xsmall: 'max(1.25rem, 1vw);',
    },
    responsiveSpacing: {
        xsmall: '1vw',
        small: '2vw',
        medium: '4vw',
        large: '6vw',
        xlarge: '8vw',
        xxlarge: '15%',
    },
    borderRadius: {
        xsmall: '0.125rem', /* 2px */
        small: '0.25rem',
        medium: '0.5rem',
        large: '0.75rem',
    },
    borderColor: {
        default: '#2A2A2A',
        focus: '#0C40B3',
        outline: '#0C40B3',
        disabled: '#9B9B9B',
    },
    boxShadow: {
        small: '0px 0.125rem 0.25rem rgba(0, 0, 0, 0.1)',
        medium: '0px 0.25rem 0.5rem rgba(0, 0, 0, 0.1)',
        large: '0px 0.5rem 1rem rgba(0, 0, 0, 0.1)',
    },
    navbar: {
        backgroundColor: '#FFF',
        textColor: '#333',
        activeTextColor: '#0047AB',
        borderColor: '#DDD',
        activeBorderColor: '#0047AB',
        height: '2.5rem',
    },
    profile: {
        backgroundColor: '#FFF',
        textColor: '#333',
        borderColor: '#DDD',
        imageSize: '5rem',
        borderRadius: '50%',
    },
    sidebar: {
        width: '5rem',
        backgroundColor: '#FFF',
        iconColor: '#333',
        labelFontSize: '0.75rem',
        iconFontSize: '1.5rem',
        iconMarginBottom: '0.625rem',
        iconLinkMarginBottom: '1.25rem',
        mobileHeight: '10vh',
    },
    header: {
        logoSize: 'calc(1vw + 3rem)',
        backgroundColor: "#FFFFFF",
        height: "8rem",
        padding: "1rem 2rem",
        boxShadow: "0px 0.125rem 0.25rem rgba(0, 0, 0, 0.1)",
        logo: {
            maxWidth: "20rem",
        },
        navLink: {
            color: "#000000",
            fontWeight: "bold",
            margin: "0rem 2rem",
        },
    },
    footer: {
        height: "3.5rem",
    },
    breakpoints: {
        mobile: "576px",
        tablet: "768px",
        desktop: "992px",
        largeDesktop: "1200px",
    }
}

export default theme;
