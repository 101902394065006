import { cloneDeep, get, isEmpty, isObject, set } from "lodash";

export function updateActiveFields(obj, activeFields) {
  for (const key in activeFields) {
    const fieldValue = get(obj, key);

    // Check if the field exists and is not null or undefined
    if (fieldValue !== null && fieldValue !== undefined) {
      set(activeFields, `${key}`, true);
    }
  }
  return activeFields;
}

export function initializeActiveFields(formInputs, initActiveFields = {}) {
  // 1: Use the inital active fields that will come from the document decloration as starting point
  const activeFields = cloneDeep(initActiveFields);

  // 2: loop through the form inputs and set any field that is a valid field to 'true' if it has a value
  function initializeActiveFieldsRecursive(object, prefix = '') {
    for (const key in object) {
      const path = prefix ? `${prefix}.${key}` : key;
      const fieldValue = get(formInputs, path);

      if (isObject(fieldValue)) {
        initializeActiveFieldsRecursive(fieldValue, path);
      } else {
        if (!isEmpty(fieldValue)) {
          activeFields[path] = true;
        }
      }
    }
  }

  initializeActiveFieldsRecursive(formInputs);

  // Remove id field
  delete activeFields._id;

  return activeFields;
}