// FormBuilder.js
import { FormProvider } from "../../providers/FormProvider/FormProvider";
import Builder from "./FormBuilder.Builder";

export default function FormBuilder({ documentName, initActiveFields, isNewDocument, refs, displayRules, validation, ...props }) {
    return <FormProvider initialValues={{
        documentName: documentName,
        activeFields: initActiveFields,
        initActiveFields: initActiveFields,
        isEditing: isNewDocument || false,
        displayRules,
        validation,
        refs
    }}>
        <Builder isNewDocument={isNewDocument} documentName={documentName} {...props} />
    </FormProvider>;
};