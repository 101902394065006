import { gql } from "@apollo/client";
import { PROFILE_FRAGMENT } from "../../graphql/Fragments/Profile";

export const initGraphQL = gql`
    ${PROFILE_FRAGMENT}
    query initProfileGraphQL($id: MongoID!) {
        ProfileFindById(_id: $id) {
            ...ProfileFields
        }
    }
`;