import { gql } from "@apollo/client";

export const initGraphQL = gql`
    query initAttachmentGraphQL($id: MongoID!) {
        AttachmentFindById(_id: $id) {
            Type
            Description
            Files {
                signedUrl
                publicId
                format
                resourceType
                _id
            }
            _id
        }
    }
`;