import React from 'react';


const Note = ({ Note, Job }) => {
    return (
        <>
        </>
    );
};

export default Note;
