import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faBoxesStacked, faCamera, faClipboard, faExclamationCircle, faFile, faFileAlt, faImage, faNoteSticky, faPaperclip, faProjectDiagram, faTextSlash, faTools, faVideo } from "@fortawesome/free-solid-svg-icons";
import ChatTextInput from './ChatInput';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Messages = styled.div`
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  gap: 10px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-self: ${({ isOutgoing }) => (isOutgoing ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageContent = styled.div`
  margin-bottom: ${({ hasAttachment }) => (hasAttachment ? '10px' : '0')};
  align-self: ${({ isOutgoing }) => (isOutgoing ? 'flex-end' : 'flex-start')};
  padding: 8px;
  border-radius: 10px;
  background-color: ${({ isOutgoing, theme }) =>
        isOutgoing ? `${theme.colors.primary}50` : `${theme.colors.secondary}25`};
  color: ${({ isOutgoing, theme }) =>
        isOutgoing ? theme.colors.black : theme.colors.black};
`;

const AttachmentContainer = styled.div`
  align-self: ${({ isOutgoing }) => (isOutgoing ? 'flex-end' : 'flex-start')};
  display: flex;
  flex-direction: column;
  align-items: ${({ isOutgoing }) => (isOutgoing ? 'flex-end' : 'flex-start')};
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: ${({ isOutgoing, theme }) =>
        isOutgoing ? `${theme.colors.primary}50` : `${theme.colors.secondary}25`};

  color: ${({ isOutgoing, theme }) =>
        isOutgoing ? theme.colors.black : theme.colors.white};
`;

const Attachment = styled.div`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.lightGray};
  color: ${({ theme }) => theme.colors.black};
`;

const AttachmentIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
`;

const AttachmentName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Chatroom = ({ data }) => {
    const [messages, setMessages] = useState(data);
    const [newMessage, setNewMessage] = useState('');

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;

        const message = {
            content: newMessage,
            sender: 'Me', // Replace with actual sender
            timestamp: new Date().toLocaleTimeString(), // Replace with actual timestamp logic
        };

        setMessages([...messages, message]);
        setNewMessage('');
    };

    return (
        <Container>
            <Messages>
                {messages.map((message, index) => (
                    <Message key={index} >
                        <MessageContent hasAttachment={message.attachments?.length}
                            isOutgoing={message.sender === 'Me'}>
                            {message.content}
                        </MessageContent>
                        {message.attachments?.map((attachment) => (
                            <AttachmentContainer
                                key={attachment.id}
                                isOutgoing={message.sender === 'Me'}
                            >
                                {attachment.type === 'image' ? (
                                    <img src={attachment.url} alt="Attachment" />
                                ) : (
                                    <Attachment>
                                        <AttachmentIcon icon={getAttachmentIcon(attachment.type)} />
                                        <AttachmentName>{attachment.name}</AttachmentName>
                                    </Attachment>
                                )}
                            </AttachmentContainer>
                        ))}
                    </Message>
                ))}
            </Messages>
            <ChatTextInput
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onSendMessage={handleSendMessage}
            />
            <Icons>
                <FontAwesomeIcon icon={faTextSlash} />
                <FontAwesomeIcon icon={faCamera} />
                <FontAwesomeIcon icon={faImage} />
                <FontAwesomeIcon icon={faProjectDiagram} />
                <FontAwesomeIcon icon={faClipboard} />
                <FontAwesomeIcon icon={faBoxesStacked} />
                <FontAwesomeIcon icon={faTools} />
                <FontAwesomeIcon icon={faNoteSticky} />
                <FontAwesomeIcon icon={faExclamationCircle} />
                <FontAwesomeIcon icon={faPaperclip} />
            </Icons>
        </Container>
    );
};

const getAttachmentIcon = (type) => {
    switch (type) {
        case 'image':
            return faImage;
        case 'file':
            return faFile;
        case 'video':
            return faVideo;
        // Add more cases for other attachment types
        default:
            return faFileAlt;
    }
};

export default Chatroom;
