/* AppRoot.js */
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { AppConfigProvider } from '../config';
import { MyApolloProvider } from '../apollo/ApolloClient';
import { ModalProvider } from '../hooks/useModal';
import { ErrorProvider } from '../providers/ErrorProvider/ErrorProvider';
import Router from './Router';
import theme from './StyledComponents/theme';
import { LayoutContextProvider } from './Utill/LayoutContext';
import ErrorBoundary from './Utill/ErrorBoundary/ErrorBoundary';

const AppRoot = props => {
  return (
      <ErrorProvider>
        <MyApolloProvider>
          <ErrorBoundary>
            <AppConfigProvider>
              <LayoutContextProvider>
                <ThemeProvider theme={theme}>
                  <ModalProvider>
                    <Router />
                  </ModalProvider>
                </ThemeProvider>
              </LayoutContextProvider>
            </AppConfigProvider>
          </ErrorBoundary>
        </MyApolloProvider>
      </ErrorProvider>
  );
}

export default AppRoot;
