import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
`;

const JobNumber = styled.p`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0;
`;

const Client = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const Address = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const LocationLine = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const Label = styled.span`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  margin: 0;
`;

const Disabled = styled.span`
  color: ${({ theme }) => theme.colors.disabledText};
`;

function formatAddress({ Street, City, StateProvince, PostalCode, Country }) {
  return [
    Street,
    `${City}, ${StateProvince} ${PostalCode}`,
    Country
  ];
}


const JobSitePreview = ({ jobSite }) => {
  const client = jobSite?.Client;
  const address = find(client?.VCard?.Addresses, ['_id', jobSite?.ClientAddressId]);
  const formattedAddress = formatAddress(address || {});

  return (
    <Container>
      <JobNumber><Label>Code:</Label> {jobSite?.ClientJobSiteCode}</JobNumber>
      <Client><Label>Client:</Label> {client?.VCard?.ContactName || <Disabled>Loading client name...</Disabled>}</Client>
      <Address><Label>Address:</Label> {address ? formattedAddress.map((line, index) => (
        <LocationLine key={index}>{line}</LocationLine>
      )) : <Disabled>Loading client address...</Disabled>}
      </Address>
      <Client><Label>Job Type:</Label> {jobSite?.Type || <Disabled>Loading Job Site Type...</Disabled>}</Client>
      <Client><Label>Description:</Label> {jobSite?.Description || <Disabled>Loading Job Site Description...</Disabled>}</Client>
    </Container>
  );
};

export default JobSitePreview;
