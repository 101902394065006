// FormBuilder.FieldMaker.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Field from './FormBuilder.Field';
import { FieldSetLabel, FormFieldSet } from './StyledComponents';

function filterFieldsByNames(fields, activeFields) {
    let filteredFields = [];

    if (!fields) return;

    for (let field of fields) {
        if (field?.fieldSet) {
            let filteredFieldSet = {
                ...field,
                fieldSet: filterFieldsByNames(field?.fieldSet, activeFields),
            };

            if (filteredFieldSet.fieldSet.length > 0) {
                filteredFields?.push(filteredFieldSet);
            }
        } else {
            const fieldName = field?.name;
            if (activeFields?.[fieldName]) {
                filteredFields?.push(field);
            }
        }
    }

    return filteredFields;
}


const FieldMaker = ({ state, dispatch, fields }) => {
    const filteredFields = fields ? filterFieldsByNames(fields, state?.activeFields) : [];
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {filteredFields.map((FieldOptions, index) => (
                FieldOptions?.fieldSet?.length ? (
                    <div key={`fieldset-${index}`}>
                        <FieldSetLabel>{t(`${state?.documentName}.FieldSet.${FieldOptions?.label}`)}</FieldSetLabel>
                        <FormFieldSet>
                            {FieldOptions?.fieldSet?.map((FieldSetOptions, fieldSetIndex) => (
                                <Field
                                    state={state}
                                    dispatch={dispatch}
                                    key={`field-${fieldSetIndex}`}
                                    fieldSet={true}
                                    {...FieldSetOptions}
                                />
                            ))}
                        </FormFieldSet>
                    </div>
                ) : (
                    <Field
                        key={`field-${index}`}
                        {...FieldOptions}
                    />
                )
            ))}
        </React.Fragment>
    );
};

export default FieldMaker;
