import { fields } from './attachment.form.fields';
import { validation } from './attachment.form.validation';
import { displayRules } from './attachment.form.displayRules';
import { initGraphQL } from './attachment.form.init.graphql';

const initActiveFields = {
    'Type': true,
    'Files': true,
    'Description': true,
};

const blackListFields = [
];

export const attachmentFormConfig = {
    documentName: 'Attachment',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};