import { gql } from "@apollo/client";
import { PROFILE_FRAGMENT } from "../Fragments/Profile";

export const ProfileOne = gql`
    ${PROFILE_FRAGMENT}
    query Query($filter: FilterFindOneProfileInput) {
        ProfileOne(filter: $filter) {
            ...ProfileFields
        }
    }
`;

export const ProfileFindMany = gql`
    ${PROFILE_FRAGMENT}
    query ProfileFindMany($filter: FilterFindManyProfileInput, $skip: Int, $limit: Int, $sort: SortFindManyProfileInput) {
        ProfileFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            ...ProfileFields
        }
    }
`;
