import { gql } from "@apollo/client";

export const ATTACHMENT_FRAGMENT = gql`
    fragment AttachmentFields on Attachment {
        Type
        Description
        Files {
            signedUrl
            publicId
            format
            resourceType
            _id
        }
        _id
    }
`;
