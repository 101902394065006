import { faBuilding } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  grid-gap: ${({ theme }) => theme.spacing.xlarge};
  max-width: 100%;
  flex-wrap: wrap;
`;

const Column1 = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex: 1;
  flex-shrink: 0;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const Column2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  flex: 1;
  flex-shrink: 0;

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;
  flex-direction: column;
`;


const ProfilePictureWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const ProfilePictureImage = styled.img`
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
  object-position: center;
`;

const PlaceholderIcon = styled(FontAwesomeIcon)`
  width: 150px;
  height: 150px;
  width: fit-content;
  font-size: 120px;
  border-radius: 50%;
  ${({ isediting }) => isediting === 'true' && (`cursor: pointer;`)};
`;

const CompanyDetailsPreview = ({ Company }) => {
  return (
    <Wrapper>
      <Column1>
        <ProfilePictureWrapper>
          {Company?.Photo?.signedUrl ? (
            <ProfilePictureImage
              src={Company?.Photo?.signedUrl}
              alt="Company"
            />
          ) : (
            <PlaceholderIcon icon={faBuilding} />
          )}
        </ProfilePictureWrapper>
      </Column1>
      <Column2>
        <Details>
          <p>{Company?.Company}</p>
        </Details>
      </Column2>
    </Wrapper>
  );
};

export default CompanyDetailsPreview;
