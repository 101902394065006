/* Router.js */
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "./Routes";

const router = createBrowserRouter(routes);

const Router = props => {
  return <RouterProvider router={router} />;
};

export default Router;
