import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

const ListItem = styled.div`
    flex: 1;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

const Title = styled.p`
    margin: 0;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    margin: 0;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.defaultText};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${({ theme }) => theme.borderRadius.medium};
    padding: ${({ theme }) => theme.spacing.medium};
`;



export default function MenuItem({ title, component, onClick }) {
    return <Wrapper onClick={() => onClick(title)}>
        {component ?
            component
            : <ListItem>
                <Title>{title}</Title>
            </ListItem>}
        <IconWrapper>
            <FontAwesomeIcon icon={faChevronRight} />
        </IconWrapper>
    </Wrapper>;
};