// formReducer.js
import { produce } from 'immer';
import { replace, set } from 'lodash';
import { initializeActiveFields } from './formReducer.utils';

function formReducer(state, action) {
    console.log({ state, action });
    switch (action.type) {
        // Initialize
        case 'SET_FORM_INPUTS':
            return produce(state, draft => {
                draft.formInputs = action.payload?.formInputs;
                draft.initialFormInputs = action.payload?.formInputs;
                draft.activeFields = initializeActiveFields(action.payload?.formInputs, state?.initActiveFields);
                draft.initActiveFields = draft.activeFields;
                draft.displayRules(draft.formInputs, draft.activeFields);
            });

        // Update
        case 'UPDATE_FORM_INPUT':
            const { key, value, index } = action.payload;
            const _key = replace(key, '[index]', `[${index}]`);
            return produce(state, draft => {
                set(draft, `formInputs.${_key}`, value);
                draft.dirtyValues[_key] = true;
                draft.displayRules(draft.formInputs, draft.activeFields);
            });

        case 'TOGGLE_ACTIVE_FIELD':
            return {
                ...state,
                activeFields: {
                    ...state.activeFields,
                    [action.payload]: !state.activeFields[action.payload],
                },
            };

        case 'SET_ERRORS':
            return { ...state, errors: action.payload };

        case 'SET_EDITING':
            return { ...state, isEditing: action.payload };

        case 'SET_LOCAL_FORM_STATE':
            return { ...state, localFormState: action.payload };

        case 'UPDATE_LOCAL_FORM_STATE':
            return {
                ...state,
                localFormState: {
                    ...state.localFormState,
                    ...action.payload,
                },
            };

        case 'CANCEL_EDITING':
            return { ...state, isEditing: false, formInputs: state.initialFormInputs, dirtyValues: {} };

        case 'CLEAR_FORM':
            return { ...state, formInputs: {}, dirtyValues: {} };

        case 'RESET_FORM_INITAL_STATE':
            return {
                ...state,
                isEditing: false,
                formInputs: state?.initialFormInputs,
                dirtyValues: {},
                activeFields: state?.initActiveFields,
            };

        case 'SUBMIT_FORM_SUCCESS':
            return {
                ...state,
                isEditing: false,
                dirtyValues: {},
                errors: {},
                submitSuccess: true,
            };
        case 'SUBMIT_FORM_FAILED':
            return {
                ...state,
                // isEditing: true,
            };
        default:
            return state;
    }
}

export default formReducer;
