import styled from "styled-components";
import FormBuilder from "../../FormBuilder";
import { noteFormConfig } from "../../../documentConfigs/note/note.form.js";

const NoteDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const NoteDetails = ({ note = {}, closeModal = () => { }, isNewDocument }) => {
    return <NoteDetailsWrapper>
        <FormBuilder
            documentId={note?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            {...noteFormConfig}
        />
    </NoteDetailsWrapper>;
};

export default NoteDetails