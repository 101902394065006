import { fields } from './jobsite.form.fields';
import { validation } from './jobsite.form.validation';
import { displayRules } from './jobsite.form.displayRules';
import { initGraphQL } from './jobsite.form.init.graphql';

const initActiveFields = {
    'Code': true,
    'Name': true,
    'Type': true,
    'Description': true,
    'EstimatedStartDate': true,
    'EstimatedCompletionDate': true,
    'Client._id': true,
    'Clients': true,
    'ClientAddressId': true,
};

const blackListFields = [
];


export const jobsiteFormConfig = {
    documentName: 'JobSite',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};