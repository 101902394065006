import { gql } from "@apollo/client";
import { TASK_FORM_FRAGMENT } from "../../graphql/Fragments/Task";

export const initGraphQL = gql`
    ${TASK_FORM_FRAGMENT}
    query initTaskGraphQL($id: MongoID!) {
        TaskFindById(_id: $id) {
            ...TaskFormFields
        }
        ProfileFindMany {
            VCard {
                ContactName
                _id
            }
            _id
        }
    }
`;