import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const DropdownContainer = styled.div`
  display: inline-block;
`;

const Label = styled.span`
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    margin-right: 10px;
`;

const Dropdown = styled.select`
    padding: ${({ theme }) => theme.spacing.xxxxsmall} ${({ theme }) => theme.spacing.xxxsmall};
    font-size: ${({ theme }) => theme.fontSizes.xsmall};
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    cursor: pointer;
    text-align: center;
`;

const PagedSizeSelector = ({ options = [5, 10, 25, 50, 100], onChange, defaultValue = options[1] }) => {
    const [selectedValue, setSelectedValue] = useState(defaultValue);
  
    useEffect(() => {
      onChange(selectedValue);
    }, [selectedValue]);

    return (
        <DropdownContainer>
            <Label>Items per page:</Label>
            <Dropdown onChange={e => setSelectedValue(e.target.value)} value={selectedValue}>
                {options.map((option, index) => (
                    <option key={index} value={option}>
                        {option}
                    </option>
                ))}
            </Dropdown>
        </DropdownContainer>
    );
};

export default PagedSizeSelector;
