import styled from "styled-components";
import FormBuilder from "../../FormBuilder";
import { alertFormConfig } from "../../../documentConfigs/alert/alert.form.js";

const AlertDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const Alert = ({ alert = {}, closeModal = () => { }, isNewDocument }) => {
    return <AlertDetailsWrapper>
        <FormBuilder
            documentId={alert?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            {...alertFormConfig}
        />
    </AlertDetailsWrapper>;
};

export default Alert;