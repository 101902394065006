
import Text from "../../components/FormBuilder/fields/Text";
import Select from "../../components/FormBuilder/fields/Select";
import TextArea from "../../components/FormBuilder/fields/TextArea";

export const fields = [
    {
        label: "Details",
        fieldSet: [
            {
                label: 'Status',
                name: 'Status',
                type: Select,
                example: '',
                placeholder: 'Select Status',
                ariaLabel: 'Status',
                ariaDescribedby: 'Select Status',
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'Pending', caption: 'Pending' },
                        { value: 'Active', caption: 'Active' },
                        { value: 'Complete', caption: 'Complete' },
                    ],
                },
            },
            {
                label: 'Name',
                name: 'Name',
                type: Text,
                example: '',
                placeholder: 'Enter Name',
                ariaLabel: 'Name',
                ariaDescribedby: 'Enter Name',
                autoComplete: '',
            },
            {
                label: 'Type',
                name: 'Type',
                type: Select,
                example: '',
                placeholder: 'Select Note Type',
                ariaLabel: 'Note Type',
                ariaDescribedby: 'Select Note Type',
                limit: 30,
                autoComplete: '',
                optionsConfig: {
                    options: [
                        { value: 'LandScaping', caption: 'LandScaping' },
                        { value: 'New Construction', caption: 'New Construction' },
                        { value: 'Retrofit', caption: 'Retrofit' }
                    ],
                }
            },
            {
                label: 'Description',
                name: 'Description',
                type: TextArea,
                example: '',
                placeholder: 'Enter Description',
                ariaLabel: 'Description',
                ariaDescribedby: 'Enter Description',
                autoComplete: '',
            },
        ]
    },
];