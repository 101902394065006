import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    flex: 1;
    background-color: white;
    border-radius: 10px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    padding: ${({ theme }) => theme.spacing.small};
    gap: ${({ theme }) => theme.spacing.large};
    align-items: center;
`;

const Column1 = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
`;

const Column2 = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 60%;
`;

const Details = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const Picture = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: ${({ pictureSize }) => pictureSize};
  height: ${({ pictureSize }) => pictureSize};
  > svg {
    width: ${({ pictureSize }) => pictureSize};
    height: ${({ pictureSize }) => pictureSize};
  }
  > img {
    width: ${({ pictureSize }) => pictureSize};
    height: ${({ pictureSize }) => pictureSize};
  }
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
`;

const Name = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const ClientCode = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ theme }) => theme.colors.disabledText};
  margin: 0;
`;

const JobSites = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.small};
  margin: 0;
`;

const ClientPreview = ({ item, handleClick }) => {
    const [pictureSize, setPictureSize] = useState(50);
    const column2Ref = useRef(null);
    const showCompany = item?.VCard?.Company;

    useEffect(() => {
        if (column2Ref.current) {
            setPictureSize(column2Ref.current.offsetHeight)
        }
    }, [column2Ref]);

    return (
        <Wrapper onClick={() => handleClick(item)}>
            <Row>
                <Column1>
                    <Picture pictureSize={`${pictureSize}px`}>
                        {item?.VCard?.Photo?.signedUrl ? (
                            <img
                                src={item?.VCard?.Photo?.signedUrl}
                                alt="Profile"
                                style={{ objectFit: 'cover', borderRadius: '50%', objectPosition: 'center' }}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faCircleUser} style={{ fontSize: '120px', borderRadius: '50%' }} />
                        )}
                    </Picture>
                </Column1>
                <Column2 ref={column2Ref}>
                    <Details>
                        <NameWrapper>
                            {showCompany ? (
                                <Name>{item?.VCard?.Company}</Name>
                            ) : (
                                <Name>
                                    {item?.VCard?.FirstName} {item?.VCard?.LastName}
                                </Name>
                            )}
                            <ClientCode>CLIENT</ClientCode>
                        </NameWrapper>
                        <JobSites>
                            {item?.RelatedJobSites?.length ?? 0} JobSite{item?.RelatedJobSites?.length !== 1 ? 's' : ''}
                        </JobSites>
                    </Details>
                </Column2>
            </Row>
        </Wrapper>
    );
};

export default ClientPreview;
