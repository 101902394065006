// FormBuilder.Field.js
import React from "react";
import { FormField } from "./StyledComponents";
import FieldMultipleList from "./fields/FieldMultipleList";
import FieldWrapper from "./fields/FieldWrapper";

const Field = ({ type: FieldComponent, fieldSet, ...props }) => {
  return (
    <FormField fieldSet={fieldSet}>
      {FieldComponent === FieldMultipleList ?
        <FieldComponent {...props} />
        : <FieldWrapper FieldComponent={FieldComponent} {...props} />
      }
    </FormField>
  );
};

export default Field;
