import { gql } from "@apollo/client";

export const initGraphQL = gql`
    query initClientAvailablityGraphQL($id: MongoID!) {
        ClientAvailablityFindById(_id: $id) {
            CalendarEvent {
                IsAllDay
                StartTime
                EndTime
                DaysOfWeek
                Frequency
                Interval
                Description
                _id
            }
            _id
        }
    }
`;