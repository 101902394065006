import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { LayoutContext } from '../../../Utill/LayoutContext';
import { useContext } from 'react';

const StyledModalWrapper = styled.div`
  display: ${props => (props.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;

  right: 0;
  bottom: 0;
  background-color: #33333399;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.2s ease-out, visibility 0s linear ${({ isOpen }) => (isOpen ? '0s' : '0.2s')};
`;

const StyledModalContent = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); */


  background-color: ${({ theme }) => theme.colors.contentArea};
  padding: 20px;
  border-radius: 4px;
  width: ${({ miniModal }) => (miniModal ? '25%' : '80%')};
  height: ${({ miniModal }) => (miniModal ? '40%' : '85%')};
  position: relative;
  margin-top: ${({ miniModal }) => (miniModal ? 'inherit' : 'auto')};
  margin-left: ${({ mainLeftMargin }) => mainLeftMargin};
  margin-right: 0px;
  box-sizing: border-box;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'auto' : 'none')};
  transform: translateY(${({ isOpen }) => (isOpen ? '0' : '100%')});
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  padding: ${({ theme }) => theme.spacing.small};
  overflow: auto;
  max-width: 80%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 0px;
    margin-top: 10vh;
    width: ${({ miniModal }) => (miniModal ? '70vw' : '100vw')};
    height: ${({ miniModal }) => (miniModal ? ' 60vh' : '100vh')};
    max-width: 100%;
  }
`;

// const StyledCloseButton = styled.button`
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
//   position: absolute;
//   top: 10px;
//   right: 10px;
// `;


const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

const Modal = ({ modals, closeModal }) => {
  const { LayoutValue } = useContext(LayoutContext);
  return (
    <>
      {modals.map((modal, index) => {
        if (!modal.isOpen) return null;

        const modalContentWithProps = React.cloneElement(modal.content, {
          closeModal: () => closeModal(index),
        });

        return (
          <StyledModalWrapper key={index} isOpen={modal.isOpen} onClick={() => modal.clickOut ? closeModal(index) : undefined}>
            <GlobalStyle />
            <StyledModalContent
              isOpen={modal.isOpen}
              miniModal={modal.miniModal}
              mainLeftMargin={LayoutValue.mainLeftMargin}
              onClick={(e) => e.stopPropagation()}>
              {modalContentWithProps}
            </StyledModalContent>
          </StyledModalWrapper>
        );
      })}
    </>
  );
};

export default Modal;
