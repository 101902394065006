import { gql } from "@apollo/client";
import { VCARD_FRAGMENT } from "./VCard";

export const CLIENT_FRAGMENT = gql`
    ${VCARD_FRAGMENT}
    fragment ClientFields on Client {
        VCard {
            ...VCardFields
        }
        _id
        Code
        Colour
        RelatedJobSites {
            _id
        }
    }
`;
