import styled from "styled-components";
import FormBuilder from "../../FormBuilder";
import { taskFormConfig } from "../../../documentConfigs/task/task.form.js";

const TaskDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;


const TaskDetails = ({ task = {}, closeModal = () => { }, isNewDocument }) => {
    return <TaskDetailsWrapper>
        <FormBuilder
            documentId={task?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            {...taskFormConfig}
        />
    </TaskDetailsWrapper>;
};

export default TaskDetails