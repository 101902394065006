/* Config.js */
import React, { createContext, useState } from 'react';
import { AppConfigDefault } from './config.defaults';


export const AppConfigContext = createContext();

export const AppConfigProvider = ({ children }) => {
  const [appConfig, setAppConfig] = useState(AppConfigDefault);

  return (
    <AppConfigContext.Provider value={{ appConfig, setAppConfig }}>
      {children}
    </AppConfigContext.Provider>
  );
};
