import styled from 'styled-components';
import Text from './Text';
import { useCallback } from 'react';
import Select from './Select';

const PhoneNumberRow = styled.fieldset`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  align-items: stretch;
  margin: 0;
  padding: 0;
  border: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

const PhoneNumber = (props) => {
  const formatInput = (value) => {
    if (!value) {
      return;
    }
    const numbers = value?.replace(/\D/g, '');
    let formattedValue = '';
    let index = 0;

    if (numbers.startsWith('1') && numbers[index + 1]) {
      formattedValue += '+1 ';
      index += 1;
    }
    if ((!numbers.startsWith('1') && numbers[index + 1]) || numbers[index + 1]) {
      formattedValue += '(';
    }
    formattedValue += numbers.substring(index, index + Math.min(3, numbers.length));
    index += Math.min(3, numbers.length);
    if (numbers[index]) {
      formattedValue += ') ';
    }
    formattedValue += numbers.substring(index, index + Math.min(3, numbers.length))
    index += Math.min(3, numbers.length);
    if (numbers[index]) {
      formattedValue += ' - ';
    }
    formattedValue += numbers.substring(index, index + Math.min(4, numbers.length))

    return formattedValue;
  };

  const handleInputChange = useCallback((name, value, _index) => {
    props?.onChange(name, value.replace(/\D/g, ''), _index);
  }, [props]);

  return <PhoneNumberRow>
    <Select
      {...props}
      name={`${props?.name}-PhoneType`}
      example="Example: work, home, cell"
      placeholder="Select address type"
      label={`Phone Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`Phone Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaDescribedby="Select Phone type"
      autoComplete="phone-type"
      options={props?.typeOptions?.keyOptions}
    />
    <Text
      {...props}
      type="tel"
      label={`${props?.label} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`${props?.ariaLabel} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      name={`${props?.name}-PhoneNumber`}
      formatInput={formatInput}
      onChange={handleInputChange}
      // pattern="(\+1)?[ ]?\(\d{3}\)[ ]\d{3}[ ][-][ ]\d{4}"
      title="Please enter a valid phone number in the format +1 (XXX) XXX-XXXX"
    />
  </PhoneNumberRow>;
};

export default PhoneNumber;
