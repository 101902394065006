import styled from "styled-components";
import VerticalMenu from "../../Content/common/VerticalMenu";
import ProfileDetailsPreview from "./ProfileDetails/ProfileDetailsPreview";

const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-left: ${({ theme }) => theme.spacing.xxlarge};
  margin-right: ${({ theme }) => theme.spacing.xxlarge};
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.small};
`;

var options = [
    {
        title: "Account",
        items: [
            {
                title: "Account",
                linkTo: "",
                component: <ProfileDetailsPreview Profile={{ FirstName: "Corey", LastName: "James" }} />
            }
        ]
    },
    {
        title: "Setting",
        items: [
            {
                title: "Time Logs",
                linkTo: "",
            },
            {
                title: "Mileage",
                linkTo: "",
            },
            {
                title: "Notifications",
                linkTo: "",
            },
            {
                title: "Location",
                linkTo: "",
            }
        ]
    },
];


const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const DeleteButton = styled.button`
    background-color: red;
    border: none;
    border-radius: 10px;
    padding: 10px;
    color: white;
`;

const Profile = props => {
    return <CompanyContainer>
        <VerticalMenu options={options} />
        <ButtonContainer>
            <DeleteButton type='button'>Log Out</DeleteButton>
        </ButtonContainer>
    </CompanyContainer>;
};

export default Profile;