import { Link } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';


const ListItem = styled.div`

`;

const IconWrapper = styled.span`
`;

const Title = styled.p`
  padding: 0;
  margin: 0;
  text-align: left;
`;

const LinkContainer = styled(Link)`
  height: fit-content;
  padding: ${({ theme }) => theme.spacing.small};
  margin: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.defaultText};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default function MenuItem({ title, linkTo, component }) {
    return <LinkContainer to={linkTo}>
        {component ?
            component
            : <ListItem>
                <Title>{title}</Title>
            </ListItem>}
        <IconWrapper>
            <FontAwesomeIcon icon={faChevronRight} />
        </IconWrapper>
    </LinkContainer>;
};