import { useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { API_ADDRESS } from '../../apollo/apollo.config';

// Custom hook to send data to a REST API using axios and authentication token
const useApi = () => {
  // Get the API URL from an environment variable
  const apiUrl = API_ADDRESS;

  // Get the authentication token from useAuth0
  const { getAccessTokenSilently } = useAuth0();

  // Initialize state for the response and error
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  // Function to send data to the REST API
  const sendRequest = async (url, method, data) => {
    try {
      // Get the authentication token
      const token = await getAccessTokenSilently();

      // Set the headers for the request
      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      };

      // Send the request to the server using axios
      const response = await axios({
        method,
        url: `${apiUrl}${url}`,
        headers,
        data,
      });

      // Set the response state to the server response data
      setResponse(response.data);
      setError(null);
      return { data: response.data, error: null };
    } catch (error) {
      // Set the error state to the error object
      setResponse(null);
      setError(error);
      return { data: null, error: error };
    }
  };

  // Return an object with the sendRequest function, response state, and error state
  return { sendRequest, response, error };
};

export default useApi;
