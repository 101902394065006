import { Outlet } from "react-router-dom";
import styled from "styled-components";

const AdminContainer = styled.div`
  height: 100vh;
`;


const Admin = props => {
  return <AdminContainer>
    <Outlet />
  </AdminContainer>;
};

export default Admin;