import React, { createContext, useState } from 'react';

export const ErrorContext = createContext({
    handleError: (error) => { },
    clearError: () => { },
    error: null,
});

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);

    const handleError = (error) => {
        // Here you might want to distinguish between error types
        // and handle them accordingly
        console.error(error);
        setError(error);
    };

    const clearError = () => {
        setError(null);
    };

    return (
        <ErrorContext.Provider value={{ handleError, clearError, error }}>
            {children}
        </ErrorContext.Provider>
    );
};
