import { gql } from "@apollo/client";

export const ALERT_FRAGMENT = gql`
    fragment AlertFields on Alert {
        Status
        Name
        Type
        Description
        _id
        createdAt
        updatedAt
    }
`;
