import { gql } from "@apollo/client";

export const initGraphQL = gql`
    query initAlertGraphQL($id: MongoID!) {
        AlertFindById(_id: $id) {
            Status
            Name
            Type
            Description
            _id
        }
    }
`;