import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: sans-serif; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    height: 100%;
    font-size: 16px;
    color: #2A2A2A;
    background-color: #E0E0E0;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }
  button, 
  select, 
  input,
  label,
  textarea,
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  a,
  ul,
  ol,
  li
  strong,
  em {
    font-family: 'Montserrat', sans-serif;
  }

  select, 
  input,
  textarea {
    border: 1px solid #333333;
  }

`;