import { useContext, useState } from "react";
import { CalendarContext } from "../CalendarContext";
import styled from "styled-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Day from "./Day";
import Week from "./Week";
import Modal from "../../../Content/Modal";
import CalendarHeader from "./CalendarHeader";
import Month from "./Month";
import Year from "./Year";

const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  min-width: 0;
  box-sizing: border-box;
`;

function CalendarBody() {
    const { calendarState, setCalendarState } = useContext(CalendarContext);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState({});

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        handleOpenModal();
    };

    const handleCalendarState = (state) => {
        setCalendarState((prevValue) => {
            return {
                ...prevValue,
                ...state
            };
        });
    };

    return (
        <Wrapper>
            <Modal isOpen={isModalOpen} onClose={handleCloseModal} hideCloseButton={false}>
                <div>Create Event {selectedEvent?.name}</div>
            </Modal>
            <CalendarHeader />
            {calendarState.activeView === 'day' && <Day handleSelectEvent={handleSelectEvent} />}
            {calendarState.activeView === 'week' && <Week handleSelectEvent={handleSelectEvent} />}
            {calendarState.activeView === 'month' && <Month handleSelectEvent={handleSelectEvent} />}
            {calendarState.activeView === 'year' && <Year handleSelectEvent={handleSelectEvent} />}
        </Wrapper>
    );
}

export default CalendarBody;