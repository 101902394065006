import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';
import { useCallback } from 'react';
import { useFormContext } from '../../../providers/FormProvider/FormProvider';
// mime.lookup()
const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column-reverse;
    gap: ${({ theme }) => theme.spacing.medium};
  }
`;

const ProfilePictureWrapper = styled.div`
  display: flex;
  overflow: hidden;
`;

const ProfilePictureImage = styled.img`
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  object-position: center;
  ${({ isEditing }) => isEditing && (`cursor: pointer;`)};
`;

const PlaceholderIcon = styled(FontAwesomeIcon)`
  width: 200px;
  height: 200px;
  width: fit-content;
  font-size: 120px;
  margin-bottom: ${({ theme }) => theme.spacing.medium};
  border-radius: 50%;
  ${({ isEditing }) => isEditing === 'true' && (`cursor: pointer;`)};
`;

const EditIcon = styled(FontAwesomeIcon)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const RemoveIcon = styled(FontAwesomeIcon)`
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const FileInput = styled.input`
  display: none;
`;

const EditOptions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  gap: ${({ theme }) => theme.spacing.medium};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row-reverse;
    align-items: center;
  }
`;

const UploadButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.defaultText};
  background-color: ${({ theme }) => theme.colors.third};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: none;
  padding: ${({ theme }) => theme.spacing.xxxsmall};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

const RemoveButton = styled.button`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: ${({ theme }) => theme.colors.defaultText};
  background-color: ${({ theme }) => theme.colors.red};
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  border: none;
  padding: ${({ theme }) => theme.spacing.xxxsmall};
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.small};
  }
`;

const IconLabel = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-wrap: break-word;
  word-break: break-word;
  word-wrap: break-word;
`;


const ProfilePicture = ({
  index = undefined,
  type = 'text',
  label = '',
  name = '',
  example = '',
  placeholder = '',
  ariaLabel = '',
  ariaDescribedby = '',
  autoComplete = '',
  required = false,
  formatInput = (value) => value,
  ...props
}) => {
  const { state, dispatch } = useFormContext();
  const [key, setKey] = useState(Date.now());
  const [displayedImageUrl, setDisplayedImageUrl] = useState();

  const handleInputChange = useCallback((e) => {
    e.preventDefault();
    const file = e.target.files;
    if (file && file[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setKey(Date.now());
        // setDisplayedImageUrl(reader.result);
        dispatch({
          type: 'UPDATE_FORM_INPUT', payload: {
            key: name,
            value: { raw: file[0], ...getFormValue(state?.formInputs, name, index) },
            index
          }
        });
      };
      const fileType = file[0].type;
      if (fileType === 'image/jpeg' || fileType === 'image/png') {
        reader.readAsDataURL(file[0]);
      }
    }
  }, [dispatch, state?.formInputs, name, index]);

  useEffect(() => {
    const image = getFormValue(state?.formInputs, name, index);
    if (image?.raw) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // setKey(Date.now());
        setDisplayedImageUrl(reader.result);
      };
      const fileType = image?.raw?.type;
      if (fileType === 'image/jpeg' || fileType === 'image/png') {
        reader.readAsDataURL(image?.raw);
      }
    } else {
      setDisplayedImageUrl(image?.signedUrl);
    }
  }, [state, displayedImageUrl, name, index])


  const handleRemoveClick = () => {
    setKey(Date.now());
    setDisplayedImageUrl(null);
    dispatch({ type: 'UPDATE_FORM_INPUT', payload: { key: name, value: null, index } });
  };

  const handleImageClick = () => {
    if (!state?.isEditing) return;
    const fileInput = document.getElementById(`file-input-${name}`);
    fileInput.click();
  }

  const handleEditClick = () => {
    if (!state?.isEditing) return;
    const fileInput = document.getElementById(`file-input-${name}`);
    fileInput.click();
  }

  return (
    <ProfilePictureContainer>
      {state?.isEditing && (
        <EditOptions>
          <UploadButton type='button' onClick={handleEditClick}>
            <EditIcon icon={faEdit} />
            <IconLabel>Upload New Picture</IconLabel>
          </UploadButton>
          <RemoveButton type='button' onClick={handleRemoveClick}>
            <RemoveIcon icon={faTrash} />
            <IconLabel>Remove Current Picture</IconLabel>
          </RemoveButton>
        </EditOptions>
      )}
      <ProfilePictureWrapper>
        {displayedImageUrl ? (
          <ProfilePictureImage
            isEditing={state?.isEditing}
            src={displayedImageUrl}
            alt="Profile"
            onClick={handleImageClick}
          />
        ) : (
          <PlaceholderIcon isEditing={state?.isEditing ? 'true' : 'false'} icon={faUserCircle} onClick={handleImageClick} />
        )}
      </ProfilePictureWrapper>
      <FileInput
        type="file"
        id={`file-input-${name}`}
        name={name}
        onChange={handleInputChange}
        key={key}
      />
    </ProfilePictureContainer>
  );
};

export default ProfilePicture;