import styled from "styled-components";
import Sidebar from "./Sidebar";
import { useState } from "react";
import ChatRoom from "./ChatRoom";


const Wrapper = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
`;

const TwoColumn = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.spacing.small};
  gap: ${({ theme }) => theme.spacing.small};
`;

const ColumnA = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  height: 100%;
  width: 100%;
`;

const ColumnB = styled.div`
  flex: 3;
  height: 100%;
  width: 100%;
`;


const Chat = (props) => {
  const [chatSelection, setChatSelection] = useState('Name 1');

  const handleSelection = (name) => {
    setChatSelection(name)
  }


  const treeData = [
    {
      name: 'Company',
      // items: ['Name 1',],
      children: [{ name: 'General' }, { name: 'Announcments' }, { name: 'Team' }],
    },
    {
      name: 'JobSites',
      children: [{ name: 'CLINT-SITE', children: [{ name: 'General' }, { name: 'Project 1' }, { name: 'Project 2' }] }],
    },
    {
      name: 'Direct Messages',
      children: [{ name: 'Corey James' }],
    },
  ];

  const testData = [
    {
      id: 1,
      content: "Hello!",
      sender: "Me",
      timestamp: "9:00 AM",
      attachments: []
    },
    {
      id: 2,
      content: "Hi there!",
      sender: "Friend",
      timestamp: "9:05 AM",
      attachments: []
    },
    {
      id: 3,
      content: "Check out this image",
      sender: "Friend",
      timestamp: "9:07 AM",
      attachments: [
        { id: 1, type: "image", url: "https://picsum.photos/id/237/200/300" }
      ]
    },
    {
      id: 4,
      content: "Sure, here's a PDF file",
      sender: "Me",
      timestamp: "9:10 AM",
      attachments: [
        { id: 2, type: "image", url: "https://picsum.photos/id/237/200/300" }
      ]
    }
  ];

  return <Wrapper>
    <TwoColumn>
      <ColumnA>
        <Sidebar data={treeData} handleSelection={handleSelection} />
      </ColumnA>
      <ColumnB>
        <ChatRoom data={testData}/>
      </ColumnB>
    </TwoColumn>
  </Wrapper>;
};

export default Chat