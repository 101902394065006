import styled from "styled-components";
import FormBuilder from "../../FormBuilder";
import { profileFormConfig } from "../../../documentConfigs/profile/profile.form";

const ProfileDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const Profile = ({ profile = {}, closeModal = () => { }, isNewDocument }) => {
    return <ProfileDetailsWrapper>
        <FormBuilder
            documentId={profile?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            {...profileFormConfig}
        />
    </ProfileDetailsWrapper>;
};

export default Profile;