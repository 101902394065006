import React, { forwardRef } from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  min-height: 100%;
  aria-live: polite;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(301px, 1fr));
  gap: ${({ theme }) => theme.spacing.xxsmall};
  justify-items: start;
  box-sizing: border-box;
`;

const Grid = forwardRef(({ items, GridItem }, ref) => {
  return <GridContainer ref={ref}>
    {items.map((data, index) => (
      <GridItem key={index} data={data} index={index} />
    ))}
  </GridContainer>
});

export default Grid;
