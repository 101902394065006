import { filter } from 'lodash';
import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export default function useModal() {
  return useContext(ModalContext);
};

export const ModalProvider = ({ children }) => {
  const [modals, setModals] = useState([]);

  const openModal = (content, props) => {
    setModals((prevModals) => [...prevModals, { content, isOpen: true, ...props }]);
  };

  const closeModal = (index) => {
    setModals((prevModals) => {
      return filter(prevModals, (_, i) => i !== index);
    });
  };

  const closeAllModals = () => {
    setModals([]);
  };

  return (
    <ModalContext.Provider value={{ modals, openModal, closeModal, closeAllModals }}>
      {children}
    </ModalContext.Provider>
  );
};
