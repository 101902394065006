export const validation = {
    required: {
        'Status': false,
        'Managers': true,
        'Priority': false,
        'Name': true,
        'Type': true,
        'Description': true,
        'TargetStartDate': true,
        'TargetEndDate': true,
    },
    validators: {
        // 'Managers': async (value) => {
        //     return { valid: false, error: 'field not valid' };
        // }
    }
};