
import { useQuery } from "@apollo/client";
import BackButton from "../../../Content/BackButton";
import PlusButton from "../../../Content/PlusButton";
import PagedList from "../../../Content/common/PagedList";
import styled from "styled-components";
import ClientPreview from "./ClientPreview";
import * as querys from "../../../../graphql/querys";
import useModal from "../../../../hooks/useModal";
import Client from "../../../Documents/Client/Client";


const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

function Clients() {
    const { data: { ClientFindMany } = {} } = useQuery(querys.ClientFindMany);
    const { openModal } = useModal();

    const handleOpenModal = (client, options = {}) => {
        openModal(<Client
            client={client}
            {...options}
        />)
    };

    return <Wrapper>
        <ButtonWrapper>
            <BackButton />
            <PlusButton onClick={() => handleOpenModal({}, { isNewDocument: true })} />
        </ButtonWrapper>
        <PagedList data={ClientFindMany} ListItem={ClientPreview} handleClick={handleOpenModal} itemsPerPage={10} searchable={true} />
    </Wrapper>;
};

export default Clients;