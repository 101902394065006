import { gql } from "@apollo/client";
import { PROFILE_FRAGMENT_LOOKUP_FIELDS } from "../../graphql/Fragments/Profile";

export const initGraphQL = gql`
    query initProjectGraphQL($id: MongoID!) {
        ProjectFindById(_id: $id) {
            Managers {
                _id
            }
            Status
            Priority
            Name
            Type
            Description
            TargetStartDate
            TargetEndDate
            _id
        }
        ProfileFindMany {
            VCard {
                ContactName
                _id
            }
            _id
        }
    }
`;