
import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComment, faComments, faPlus, faUserGroup } from "@fortawesome/free-solid-svg-icons";

const PlusButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.xxxxsmall};
  color: ${({ theme }) => theme.colors.defaultText};
  font-size: ${({ theme }) => theme.fontSizes.small};
  gap: 20px;

  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const PlusButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 0.5rem;
  font-size: ${({ theme }) => theme.fontSizes.medium};
`;

const CommentButton = ({ onClick }) => {

    return (
        <PlusButtonWrapper onClick={onClick}>
            <PlusButtonIcon icon={faComment} />
        </PlusButtonWrapper>
    );
};

export default CommentButton;
