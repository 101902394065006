import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.medium};
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  /* padding: ${({ theme }) => theme.spacing.medium}; */
  max-height: fit-content;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.medium};

  @media (max-width: 768px) {
  /* padding: ${({ theme }) => theme.spacing.medium} 0px; */
  }
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const FormButtonsEditing = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const FormButtonsReadOnly = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const FormButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  text-decoration: none;
  color:${({ theme }) => theme.colors.defaultText};
  ${({ theme, action }) => action === 'cancel' && `color: ${theme.colors.red}`};
  ${({ theme, action }) => action === 'submit' && `color: ${theme.colors.secondary}`};


  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

export const FieldAddButton = styled.button`
  display: flex;
  align-items: center;
  padding: 8px;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  border-radius: 8px;
  background-color: #FFF;

  &:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    border-radius: 0px;
  }
`;


export const FieldAddButtonIcon = styled(FontAwesomeIcon)`
  margin-right: 8px;
  font-size: 20px;
  color: green;
`;

export const FieldAddButtonLabel = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  
  ${({ hideBackgroundColor, theme }) => !hideBackgroundColor && css`
    background-color: ${theme.colors.white};
  `};
  
  ${({ fieldSet, theme }) => !fieldSet && css`
    border-radius: ${theme.borderRadius.medium};
    margin-bottom: ${theme.spacing.medium};
    padding: ${theme.spacing.medium};
  `};
  
  @media (max-width: 768px) {
    border-radius: 0px;
  }
  
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
`;

export const FormFieldSet = styled.div`
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  background-color: ${({ theme }) => theme.colors.white};
  gap: ${({ theme }) => theme.spacing.small};
  padding: ${({ theme }) => theme.spacing.medium};
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: ${({ theme }) => theme.spacing.small};
  }
`;

export const FieldSetLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  font-size: ${({ theme }) => theme.fontSizes.large};
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
`;
