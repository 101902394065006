import { gql } from "@apollo/client";
import { TASK_FRAGMENT } from "./Task";
import { PROFILE_FRAGMENT } from "./Profile";

export const PROJECT_FRAGMENT = gql`
    ${TASK_FRAGMENT}
    ${PROFILE_FRAGMENT}
    fragment ProjectFields on Project {
        JobSite
        Tasks {
            ...TaskFields
        }
        Managers {
            ...ProfileFields
        }
        Status
        Priority
        Name
        Type
        Description
        TargetStartDate
        TargetEndDate
        _id
        createdAt
        updatedAt
    }
`;
