// src/hooks/useForm/useForm.js
import { useMutation, useQuery } from '@apollo/client';
import { isEmpty, isEqual } from 'lodash';
import { useContext, useEffect, useRef } from 'react';
import { AppConfigContext } from '../../config/config';
import * as mutations from '../../graphql/mutations';
import * as querys from '../../graphql/querys';
import { useFormContext } from '../../providers/FormProvider/FormProvider';
import useApi from '../useApi/useApi';
import { handleFormSubmit } from './useForm.submit';
import { handleFormValidation } from './useForm.validator';

export default function useForm({
    documentName,
    documentId,
    validation,
    initGraphQL,
}) {
    /* Context */
    const { appConfig } = useContext(AppConfigContext);

    /* Reducer */
    const { state, dispatch } = useFormContext();

    /* GRAPHQL */
    // Querys
    const {
        data = {}, error, loading
    } = useQuery(initGraphQL, {
        variables: { id: documentId ? documentId : "" },
        fetchPolicy: 'network-only',
    });
    if (error) {
        dispatch({ type: 'SET_ERRORS', payload: error });
    }

    // Mutations
    const [createOne] = useMutation(mutations[`${documentName}CreateOne`]);
    const [updateOne] = useMutation(mutations[`${documentName}UpdateOne`]);
    const [removeFindById] = useMutation(mutations[`${documentName}RemoveFindById`]);

    /* API */
    const { sendRequest } = useApi();
    /* Use Effect Hooks */
    useEffect(() => {
        if (loading || error) return;
        const { [`${documentName}FindById`]: formInputs, ...localState } = data || {};

        dispatch({
            type: 'SET_FORM_INPUTS', payload: {
                formInputs,
            },
        });

        for (const key in localState) {
            dispatch({
                type: 'UPDATE_LOCAL_FORM_STATE', payload: {
                    [key]: localState[key],
                },
            });
        }
    }, [data, loading, error, documentName, dispatch]);

    const submitForm = async () => {
        try {
            // Locally Validate 
            await handleFormValidation(state);
            // Submit Values
            await handleFormSubmit({
                documentName,
                state,
                dispatch,
                documentMany: querys[`${documentName}Many`],
                createOne,
                updateOne,
                removeFindById,
                initGraphQL,
                sendRequest,
                appConfig,
            });
            await dispatch({ type: 'SUBMIT_FORM_SUCCESS' });
        } catch (errors) {
            dispatch({ type: 'SUBMIT_FORM_FAILED' });
            dispatch({ type: 'SET_ERRORS', payload: errors });
        }
    }

    // Returns
    return {
        state,
        dispatch,
        loading,
        submitForm,
    };
}
