import styled from "styled-components";
import SearchBar from "../SearchBar";
import { useState } from "react";
import Pagination from "../Pagination";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  min-height: 100%;
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const Row = styled.div`
  flex: 1;
`;

const ListWrapper = styled.div`
  flex: 10;
`;

const ListRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.medium};
  gap: ${({ theme }) => theme.spacing.small};
`;

const PaginationRow = styled.div`
  display: flex;
  min-width: 100%;
  align-self: center;
  justify-content: center;
`;

function PagedList({ data = [], ListItem = <div />, handleClick, itemsPerPage = 1, searchable = true }) {
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    let filteredData;
    if (searchable) {
        filteredData = data.filter(item => {
            const allValues = Object.values(item);
            const combinedString = allValues.join(' ').toLowerCase();
            return combinedString.includes(searchValue.toLowerCase());
        });
    } else {
        filteredData = data;
    }

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const pagedItems = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    return <Wrapper>
        {searchable &&
            <>
                <Row>
                    <SearchBar
                        searchValue={searchValue}
                        onSearchChange={(e) => setSearchValue(e.target.value)}
                    />
                </Row>
                <Row>
                    <h2>Recent:</h2>
                </Row>
            </>
        }
        <ListWrapper>
            <ListRow>
                {pagedItems?.map((item, index) => {
                    return <ListItem
                        key={`list-item-${index}`}
                        item={item}
                        handleClick={handleClick} />
                })}
            </ListRow>
        </ListWrapper>
        <Row>
            <PaginationRow>
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                />
            </PaginationRow>
        </Row>
    </Wrapper>;
};

export default PagedList;