import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { getFormValue } from '../../../hooks/useForm/useForm.utils';
import { useFormContext } from '../../../providers/FormProvider/FormProvider';

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  flex: 1;
  height: fit-content;
`;

const FieldLabel = styled.label`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
`;

const FieldSelect = styled.select`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;

  /* Display style settings */
  border: 1px solid ${({ theme }) => theme.colors.mediumGrey};
  border-radius: ${({ theme }) => theme.spacing.xxxxxsmall};
  transition: border-color 0.2s ease-in-out;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};

  /* Display settings */
  padding: ${({ theme }) => theme.spacing.xxxsmall} ${({ theme }) => theme.spacing.xxxxsmall};
  box-sizing: border-box;
  ${({ readOnly }) => readOnly ? 'pointer-events: none; user-select: text;' : ''};
  border-color: ${({ readOnly, theme }) => readOnly ? theme.borderColor.disabled : theme.borderColor.default};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.defaultText};
  height: ${({ theme }) => theme.spacing.xxlarge};
  max-height: ${({ theme }) => theme.spacing.xxlarge};
  
  &:focus {
      border-color: ${({ theme }) => theme.borderColor.focus};
      outline-color: ${({ theme }) => theme.borderColor.outline};
  }
`;

const FieldExample = styled.span`
  /* Text settings */
  text-overflow: ellipsis;
  overflow: hidden;
  font-style: ${({ theme }) => theme.fontStyles.italic};
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.disabledText};
`;

const Timezone = React.memo(({
    index = undefined,
    label = '',
    name = '',
    example = '',
    placeholder = '',
    ariaLabel = '',
    ariaDescribedby = '',
    autoComplete = '',
    required = false,
}) => {
    const { state, dispatch } = useFormContext();
    const [value, setValue] = useState('');
    const handleInputChange = useCallback((event) => {
        const selectedOption = event.target.options[event.target.selectedIndex];
        const dataName = selectedOption.getAttribute('data-name');

        dispatch({
            type: 'UPDATE_FORM_INPUT', payload: {
                key: name,
                value: { TimezoneName: dataName, Offset: event.target.value }
            }
        })
    }, [dispatch, name]);

    useEffect(() => {
        const inputValue = getFormValue(state?.formInputs, name, index)?.Offset;
        setValue(inputValue);
    }, [state?.formInputs, name, index]);

    return <FieldWrapper>
        <FieldLabel readOnly={!state?.isEditing} htmlFor={`field-${label.toLowerCase()}-${index}`}>{label}</FieldLabel>
        <FieldSelect
            id={`field-${label.toLowerCase()}-${index}`}
            readOnly={!state?.isEditing}
            name={name}
            type="text"
            value={value || ''}
            onChange={handleInputChange}
            placeholder={placeholder}
            autoComplete={autoComplete}
            required={required}
            aria-readonly={!state?.isEditing}
            aria-label={ariaLabel}
            aria-describedby={ariaDescribedby}
        >
            <option value="" data-name="">
                Select Time Zone
            </option>
            <option value="-12:00" data-name="International Date Line West">
                (UTC-12:00) International Date Line West
            </option>
            <option value="-11:00" data-name="Coordinated Universal Time-11">
                (UTC-11:00) Coordinated Universal Time-11
            </option>
            <option value="-10:00" data-name="Hawaii">
                (UTC-10:00) Hawaii
            </option>
            <option value="-09:30" data-name="Marquesas Islands">
                (UTC-09:30) Marquesas Islands
            </option>
            <option value="-09:00" data-name="Alaska">
                (UTC-09:00) Alaska
            </option>
            <option value="-08:00" data-name="Pacific Time (US & Canada)">
                (UTC-08:00) Pacific Time (US & Canada)
            </option>
            <option value="-07:00" data-name="Arizona">
                (UTC-07:00) Arizona
            </option>
            <option value="-07:00" data-name="Mountain Time (US & Canada)">
                (UTC-07:00) Mountain Time (US & Canada)
            </option>
            <option value="-06:00" data-name="Central America">
                (UTC-06:00) Central America
            </option>
            <option value="-06:00" data-name="Central Time (US & Canada)">
                (UTC-06:00) Central Time (US & Canada)
            </option>
            <option value="-05:00" data-name="Eastern Time (US & Canada)">
                (UTC-05:00) Eastern Time (US & Canada)
            </option>
            <option value="-05:00" data-name="Indiana (East)">
                (UTC-05:00) Indiana (East)
            </option>
            <option value="-04:00" data-name="Atlantic Time (Canada)">
                (UTC-04:00) Atlantic Time (Canada)
            </option>
            <option value="-04:00" data-name="Caracas">
                (UTC-04:00) Caracas
            </option>
            <option value="-04:00" data-name="Georgetown, La Paz, Manaus, San Juan">
                (UTC-04:00) Georgetown, La Paz, Manaus, San Juan
            </option>
            <option value="-03:30" data-name="Newfoundland">
                (UTC-03:30) Newfoundland
            </option>
            <option value="-03:00" data-name="Brasilia">
                (UTC-03:00) Brasilia
            </option>
            <option value="-03:00" data-name="Buenos Aires">
                (UTC-03:00) Buenos Aires
            </option>
            <option value="-03:00" data-name="Greenland">
                (UTC-03:00) Greenland
            </option>
            <option value="-02:00" data-name="Mid-Atlantic">
                (UTC-02:00) Mid-Atlantic
            </option>
            <option value="-01:00" data-name="Azores">
                (UTC-01:00) Azores
            </option>
            <option value="-01:00" data-name="Cape Verde Is.">
                (UTC-01:00) Cape Verde Is.
            </option>
            <option value="+00:00" data-name="Casablanca">
                (UTC+00:00) Casablanca
            </option>
            <option value="+00:00" data-name="Dublin, Edinburgh, Lisbon, London">
                (UTC+00:00) Dublin, Edinburgh, Lisbon, London
            </option>
            <option value="+00:00" data-name="Monrovia, Reykjavik">
                (UTC+00:00) Monrovia, Reykjavik
            </option>
            <option value="+01:00" data-name="Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna">
                (UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna
            </option>
            <option value="+01:00" data-name="Belgrade, Bratislava, Budapest, Ljubljana, Prague">
                (UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague
            </option>
            <option value="+01:00" data-name="Brussels, Copenhagen, Madrid, Paris">
                (UTC+01:00) Brussels, Copenhagen, Madrid, Paris
            </option>
            <option value="+01:00" data-name="Sarajevo, Skopje, Warsaw, Zagreb">
                (UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb
            </option>
            <option value="+01:00" data-name="West Central Africa">
                (UTC+01:00) West Central Africa
            </option>
            <option value="+02:00" data-name="Amman">
                (UTC+02:00) Amman
            </option>
            <option value="+02:00" data-name="Athens, Bucharest">
                (UTC+02:00) Athens, Bucharest
            </option>
            <option value="+02:00" data-name="Beirut">
                (UTC+02:00) Beirut
            </option>
            <option value="+02:00" data-name="Cairo">
                (UTC+02:00) Cairo
            </option>
            <option value="+02:00" data-name="Damascus">
                (UTC+02:00) Damascus
            </option>
            <option value="+02:00" data-name="Harare, Pretoria">
                (UTC+02:00) Harare, Pretoria
            </option>
            <option value="+02:00" data-name="Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius">
                (UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius
            </option>
            <option value="+02:00" data-name="Jerusalem">
                (UTC+02:00) Jerusalem
            </option>
            <option value="+02:00" data-name="Minsk">
                (UTC+02:00) Minsk
            </option>
            <option value="+02:00" data-name="Windhoek">
                (UTC+02:00) Windhoek
            </option>
            <option value="+03:00" data-name="Baghdad">
                (UTC+03:00) Baghdad
            </option>
            <option value="+03:00" data-name="Kuwait, Riyadh">
                (UTC+03:00) Kuwait, Riyadh
            </option>
            <option value="+03:00" data-name="Moscow, St. Petersburg, Volgograd">
                (UTC+03:00) Moscow, St. Petersburg, Volgograd
            </option>
            <option value="+03:00" data-name="Nairobi">
                (UTC+03:00) Nairobi
            </option>
            <option value="+03:30" data-name="Tehran">
                (UTC+03:30) Tehran
            </option>
            <option value="+04:00" data-name="Abu Dhabi, Muscat">
                (UTC+04:00) Abu Dhabi, Muscat
            </option>
            <option value="+04:00" data-name="Baku">
                (UTC+04:00) Baku
            </option>
            <option value="+04:00" data-name="Port Louis">
                (UTC+04:00) Port Louis
            </option>
            <option value="+04:00" data-name="Tbilisi">
                (UTC+04:00) Tbilisi
            </option>
            <option value="+04:00" data-name="Yerevan">
                (UTC+04:00) Yerevan
            </option>
            <option value="+04:30" data-name="Kabul">
                (UTC+04:30) Kabul
            </option>
            <option value="+05:00" data-name="Ashgabat, Tashkent">
                (UTC+05:00) Ashgabat, Tashkent
            </option>
            <option value="+05:00" data-name="Ekaterinburg">
                (UTC+05:00) Ekaterinburg
            </option>
            <option value="+05:00" data-name="Islamabad, Karachi">
                (UTC+05:00) Islamabad, Karachi
            </option>
            <option value="+05:30" data-name="Chennai, Kolkata, Mumbai, New Delhi">
                (UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi
            </option>
            <option value="+05:30" data-name="Sri Jayawardenepura">
                (UTC+05:30) Sri Jayawardenepura
            </option>
            <option value="+05:45" data-name="Kathmandu">
                (UTC+05:45) Kathmandu
            </option>
            <option value="+06:00" data-name="Astana">
                (UTC+06:00) Astana
            </option>
            <option value="+06:00" data-name="Dhaka">
                (UTC+06:00) Dhaka
            </option>
            <option value="+06:30" data-name="Yangon (Rangoon)">
                (UTC+06:30) Yangon (Rangoon)
            </option>
            <option value="+07:00" data-name="Bangkok, Hanoi, Jakarta">
                (UTC+07:00) Bangkok, Hanoi, Jakarta
            </option>
            <option value="+07:00" data-name="Krasnoyarsk">
                (UTC+07:00) Krasnoyarsk
            </option>
            <option value="+08:00" data-name="Beijing, Chongqing, Hong Kong SAR, Urumqi">
                (UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi
            </option>
            <option value="+08:00" data-name="Irkutsk">
                (UTC+08:00) Irkutsk
            </option>
            <option value="+08:00" data-name="Kuala Lumpur, Singapore">
                (UTC+08:00) Kuala Lumpur, Singapore
            </option>
            <option value="+08:00" data-name="Perth">
                (UTC+08:00) Perth
            </option>
            <option value="+08:00" data-name="Taipei">
                (UTC+08:00) Taipei
            </option>
            <option value="+08:00" data-name="Ulaanbaatar">
                (UTC+08:00) Ulaanbaatar
            </option>
            <option value="+08:45" data-name="Eucla">
                (UTC+08:45) Eucla
            </option>
            <option value="+09:00" data-name="Chita">
                (UTC+09:00) Chita
            </option>
            <option value="+09:00" data-name="Osaka, Sapporo, Tokyo">
                (UTC+09:00) Osaka, Sapporo, Tokyo
            </option>
            <option value="+09:00" data-name="Seoul">
                (UTC+09:00) Seoul
            </option>
            <option value="+09:00" data-name="Yakutsk">
                (UTC+09:00) Yakutsk
            </option>
            <option value="+09:30" data-name="Adelaide">
                (UTC+09:30) Adelaide
            </option>
            <option value="+09:30" data-name="Darwin">
                (UTC+09:30) Darwin
            </option>
            <option value="+10:00" data-name="Brisbane">
                (UTC+10:00) Brisbane
            </option>
            <option value="+10:00" data-name="Canberra, Melbourne, Sydney">
                (UTC+10:00) Canberra, Melbourne, Sydney
            </option>
            <option value="+10:00" data-name="Guam, Port Moresby">
                (UTC+10:00) Guam, Port Moresby
            </option>
            <option value="+10:00" data-name="Hobart">
                (UTC+10:00) Hobart
            </option>
            <option value="+10:00" data-name="Vladivostok">
                (UTC+10:00) Vladivostok
            </option>
            <option value="+10:30" data-name="Lord Howe Island">
                (UTC+10:30) Lord Howe Island
            </option>
            <option value="+11:00" data-name="Bougainville Island">
                (UTC+11:00) Bougainville Island
            </option>
            <option value="+11:00" data-name="Chokurdakh">
                (UTC+11:00) Chokurdakh
            </option>
            <option value="+11:00" data-name="Magadan">
                (UTC+11:00) Magadan
            </option>
            <option value="+11:00" data-name="Norfolk Island">
                (UTC+11:00) Norfolk Island
            </option>
            <option value="+11:00" data-name="Sakhalin">
                (UTC+11:00) Sakhalin
            </option>
            <option value="+11:00" data-name="Solomon Is., New Caledonia">
                (UTC+11:00) Solomon Is., New Caledonia
            </option>
            <option value="+12:00" data-name="Anadyr, Petropavlovsk-Kamchatsky">
                (UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky
            </option>
            <option value="+12:00" data-name="Auckland, Wellington">
                (UTC+12:00) Auckland, Wellington
            </option>
            <option value="+12:00" data-name="Fiji">
                (UTC+12:00) Fiji
            </option>
            <option value="+12:00" data-name="Kamchatka">
                (UTC+12:00) Kamchatka
            </option>
            <option value="+12:45" data-name="Chatham Islands">
                (UTC+12:45) Chatham Islands
            </option>
            <option value="+13:00" data-name="Nuku'alofa">
                (UTC+13:00) Nuku'alofa
            </option>
            <option value="+13:00" data-name="Samoa">
                (UTC+13:00) Samoa
            </option>
            <option value="+14:00" data-name="Kiritimati Island">
                (UTC+14:00) Kiritimati Island
            </option>
        </FieldSelect>
        <FieldExample>{example}</FieldExample>
    </FieldWrapper>
});

export default Timezone;
