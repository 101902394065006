
import Text from "../../components/FormBuilder/fields/Text";
import Select from "../../components/FormBuilder/fields/Select";
import SelectLookup from "../../components/FormBuilder/fields/SelectLookup";
import TextArea from "../../components/FormBuilder/fields/TextArea";
import { get, find, map } from "lodash";

export const fields = [
    {
        label: 'Client',
        fieldSet: [
            {
                name: 'Client._id',
                type: SelectLookup,
                limit: 30,
                autoComplete: '',
                optionsConfig: {
                    loadFromState: (state) => {
                        const clients = get(state, 'localFormState.ClientFindMany');
                        return map(clients, (client) => {
                            return {
                                caption: get(client, 'VCard.ContactName'),
                                value: get(client, '_id'),
                            };
                        });
                    },
                },
            },
            {
                name: 'ClientAddressId',
                type: Select,
                limit: 100,
                autoComplete: '',
                optionsConfig: {
                    loadFromState: (state) => {
                        const clients = get(state, 'localFormState.ClientFindMany');
                        const client = find(clients, ['_id', get(state, 'formInputs.Client._id')]);
                        const clientAddresses = get(client, 'VCard.Addresses', []);
                        return map(clientAddresses, (address) => {
                            return {
                                caption: get(address, 'AddressString'),
                                value: get(address, '_id'),
                            };
                        });
                    },
                },
            },
        ],
    },
    {
        label: 'Details',
        fieldSet: [
            {
                name: 'Name',
                type: Text,
                autoComplete: '',
            },
            {
                name: 'Code',
                type: Text,
                autoComplete: '',
                limit: 6,
            },
            {
                label: 'Type',
                name: 'Type',
                type: Select,
                optionsConfig: {
                    options: [
                        { value: 'LandScaping', caption: 'LandScaping' },
                        { value: 'New Construction', caption: 'New Construction' },
                        { value: 'Retrofit', caption: 'Retrofit' }
                    ],
                },
                example: '',
                placeholder: 'Select JobSite Type',
                ariaLabel: 'JobSite Type',
                ariaDescribedby: 'Select JobSite Type',
                limit: 30,
                autoComplete: '',
            },
            {
                label: 'Description',
                name: 'Description',
                type: TextArea,
                example: '',
                placeholder: 'Enter JobSite Description',
                ariaLabel: 'JobSite Description',
                ariaDescribedby: 'Enter JobSite Description',
                autoComplete: '',
            },
        ],
    },
];