import { gql } from "@apollo/client";
import { TENANTDETAILS_FRAGMENT } from "../Fragments/TenantDetails";

export const TenantDetailsFindOne = gql`
    ${TENANTDETAILS_FRAGMENT}
    query TenantDetailsFindOne($filter: FilterFindOneTenantDetailsInput) {
        TenantDetailsFindOne(filter: $filter) {
            ...TenantDetailsFields
        }
    }
`;

export const TenantDetailsFindById = gql`
    ${TENANTDETAILS_FRAGMENT}
    query TenantDetailsFindById($id: MongoID!) {
        TenantDetailsFindById(_id: $id) {
            ...TenantDetailsFields
        }
    }
`;

export const TenantDetailsFindMany = gql`
    ${TENANTDETAILS_FRAGMENT}
    query TenantDetailsFindMany($filter: FilterFindManyTenantDetailsInput, $skip: Int, $limit: Int, $sort: SortFindManyTenantDetailsInput) {
        TenantDetailsFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            ...TenantDetailsFields
        }
    }
`;
