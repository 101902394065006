import { gql } from "@apollo/client";

export const initGraphQL = gql`
    query initNoteGraphQL($id: MongoID!) {
        NoteFindById(_id: $id) {
            Name
            Type
            Description
            _id
        }
    }
`;