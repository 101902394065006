import ReactDOM from 'react-dom/client';
import React from 'react';
import { CookiesProvider } from "react-cookie";
import { Auth0Provider } from "@auth0/auth0-react";
import { GlobalStyle } from './components/StyledComponents/GlobalStyle';
import AppRoot from './components/AppRoot';
import './i18n';

const rootElement = document.getElementById('root');

ReactDOM.createRoot(rootElement).render(
  <>
  {/* <React.StrictMode> */}
      <GlobalStyle />
      <CookiesProvider>
        <Auth0Provider
          domain="dev-7toi8gkoa640zun1.us.auth0.com"
          clientId="8MJLB1hcToIrzwxGZbVRsHIZJTN0MBVs"
          authorizationParams={{
            audience: "https://dev-7toi8gkoa640zun1.us.auth0.com/api/v2/",
            scope: 'openid profile email read:current_user read:current_user_app_metadata',
            redirect_uri: window.location.origin
          }}
          cacheLocation="localstorage"
        >
          <AppRoot />
        </Auth0Provider>
      </CookiesProvider>
  {/* </React.StrictMode> */}
  </>
);
