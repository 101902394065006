import styled from 'styled-components';
import Text from './Text';
import Select from './Select';

const UrlRow = styled.fieldset`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  align-items: stretch;
  margin: 0;
  padding: 0;
  border: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

const Url = (props) => {
  return (<UrlRow>
    <Select
      {...props}
      name={`${props?.name}-WebsiteType`}
      example="Example: work, personal"
      placeholder="Select Website type"
      label={`Website Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`Website Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaDescribedby="Select Website type"
      autoComplete="website-type"
      options={props?.typeOptions?.keyOptions}
    />
    <Text
      {...props}
      type="url"
      label={`${props?.label} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`${props?.ariaLabel} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      name={`${props?.name}-WebsiteURL`}
      maxLength="255"
      title="Please enter a valid website"
    />
  </UrlRow>);
};

export default Url;
