import { gql } from "@apollo/client";

export const USER_ONE = gql`
    query UserFindOne($filter: FilterFindOneUserInput) {
        UserFindOne(filter: $filter) {
            Name
            Email
            ExternalType
            ExternalId
            SelectedTenant {
                _id
                User {
                    Email
                    Name
                    _id
                }
                Tenant {
                    Company {
                        ContactName
                        _id
                    }
                    _id
                }
                profile
            }
            RelatedTenants {
                Tenant {
                    _id
                    Company {
                        ContactName
                        _id
                    }
                }
                User {
                    Name
                    Email
                    _id
                }
                profile
                _id
                createdAt
                updatedAt
            }
            _id
            createdAt
            updatedAt
        }
    }
`;