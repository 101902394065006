import styled from "styled-components";
import QuotesPreview from "./preview.js";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.small};
`;

const JobSiteQuotes = ({ Quotes, Job }) => {
  return <Wrapper>
    {
      Quotes?.map((Quote, index) => {
        return <QuotesPreview
          key={`note-${index}`}
          Quotes={Quote}
          Job={Job} />
      })
    }
  </Wrapper>;
};

export default JobSiteQuotes;