import styled from 'styled-components';
import Text from './Text';
import Select from './Select';

const SocialProfilesRow = styled.fieldset`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.xlarge};
  margin-bottom: ${({ theme }) => theme.spacing.xxsmall};
  align-items: stretch;
  margin: 0;
  padding: 0;
  border: none;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
  }
`;

const SocialProfiles = (props) => {
  return <SocialProfilesRow>
    <Select
      {...props}
      name={`${props?.name}-SocialProfileType`}
      example="Example: FaceBook, LinkedIn"
      placeholder="Select Social profile"
      label={`Social profile Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`Social profile Type ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaDescribedby="Select Social profile type"
      autoComplete="social-profile-type"
      options={props?.typeOptions?.keyOptions}
    />
    <Text
      {...props}
      label={`${props?.label} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      ariaLabel={`${props?.ariaLabel} ${props?.index !== undefined ? props?.index + 1 : ''}`}
      name={`${props?.name}-SocialProfileURL`}
      title="Please enter a Social profile URL"
    />
  </SocialProfilesRow>;
};

export default SocialProfiles;
