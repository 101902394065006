import moment from 'moment';
import React, { createContext, useState } from 'react';

export const CalendarContext = createContext();

export const CalendarContextProvider = ({ children }) => {
    const [calendarState, setCalendarState] = useState({
        today: moment(),
        activeView: 'day',
        use24HourClock: false,
        events: [
            {
                name: 'Breakfast Meeting',
                startTime: moment().set({ hour: 8, minute: 0 }).subtract(2, 'days').toISOString(),
                endTime: moment().set({ hour: 9, minute: 30 }).subtract(2, 'days').toISOString()
            },
            {
                name: 'Team Sync-up',
                startTime: moment().set({ hour: 10, minute: 30 }).subtract(1, 'days').toISOString(),
                endTime: moment().set({ hour: 11, minute: 0 }).subtract(1, 'days').toISOString()
            },
            {
                name: 'Lunch with Client',
                startTime: moment().set({ hour: 12, minute: 0 }).toISOString(),
                endTime: moment().set({ hour: 13, minute: 30 }).toISOString()
            },
            {
                name: 'Project Review',
                startTime: moment().set({ hour: 14, minute: 0 }).add(1, 'days').toISOString(),
                endTime: moment().set({ hour: 15, minute: 30 }).add(1, 'days').toISOString()
            },
            {
                name: 'Dinner at Home',
                startTime: moment().set({ hour: 18, minute: 0 }).add(2, 'days').toISOString(),
                endTime: moment().set({ hour: 19, minute: 30 }).add(2, 'days').toISOString()
            },
            {
                name: 'Reading',
                startTime: moment().set({ hour: 21, minute: 30 }).toISOString(),
                endTime: moment().set({ hour: 22, minute: 0 }).toISOString()
            }
        ],
    });
    return (
        <CalendarContext.Provider value={{ calendarState, setCalendarState }}>
            {children}
        </CalendarContext.Provider>
    );
};
