import React from 'react';


const QuotesPreview = ({ Job, Quotes }) => {

    return (
        <>
        </>
    );
};

export default QuotesPreview;
