import styled from "styled-components";
import ClientAvailabilityPreview from "./preview.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.small};
`;

const JobSiteClientAvailability = ({ Job, Availability }) => {
  return <Wrapper>
    {
      Availability?.map((_availability, index) => {
        return <ClientAvailabilityPreview
          key={`note-${index}`}
          Job={Job}
          Availability={_availability} />
      })
    }
  </Wrapper>;
};

export default JobSiteClientAvailability;