import styled from "styled-components";
import MenuItem from "./MenuItem";

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const OptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spacing.xxsmall};
`;

const Title = styled.h3`
  padding: 0;
  margin: 0;
  text-align: left;
`;

function VerticalMenu({options}) {
    return <Wrapper>
        {options?.map(option => (
            <OptionWrapper key={option?.title}>
                <Title>{option?.title}</Title>
                {
                    option?.items?.map(item => (
                        <MenuItem key={item?.title} title={item?.title} linkTo={item?.linkTo} component={item?.component} />
                    ))
                }
            </OptionWrapper>
        ))}
    </Wrapper>;
};

export default VerticalMenu;