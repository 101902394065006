import { find } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const JobInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const JobNumber = styled.p`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0;
`;

const StatusText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  color: ${({ theme }) => theme.colors.primary};
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  margin: 0;
`;

const JobSiteName = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const Client = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const Address = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const Status = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxxxxsmall};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  background-color: ${({ Color }) => Color};
  border-radius: ${({ theme }) => theme.borderRadius.small} ${({ theme }) => theme.borderRadius.small} 0 0;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: ${({ theme }) => theme.spacing.xxxsmall};
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const LocationLine = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin: 0;
`;

const Label = styled.span`
  font-weight: ${({ theme }) => theme.fontStyles.bold};
  margin: 0;
`;

function formatAddress({ Street, City, StateProvince, PostalCode, Country }) {
  return [
    Street,
    `${City}, ${StateProvince} ${PostalCode}`,
    Country
  ];
}


const JobSiteCard = ({ JobSite }) => {
  const client = JobSite?.Client;
  const Name = JobSite?.Name;
  const address = find(client?.VCard?.Addresses, ['_id', JobSite?.ClientAddressId]);
  const formattedAddress = formatAddress(address || {});

  return (
    <JobInfoContainer>
      <Info>
        <JobNumber>{JobSite?.ClientJobSiteCode}</JobNumber>
        <JobSiteName>{Name}</JobSiteName>
        <Client>{client?.ContactName}</Client>
        <Address>{formattedAddress.map((line, index) => (
          <LocationLine key={index}>{line}</LocationLine>
        ))}
        </Address>
      </Info>
    </JobInfoContainer>
  );
};

export default JobSiteCard;
