import { get, set } from "lodash";

export const displayRules = (document, activeFields) => {
    if(get(document, 'VCard.IsCompany') === true){
        set(activeFields, 'VCard.FirstName', false);
        set(activeFields, 'VCard.LastName', false);
        set(activeFields, 'VCard.Company', true);
    } else {
        set(activeFields, 'VCard.FirstName', true);
        set(activeFields, 'VCard.LastName', true);
        set(activeFields, 'VCard.Company', false);
    }
};