import React from 'react';


const AttachmentPreview = ({ Job, Attachment }) => {

    return (
        <>
        </>
    );
};

export default AttachmentPreview;
