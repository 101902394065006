import { fields } from './profile.form.fields';
import { validation } from './profile.form.validation';
import { displayRules } from './profile.form.displayRules';
import { initGraphQL } from './profile.form.init.graphql';

const initActiveFields = {
    'VCard.Photo': true,
    'VCard.Prefix': false,
    'VCard.FirstName': true,
    'VCard.PhoneticFirstName': false,
    'VCard.PronounciationFirstName': false,
    'VCard.MiddleName': false,
    'VCard.PhoneticLastName': false,
    'VCard.PronounciationLastName': false,
    'VCard.LastName': true,
    'VCard.MaidenName': false,
    'VCard.Suffix': false,
    'VCard.Nickname': false,
    'VCard.JobTitle': false,
    'VCard.Department': false,
    'VCard.Company': true,
    'VCard.PhoneticCompanyName': true,
    'VCard.Phones': true,
    'VCard.Emails': true,
    'VCard.Addresses': true,
    'VCard.Websites': true,
    'VCard.Birthday': false,
    'VCard.Anniversary': false,
    'VCard.RelatedVcard': false,
    'VCard.InstantMessagings': false,
    'VCard.SocialProfiles': false,
    'VCard.Timezone': false,
    'VCard.Notes': true,
};

const blackListFields = [
];

export const profileFormConfig = {
    documentName: 'Profile',
    allowFieldSelector: false,
    fields,
    initActiveFields,
    blackListFields,
    validation,
    displayRules,
    initGraphQL,
};