import { gql } from "@apollo/client";
import { TASK_FRAGMENT } from "../Fragments/Task";

export const TaskCreateOne = gql`
    ${TASK_FRAGMENT}
    mutation Mutation($record: TaskCreateOneWithRelationship!) {
        TaskCreateOne(record: $record) {
            record {
                ...TaskFields
            }
        }
    }
`;

export const TaskUpdateOne = gql`
    mutation TaskUpdateOne($record: TaskUpdateOneWithRelationship!, $filter: FilterUpdateOneTaskInput) {
        TaskUpdateOne(record: $record, filter: $filter) {
            recordId
            error {
                message
            }
        }
    }
`;

export const TaskRemoveFindById = gql`
    mutation TaskRemoveFindById($id: MongoID!) {
        TaskRemoveFindById(_id: $id) {
            recordId
            error {
                message
            }
        }
    }
`;
