/* Header.js */
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCircleUser } from '@fortawesome/free-regular-svg-icons';
import { forwardRef } from 'react';

const HeaderContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px);
  border-radius: 10px;
  padding: ${({ theme }) => theme.spacing.small};
  text-align: center;
  position: fixed;
  /* width: 100%; */
  box-sizing: border-box;
  top: 0;
  z-index: 100;
  /* Styles */
  background-color: ${({ theme }) => theme.colors.primary};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const HeaderContent = styled.header`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  /* Styles */
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  justify-content: flex-start;
  align-items: center;
  a {
    text-decoration: none;
  }
`;

const IconContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-basis: 50%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: ${({ theme }) => theme.responsiveSpacing.medium};
`;

const AppNameContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
`;

const AppName = styled.h1`
  font-family: 'Poppins', sans-serif !important;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin: 0;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.appName};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    display: none;
  }
  /* color: white; */
`;

const LogoImage = styled.img`
  height: ${({ theme }) => theme.fontSizes.xxlarge};
  width: auto;
  /* margin-right: ${({ theme }) => theme.spacing.small}; */
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    height: ${({ theme }) => theme.fontSizes.xlarge};
  }

  /* background: #0C40B3;
    border-radius: 10%;
    margin-right: 10px;
    padding: 3px; */
`;

const LogoLink = styled(Link)`
  display: flex;
`;

const NotificationIcon = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  color: ${({ theme }) => theme.colors.icon};
  border-bottom: ${({ theme, isactive }) => (isactive === "true" ? `3px solid ${theme.colors.secondary}` : 'none')};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const ProfileIcon = styled(Link)`
  font-size: ${({ theme }) => theme.fontSizes.xlarge};
  color: ${({ theme }) => theme.colors.icon};
  border-bottom: ${({ theme, isactive }) => (isactive === "true" ? `3px solid ${theme.colors.secondary}` : 'none')};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: ${({ theme }) => theme.fontSizes.large};
  }
`;

const SkipNavLink = styled.a`
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: 1000;
  padding: ${({ theme }) => theme.spacing.xsmall};
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: none;
  border-radius: ${({ theme }) => theme.borderRadius.small};
  transition: all 0.3s ease;

  &:focus {
    position: fixed;
    left: 1rem;
    top: 1rem;
    width: auto;
    height: auto;
    overflow: visible;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const Header = forwardRef(({ appName, logoBlackSrc }, ref) => {
  const location = useLocation();
  const isNotificationsActive = location.pathname === '/notifications';
  const isProfileActive = location.pathname === '/profile';

  return (
    <HeaderContainer ref={ref} role="banner">
      <HeaderContent>
        <SkipNavLink href="#main-content">Skip to main content</SkipNavLink>
        <LogoContainer>
          <LogoLink to="/">
            <LogoImage src={logoBlackSrc} alt={`${appName} logo`} />
          </LogoLink>
          <Link to="/">
            <AppNameContainer>
              <AppName>{appName}</AppName>
            </AppNameContainer>
          </Link>
        </LogoContainer>
        <IconContainer>
          <NotificationIcon
            to="/notifications"
            isactive={isNotificationsActive ? "true" : "false"}
            aria-label="View notifications"
          >
            <FontAwesomeIcon icon={faBell} aria-hidden="true" />
          </NotificationIcon>
          <ProfileIcon
            to="/profile"
            isactive={isProfileActive ? "true" : "false"}
            aria-label="View profile"
          >
            <FontAwesomeIcon icon={faCircleUser} aria-hidden="true" />
          </ProfileIcon>
        </IconContainer>
      </HeaderContent>
    </HeaderContainer>
  );
});

export default Header;
