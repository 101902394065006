import React, { useState } from 'react';
import styled from 'styled-components';
import SearchBar from '../SearchBar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.small};
`;

const SubTree = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

const NodeLabel = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: ${({ header }) => header ? '0px' : '16px'};
  align-items: flex-end;
  gap: 5px;
  font-size: ${({ theme }) => theme.fontSizes.small};
  cursor: pointer;
`;

const Header = styled.div`
  font-weight: bold;
`;

const TreeItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TreeItemCollapse = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const LineHorizontal = styled.div`
  width: ${({ theme }) => theme.fontSizes.small};
  height: ${({ theme }) => theme.fontSizes.small};
  border: black solid;
  border-top: 0px;
  border-right: 0px;
  margin-bottom: ${({ theme }) => `calc(${theme.fontSizes.small} / 2)`};
`;

function Tree({ node, header, handleSelection }) {
    const [collapsed, setCollapsed] = useState(false);

    const handleToggleCollapse = () => {
        setCollapsed(!collapsed);
    };

    if (node?.name && !node?.children) {
        return (
            <TreeItem key={node?.name}>
                <NodeLabel onClick={() => handleSelection(node?.name)}>
                    <LineHorizontal />
                    {node?.name}
                </NodeLabel>
            </TreeItem>
        );
    }
    if (node?.name && node?.children) {
        return (
            <TreeItemCollapse key={node?.name}>
                <NodeLabel header={header} onClick={handleToggleCollapse}>
                    {!header && <LineHorizontal />}
                    <Header>{node.name}</Header>
                </NodeLabel>
                {!collapsed && (
                    <SubTree>
                        {node?.children?.map((_node) => (
                            <Tree key={_node.name} node={_node} handleSelection={handleSelection} />
                        ))}
                    </SubTree>
                )}
            </TreeItemCollapse>
        );
    }
    return <div />;
}

function TreeMenu({ data, handleSelection }) {
    const [searchValue, setSearchValue] = useState('');

    return (
        <Wrapper>
            <SearchBar
                searchValue={searchValue}
                onSearchChange={(e) => setSearchValue(e.target.value)}
            />
            {data.map((item, index) => (
                <Tree node={item} key={index} header={true} handleSelection={handleSelection} />
            ))}
        </Wrapper>
    );
}

export default TreeMenu;
