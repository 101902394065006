import { get } from "lodash";

export function getFormValue(inputs, key, index) {
    // const _key = key.replace('[index]', `[${index}]`);
    return get(inputs, key);
}

export function includeIdValues(source, target) {
    for (const key in source) {
        const value = source[key];
        if (typeof value === 'object' && value !== null) {
            if (value._id) {
                target[key]._id = value._id;
            }
            if (value.__typename) {
                target[key].__typename = value.__typename;
            }
            includeIdValues(value, target[key]);
        }
    }
}