import styled from "styled-components";
import VerticalMenuDynamic from "../../../Content/common/VerticalMenuDynamic";
import Project from "../../../Documents/Project";
import Task from "../../../Documents/Task";
import Note from "../../../Documents/Note";
import Alert from "../../../Documents/Alert/Alert";
import ClientAvailablity from "../../../Documents/ClientAvailablity/ClientAvailablity";
import Invoice from "../../../Documents/Invoice/Invoice";
import Quote from "../../../Documents/Quote/Quote";
import Attachment from "../../../Documents/Attachment/Attachment";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  width: 100%;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing.xxsmall};
  gap: ${({ theme }) => theme.spacing.small};
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
  }
`;

const Button = styled.button`
  align-self: flex-start;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: normal;
  text-decoration: none;
  color:${({ theme }) => theme.colors.defaultText};
  padding: 0px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &:active {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

function JobSiteAddSubDoc({ jobsite, closeModal = () => { } }) {

    const MenuSections = [
        {
            title: 'CREATE',
            options: [
                {
                    title: 'Project',
                    content: <Project isNewDocument={true} jobsite={jobsite} />
                },
                {
                    title: 'Task',
                    content: <Task isNewDocument={true} />
                },
                {
                    title: 'Note',
                    content: <Note isNewDocument={true} />
                },
                {
                    title: 'Alert',
                    content: <Alert isNewDocument={true} />
                },
                {
                    title: 'Client Availability',
                    content: <ClientAvailablity isNewDocument={true} />
                },
                {
                    title: 'Invoice',
                    content: <Invoice isNewDocument={true} />
                },
                {
                    title: 'Quote',
                    content: <Quote isNewDocument={true} />
                },
                {
                    title: 'Attachment',
                    content: <Attachment isNewDocument={true} />
                }
            ]
        }
    ];

    return <Wrapper>
        <Button type="button" onClick={() => { closeModal(); }}>
            Close
        </Button>
        <br />
        <VerticalMenuDynamic
            MenuSections={MenuSections}
        />
    </Wrapper>;
};

export default JobSiteAddSubDoc;