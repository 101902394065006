import styled from "styled-components";
import FormBuilder from "../../FormBuilder";
import { attachmentFormConfig } from "../../../documentConfigs/attachment/attachment.form";

const InvoiceDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-wrap: nowrap;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ theme }) => theme.spacing.xxsmall};
`;

const Invoice = ({ invoice = {}, closeModal = () => { }, isNewDocument }) => {
    return <InvoiceDetailsWrapper>
        <FormBuilder
            documentId={invoice?._id}
            isNewDocument={isNewDocument}
            onFormClose={closeModal}
            label={'Invoice'}
            {...attachmentFormConfig}
        />
    </InvoiceDetailsWrapper>;
};

export default Invoice;