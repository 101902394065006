import { gql } from "@apollo/client";
import { CLIENT_FRAGMENT } from "../Fragments/Client";

export const ClientOne = gql`
    ${CLIENT_FRAGMENT}
    query Query($filter: FilterFindOneClientInput) {
        ClientOne(filter: $filter) {
            ...ClientFields
        }
    }
`;

export const ClientFindMany = gql`
    ${CLIENT_FRAGMENT}
    query ClientFindMany($filter: FilterFindManyClientInput, $skip: Int, $limit: Int, $sort: SortFindManyClientInput) {
        ClientFindMany(filter: $filter, skip: $skip, limit: $limit, sort: $sort) {
            ...ClientFields
        }
    }
`;
